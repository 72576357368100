import { groupBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FileUploadStatus } from "store/useFileUploadStore";

interface Props {
  taskCode: string | null;
  isUploadInProgress: boolean;
  fileStatuses: FileUploadStatus[];
}

const UploadPopoverTitle = ({ fileStatuses, taskCode, isUploadInProgress }: Props) => {
  const { t } = useTranslation();
  const resultTitle = useMemo(() => {
    const filesByStatus = groupBy(fileStatuses, "status");
    return [
      filesByStatus.uploaded?.length > 0
        ? t("task.upload.popover.title.uploaded", { count: filesByStatus.uploaded.length })
        : t("task.upload.popover.title.uploaded-none"),
      filesByStatus.failed?.length > 0 && t("task.upload.popover.title.failed", { count: filesByStatus.failed.length }),
      filesByStatus.canceled?.length > 0 &&
        t("task.upload.popover.title.canceled", { count: filesByStatus.canceled.length }),
    ]
      .filter(Boolean)
      .join(", ");
  }, [fileStatuses, t]);

  return (
    <div className="flex flex-1 flex-col items-start" data-test="upload-header">
      <div className="flex w-full">
        <span className="text-color-primary text-base font-medium" data-test="upload-header-title">
          {isUploadInProgress ? t("task.upload.popover.title.uploading", { count: fileStatuses.length }) : resultTitle}
        </span>
      </div>
      {taskCode && (
        <span className="text-color-secondary text-xs" data-test="upload-header-taskcode">
          {t("task.fields.task")}: {taskCode}
        </span>
      )}
    </div>
  );
};

export default UploadPopoverTitle;
