import { BarsArrowDownIcon } from "@heroicons/react/24/outline";
import Collapse from "components/Collapse";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { FileBoxIcon } from "lucide-react";
import { useDeliveredFileList } from "query/task.query";
import { useTranslation } from "react-i18next";

interface Props {
  taskId: string;
}

export const FileListTable = ({ taskId }: Props) => {
  const { t } = useTranslation();
  const { files, hasFiles, isFetching, error } = useDeliveredFileList(taskId);

  if (isFetching) {
    return <Loader isShown={true} />;
  }

  return (
    <Collapse
      defaultOpen={files.length < 5}
      header={
        <h5 className="my-2 space-x-2 font-medium" data-test="upload-task-materials-text">
          <span>{t("task.detail.files.title")}</span>
          <span>({files.length})</span>
        </h5>
      }
    >
      {!hasFiles ? (
        <EmptyState
          icon={FileBoxIcon}
          title={t("task.detail.files.title-no-delivered-files")}
          subtitle={error ? t("error:common.server") : t("task.detail.files.title-no-delivered-files-detail")}
        />
      ) : (
        <table className="min-w-full table-fixed divide-y divide-gray-100 dark:divide-slate-600">
          <thead className="text-color-primary">
            <tr>
              <th scope="col" className="cursor-pointer py-3.5 pl-4 pr-3 font-semibold sm:pl-6">
                <div className="flex items-center">
                  <span data-test="upload-task-materials-filename-label">{t("task.detail.files.fields.filename")}</span>
                </div>
              </th>
              <th
                scope="col"
                className="hidden cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:table-cell sm:pl-6"
              >
                <div className="flex items-center">
                  <span data-test="upload-task-materials-delivery-time-label">
                    {t("task.detail.files.fields.deliveryTime")}
                  </span>
                  <BarsArrowDownIcon className="ml-3 h-4 w-4" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-color-primary divide-y divide-gray-200 dark:divide-slate-600">
            {files.map((file, index) => (
              <tr key={index} className="mb-0.5">
                <td className="px-3 py-4 xl:table-cell">
                  <div className="flex flex-col">
                    <span data-test={`upload-task-materials-filename-${file.fileName}`}>{file.fileName}</span>
                    <span className="sm:hidden">{t("enum:dates.mediumDateTime", { date: file.creationTime })}</span>
                  </div>
                </td>
                <td
                  className="hidden whitespace-nowrap px-3 py-4 sm:table-cell"
                  data-test={`upload-task-materials-delivery-time-${file.fileName}`}
                >
                  {t("enum:dates.mediumDateTime", { date: file.creationTime })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Collapse>
  );
};
