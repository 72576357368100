import { toBoolean } from "./format";

type Parameter = Exclude<ImportMetaEnv, null | undefined>;

declare global {
  interface Window {
    __ENV__: { [key: string]: string | undefined };
  }
}

const getParameters = (): Parameter => {
  const {
    DEV,
    PROD,
    MODE,
    BASE_URL,
    SSR,
    PARTNER_PORTAL_API_SERVER_URL,
    PARTNER_PORTAL_ENV,
    PARTNER_PORTAL_VERSION,
    PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN,
    PARTNER_PORTAL_DATA_DOG_APP_ID,
    PARTNER_PORTAL_APPCUES_ENABLED,
  } = import.meta.env;

  const windowEnv = window.__ENV__;

  const appCues =
    windowEnv?.PARTNER_PORTAL_APPCUES_ENABLED == undefined
      ? toBoolean(PARTNER_PORTAL_APPCUES_ENABLED)
      : toBoolean(windowEnv?.PARTNER_PORTAL_APPCUES_ENABLED);

  return {
    DEV,
    PROD,
    MODE,
    BASE_URL,
    SSR,
    PARTNER_PORTAL_VERSION: windowEnv?.PARTNER_PORTAL_VERSION ?? PARTNER_PORTAL_VERSION,
    PARTNER_PORTAL_ENV: windowEnv?.PARTNER_PORTAL_ENV ?? PARTNER_PORTAL_ENV,
    PARTNER_PORTAL_DATA_DOG_APP_ID: windowEnv?.PARTNER_PORTAL_DATA_DOG_APP_ID ?? PARTNER_PORTAL_DATA_DOG_APP_ID,
    PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN:
      windowEnv?.PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN ?? PARTNER_PORTAL_DATA_DOG_CLIENT_TOKEN,
    PARTNER_PORTAL_API_SERVER_URL: windowEnv?.PARTNER_PORTAL_API_SERVER_URL ?? PARTNER_PORTAL_API_SERVER_URL,
    PARTNER_PORTAL_APPCUES_ENABLED: appCues ?? false,
  };
};

const ENV_PARAMS: Parameter = getParameters();

export default ENV_PARAMS;
