import { useTranslation } from "react-i18next";
import AnchorLink from "./AnchorLink";

interface Props {
  onCookieAccept: () => void;
}

const CookieMessage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-ribbon-600 p-2 shadow-lg sm:p-3">
          <div className="flex flex-col items-center justify-between md:flex-row md:space-x-2">
            <div className="w-full items-center">
              <p className="font-medium text-white">
                {t("cookie-consent.message")}
                <AnchorLink
                  to={"/legal/privacy"}
                  label={t("footer.privacy")}
                  color="ternary"
                  dataActionName="Open privacy"
                  dataTestId="link-privacy-page"
                />
              </p>
            </div>
            <div className="mt-2 w-full md:mt-0 md:w-max">
              <button
                type="button"
                onClick={props.onCookieAccept}
                className="w-full rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-ribbon-600 shadow-sm hover:bg-ribbon-100 md:w-max"
              >
                {t("cookie-consent.accept")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieMessage;
