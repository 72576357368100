import AnchorLink from "components/AnchorLink";
import Error from "components/Error";
import Button from "components/buttons/Button";
import InputForm from "components/input/InputForm";
import AuthenticationLayout from "layout/AuthenticationLayout";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/routes.const";
import { ForgotPasswordSuccess } from "./ForgotPasswordSuccess";
import { useForgotPassword } from "./useForgotPassword";

const ForgotPasswordPage = ({ isCreateAccount = false }: { isCreateAccount?: boolean }) => {
  const { emailSentTo, isSuccess, isPending, forgotPassword, register, errors, serverError, reset } =
    useForgotPassword();
  const { t } = useTranslation("translation", { keyPrefix: "forgot-password" });

  return (
    <AuthenticationLayout title={t(isCreateAccount ? "title-create" : "title-forgot")}>
      {!isSuccess && (
        <>
          <div className="text-color-secondary mb-6 flex flex-col items-start space-y-1 font-medium">
            <span>{t("form.description.enter-email")}</span>
            <span>{t("form.description.what-we-will-do")}</span>
          </div>
          <form className="mb-2 space-y-6" onSubmit={forgotPassword} noValidate={true}>
            <InputForm
              id="email-input"
              type="email"
              label={t("form.email.label")}
              autoComplete="username"
              autoFocus={true}
              error={errors.email?.message}
              {...register("email")}
            />
            {!isPending && <Error error={serverError} />}
            <Button
              type="submit"
              label={t("form.send")}
              className="w-full"
              dataActionName="submit-forgot-password"
              dataTestId="btn-submit-forgot-password"
              disabled={isPending}
            />
          </form>
          <div className="mt-6">
            <div className="flex items-baseline space-x-2 text-sm">
              <span className="text-color-secondary font-medium">{t("go-to-login-title")}</span>
              <AnchorLink
                to={ROUTES.LOGIN}
                label={t("go-to-login")}
                className="text-sm font-medium"
                dataActionName="go-to-login"
                dataTestId="go-to-login"
              />
            </div>
          </div>
        </>
      )}
      {isSuccess && emailSentTo && (
        <ForgotPasswordSuccess onResendResetLink={reset} emailSentTo={emailSentTo} isCreateAccount={isCreateAccount} />
      )}
    </AuthenticationLayout>
  );
};

export default ForgotPasswordPage;
