import { BaseComponentProps } from "components/typings";
import { ButtonHTMLAttributes, ComponentProps, createElement, ReactElement, ReactNode } from "react";
import { cn } from "utils/ui";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, BaseComponentProps {
  color?: "primary" | "secondary" | "ternary";
  label?: string;
  children?: ReactNode;
  outlined?: boolean;
  icon?: (props: ComponentProps<"svg">) => ReactElement;
}

const Button = ({
  color = "primary",
  outlined = false,
  disabled = false,
  label,
  children,
  icon,
  dataTestId,
  dataActionName,
  className,
  ...props
}: Props) => {
  return (
    <button
      {...props}
      disabled={disabled}
      data-test={dataTestId}
      data-dd-action-name={dataActionName}
      className={cn(
        "flex justify-center rounded-md border border-transparent px-4 py-2 text-base font-semibold transition-colors duration-200",
        {
          // normal state
          "offset-ring-300 bg-ribbon-500 text-white shadow-sm hover:bg-ribbon-400":
            !outlined && !disabled && color === "primary",
          "offset-ring-500 bg-zodiac-500 text-white shadow-sm hover:bg-zodiac-400":
            !outlined && !disabled && color === "secondary",
          "offset-ring-500 border-gray-300 bg-gray-200 text-gray-500 shadow-sm hover:bg-gray-200/75 dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-600":
            !outlined && !disabled && color === "ternary",
          // outlined state
          "offset-ring-300 border-2 border-ribbon-500 bg-white text-ribbon-500 shadow-sm hover:bg-ribbon-500/10":
            outlined && !disabled && color === "primary",
          "offset-ring-500 border-2 border-zodiac-500 bg-white text-zodiac-500 shadow-sm hover:bg-zodiac-500/10":
            outlined && !disabled && color === "secondary",
          "offset-ring-500 border-2 border-gray-300 text-zodiac-300 shadow-sm hover:bg-gray-100":
            outlined && !disabled && color === "ternary",
          // disabled state
          "bg-gray-200 text-gray-400 dark:bg-slate-700 dark:text-slate-500": !outlined && disabled,
          "border-2 border-gray-200 bg-white text-gray-300": outlined && disabled,
        },
        className
      )}
    >
      {icon ? createElement(icon, { className: "-ml-1 mr-2 h-5 w-5", "aria-hidden": "true" }) : null}
      {label ? label : children}
    </button>
  );
};

export default Button;
