import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { BaseSelectModel } from "components/typings";
import { Fragment, ReactNode } from "react";
import { cn } from "utils/ui";

export interface SelectProps<T extends BaseSelectModel> {
  label?: string;
  error?: string;
  selectedItem: T | null;
  onSelection: (selection: T) => void;
  items: T[];
  renderName: (value: T | null) => ReactNode;
}

function Select<T extends BaseSelectModel>({
  items = [],
  label,
  error,
  selectedItem,
  onSelection,
  renderName,
}: SelectProps<T>) {
  const hasError = typeof error === "string" && error.length > 0;
  return (
    <div>
      <Listbox value={selectedItem} onChange={onSelection}>
        {({ open }) => (
          <>
            {label && <Listbox.Label className="text-color-secondary text-sm font-medium">{label}</Listbox.Label>}
            <div className="relative mt-1">
              <Listbox.Button
                tabIndex={0}
                autoFocus={true}
                className={cn(
                  "relative w-full cursor-pointer rounded-md py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm",
                  "border focus:ring-1 dark:border-slate-700 dark:bg-slate-600 dark:text-slate-200 dark:focus:ring-2",
                  hasError
                    ? "border-red-300 focus:border-red-500 focus:ring-red-400 dark:border-red-400"
                    : "border-gray-300 focus:border-ribbon-400 focus:ring-ribbon-400"
                )}
              >
                <span className="block truncate">{renderName(selectedItem)}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm dark:bg-slate-700">
                  {items.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        cn(
                          active
                            ? "bg-ribbon-500 text-white dark:bg-ribbon-400 dark:text-slate-200"
                            : "text-gray-900 dark:text-slate-300",
                          "relative cursor-default select-none py-2 pl-8 pr-4"
                        )
                      }
                      value={item}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={cn(
                              selectedItem?.id === item.id ? "font-semibold dark:text-slate-200" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {renderName(item)}
                          </span>

                          {selectedItem?.id === item.id ? (
                            <span
                              className={cn(
                                active ? "text-white dark:text-slate-200" : "text-ribbon-600 dark:text-ribbon-400",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {hasError ? (
        <span className="mt-1 text-sm text-red-600 dark:text-red-500" role="alert">
          {error}
        </span>
      ) : null}
    </div>
  );
}

export default Select;
