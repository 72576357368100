import { Checkbox } from "components/CheckboxForm";
import { QAFormType } from "pages/project/common/taskBase.typing";
import { Fragment } from "react";
import { DeliverFormModel } from "pages/project/task/components/modals/useDeliverTaskForm";

interface Props {
  data: QAFormType;
  qaFormRatings: DeliverFormModel["qaFormRatings"];
  onqaFormRatingChange: (ratings: DeliverFormModel["qaFormRatings"]) => void;
}

function QAForm({ data, qaFormRatings, onqaFormRatingChange }: Props) {
  const handleOnchange = (titleId: number, updatedRatings: Array<{ key: number; value: boolean }>) => {
    onqaFormRatingChange(
      qaFormRatings.map((rate) =>
        rate.titleId === titleId
          ? {
              ...rate,
              ratings: updatedRatings,
            }
          : rate
      )
    );
  };

  return (
    <>
      <div className="my-4 grid grid-cols-8 gap-3">
        <div className="col-span-4 hidden text-sm sm:block" />
        {data.ratings.map(({ key: ratingId, value }) => {
          return (
            <div key={ratingId} className="mx-auto hidden text-xs font-medium sm:block">
              {value}
            </div>
          );
        })}
        {qaFormRatings.map(({ title, titleId, ratings }) => {
          return (
            <Fragment key={titleId}>
              <div key={titleId} title={title} className="col-span-4 truncate text-sm">
                {title}
              </div>
              <QAFormItem
                titleId={titleId}
                ratings={ratings}
                onChange={(updatedRatings) => handleOnchange(titleId, updatedRatings)}
              />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}

function QAFormItem({
  ratings,
  titleId,
  onChange,
}: {
  ratings: Array<{ key: number; value: boolean }>;
  titleId: number;
  onChange: (ratings: Array<{ key: number; value: boolean }>) => void;
}) {
  const handleOnChange = (ratingId: number, isChecked: boolean) => {
    const newValues = ratings.map(({ key }) =>
      ratingId === key
        ? { key, value: isChecked }
        : {
            key,
            value: false,
          }
    );
    onChange(newValues);
  };

  return (
    <>
      {ratings.map(({ key: ratingId, value }) => {
        return (
          <div key={ratingId} className="mx-auto">
            <Checkbox
              id={`ratings.${titleId}.${ratingId}.value`}
              checked={value}
              onChange={(event) => handleOnChange(ratingId, event.target.checked)}
            />
          </div>
        );
      })}
    </>
  );
}

export default QAForm;
