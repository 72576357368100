import { Navigate, useParams } from "react-router-dom";
import { mapToListType } from "pages/project/offer/offer.typing";
import { useOffer } from "query/offer.query";

const OfferRedirectFromId = () => {
  const { id: offerId = "" } = useParams<{ id: string }>();
  const { isLoading, selectedOffer } = useOffer(offerId);

  if (isLoading) return null;

  if (!selectedOffer) {
    return <Navigate to="/error/offer/404" replace={true} />;
  }

  const listType = mapToListType(selectedOffer.status);

  if (!listType) return <Navigate to="/error/500" replace={true} />;

  return <Navigate to={`/project/offer/list/${listType}/${offerId}`} replace={true} />;
};

export default OfferRedirectFromId;
