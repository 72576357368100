import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "components/Tooltip";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import type { PropsWithChildren } from "react";
import { useRef } from "react";
import { cn } from "utils/ui";

function CopyToClipboard({
  children,
  value,
  className,
  onClick,
}: PropsWithChildren<{ value: string; className?: string; onClick?: () => void }>) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const { isCopiedTooltipVisible, copyToClipboard } = useCopyToClipboard(timeoutRef);
  return (
    <div className={cn("inline-flex flex-nowrap items-center space-x-1", className)}>
      {children}
      <button
        type="button"
        onClick={() => {
          copyToClipboard(value);
          onClick?.();
        }}
        aria-label="copy"
      >
        <ClipboardDocumentCheckIcon className="h-[16px] w-[16px] text-gray-400 hover:cursor-pointer hover:text-gray-900 dark:hover:text-gray-200 " />
        <Tooltip isVisible={isCopiedTooltipVisible} label="Copied" />
      </button>
    </div>
  );
}

export default CopyToClipboard;
