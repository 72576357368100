import { MutableRefObject, useState } from "react";

export function useCopyToClipboard(timeoutRef: MutableRefObject<ReturnType<typeof setTimeout> | undefined>) {
  const [isCopiedTooltipVisible, toggleCopiedTooltip] = useState(false);

  const copyToClipboard = (value: string) => {
    if (isCopiedTooltipVisible) {
      return;
    }
    navigator.clipboard.writeText(value).then();
    toggleCopiedTooltip(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => toggleCopiedTooltip(false), 1200);
  };

  return { isCopiedTooltipVisible, copyToClipboard };
}
