import { ZipFullDownloadDtoResponse } from "api/api.typing";
import { toastContent } from "components/toastContent";
import { t } from "i18next";
import { TaskBase, TaskBaseTypes } from "pages/project/common/taskBase.typing";
import { toast } from "react-hot-toast";
import { bytesToSize } from "utils/format";
import { LanguageType } from "utils/language";
import { create } from "zustand";

const ZipOperationResultType = {
  Success: 0,
  Error: 1,
};

type TaskBaseDownloadState = {
  id: string;
  taskCode: string;
  toasterId: string;
  downloadStatus: "INIT" | "IN_PROGRESS" | "FAILED";
};

interface TaskBaseFileState<TaskBaseType extends TaskBase> {
  downloadStatuses: Map<string, TaskBaseDownloadState>;
  shouldDisableAction: (id?: string) => boolean;
  requestZipFullDownload: (
    id: string,
    taskBase: TaskBaseType,
    currentLanguage: LanguageType,
    hubConnectionId: string
  ) => void;
  onReadyToDownload: (id: string, guid: string, resultType: number) => void;
}

export function createTaskBaseFileStore<TaskBaseType extends TaskBase>(
  objectType: TaskBaseTypes,
  requestZipFullDownload: (
    taskBase: TaskBaseType,
    currentLanguage: LanguageType,
    hubConnectionId: string
  ) => Promise<ZipFullDownloadDtoResponse>,
  downloadFullZip: (id: string, guid: string) => Promise<unknown>
) {
  const tTaskBase = (key: string, taskCode: string) => t(`${objectType}.detail.${key}`, { taskCode });

  return create<TaskBaseFileState<TaskBaseType>>((set, get) => {
    function setRequestFailed(id: string) {
      const downloadStatuses = get().downloadStatuses;
      const state = downloadStatuses.get(id);
      if (state?.toasterId) {
        toast.dismiss(state.toasterId);
      }
      if (!state) {
        return;
      }
      toast.error(toastContent(tTaskBase("title", state.taskCode), t("translation:fileDownload.noFileError")));
      downloadStatuses.delete(id);
      set({ downloadStatuses });
    }

    function setRequestInProgress(id: string, taskCode: string) {
      const downloadStatuses = get().downloadStatuses;
      const state = downloadStatuses.get(id);
      if (state?.toasterId) {
        toast.dismiss(state.toasterId);
      }

      const toasterId = toast.loading(toastContent(tTaskBase("title", taskCode), t("translation:common.loading")));
      downloadStatuses.set(id, { id, downloadStatus: "IN_PROGRESS", toasterId, taskCode });
      set({ downloadStatuses });
    }

    function setRequestPreparing(id: string, estimatedFileSize: number) {
      const downloadStatuses = get().downloadStatuses;
      const state = downloadStatuses.get(id);
      if (!state) {
        return;
      }
      if (state?.toasterId) {
        toast.dismiss(state.toasterId);
      }

      const toasterId = toast.loading((currentToast: { id: string }) =>
        toastContent(
          tTaskBase("title", state.taskCode),
          t("translation:fileDownload.preparing", { estimatedFileSize: bytesToSize(estimatedFileSize) }),
          () => {
            toast.dismiss(currentToast.id);
            downloadStatuses.delete(state.id);
            set({ downloadStatuses });
          }
        )
      );

      downloadStatuses.set(id, { toasterId, id, downloadStatus: "IN_PROGRESS", taskCode: state.taskCode });
      set({ downloadStatuses });
    }

    return {
      downloadStatuses: new Map<string, TaskBaseDownloadState>(),
      shouldDisableAction: (id?: string) => {
        return get().downloadStatuses.get(id ?? "")?.downloadStatus === "IN_PROGRESS" || false;
      },
      requestZipFullDownload: (
        id: string,
        taskBase: TaskBaseType,
        currentLanguage: LanguageType,
        hubConnectionId: string
      ) => {
        setRequestInProgress(id, taskBase.taskCode);
        requestZipFullDownload(taskBase, currentLanguage, hubConnectionId)
          .then((response) => {
            const estimatedFileSize = response?.estimatedFileSize ?? 0;
            setRequestPreparing(id, estimatedFileSize);
          })
          .catch(() => setRequestFailed(id));
      },
      onReadyToDownload: async (id: string, guid: string, resultType: number) => {
        const downloadStatuses = get().downloadStatuses;
        const state = downloadStatuses.get(id.toString());

        if (!state) {
          return;
        }

        toast.dismiss(state.toasterId);

        if (resultType === ZipOperationResultType.Success) {
          toast.success(toastContent(tTaskBase("title", state.taskCode), t("translation:fileDownload.ready")));
          await downloadFullZip(state.id, guid).catch(() => {
            toast.error(toastContent(tTaskBase("title", state.taskCode), t("translation:fileDownload.failedDownload")));
          });
        } else if (resultType === ZipOperationResultType.Error) {
          toast.error(toastContent(tTaskBase("title", state.taskCode), t("translation:fileDownload.failedDownload")));
        }

        downloadStatuses.delete(state.id);
        set({ downloadStatuses });
      },
    };
  });
}
