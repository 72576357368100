import { TaskInstructionDto } from "api/api.typing";
import { TaskBase } from "pages/project/common/taskBase.typing";

export const TASK_LIST_TYPE: Record<TaskListType, TaskListType> = {
  upcoming: "upcoming",
  ongoing: "ongoing",
  delivered: "delivered",
  validated: "validated",
  completed: "completed",
};

export type TaskListType = "upcoming" | "ongoing" | "delivered" | "validated" | "completed";
export type TaskActionType = "deliver";

export type TaskListRouteParam = { type: TaskListType; id: string; action: TaskActionType };

export interface Task extends TaskBase {
  status: TaskStatus;
  shouldDisplayQAForm: boolean;
}

export enum TaskStatus {
  UpComing = 1,
  OnGoing = 2,
  Delivered = 3,
  Completed = 4,
}

export const mapToTaskStatus = (taskListType: TaskListType): TaskStatus | null => {
  if (taskListType === "upcoming") {
    return TaskStatus.UpComing;
  }
  if (taskListType === "ongoing") {
    return TaskStatus.OnGoing;
  }
  if (taskListType === "delivered" || taskListType === "validated") {
    return TaskStatus.Delivered;
  }
  if (taskListType === "completed") {
    return TaskStatus.Completed;
  }
  return null;
};

export const mapToListType = (status: TaskStatus): TaskListType | null => {
  if (status === TaskStatus.UpComing) {
    return TASK_LIST_TYPE.upcoming;
  }
  if (status === TaskStatus.OnGoing) {
    return TASK_LIST_TYPE.ongoing;
  }
  if (status === TaskStatus.Delivered) {
    return TASK_LIST_TYPE.delivered;
  }
  if (status === TaskStatus.Completed) {
    return TASK_LIST_TYPE.completed;
  }
  return null;
};

export type TaskInstruction = TaskInstructionDto;
