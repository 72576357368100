import { AnimatePresence, motion } from "framer-motion";

export const Tooltip = ({ isVisible, label }: { isVisible: boolean; label: string }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div className="absolute">
      <AnimatePresence>
        <div className="relative inset-0">
          <motion.span
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: -8, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            className="absolute -top-8 left-0 z-30 rounded bg-white px-1.5 py-1 text-sm font-medium text-gray-500 shadow-md"
          >
            {label}
          </motion.span>
        </div>
      </AnimatePresence>
    </div>
  );
};
