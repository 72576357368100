import { useQuery } from "@tanstack/react-query";
import { getResetPasswordTokenValidity, ResetPasswordTokenValidity } from "api/user.api";
import { AxiosError } from "axios";
import { useLogoutMutation, useUser } from "query/user.query";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { formatServerError } from "utils/format";

export const useParseToken = () => {
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("token");
  return resetPasswordToken && resetPasswordToken.trim().length > 0 ? resetPasswordToken : null;
};

export const useGetResetPasswordTokenValidity = () => {
  const { t } = useTranslation("error");
  const resetPasswordToken = useParseToken();
  const { mutate: logout } = useLogoutMutation(false);
  const { data: user } = useUser();

  useEffect(() => {
    if (user) {
      logout();
    }
  }, [logout, user]);

  const {
    isSuccess,
    isFetching,
    isError,
    error: axiosError,
    data: resetPasswordTokenValidity,
  } = useQuery<ResetPasswordTokenValidity, AxiosError>({
    queryKey: ["resetPasswordTokenCheck", resetPasswordToken],
    queryFn: () => getResetPasswordTokenValidity(resetPasswordToken),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: false,
    staleTime: 60000, // 1 minutes
    enabled: !!resetPasswordToken,
  });

  const serverError = useMemo(() => {
    if (axiosError) {
      return formatServerError(axiosError);
    }
    if (!resetPasswordTokenValidity?.isValid || !resetPasswordToken) {
      return t("set-password.invalid-link");
    }
    return null;
  }, [axiosError, resetPasswordToken, resetPasswordTokenValidity?.isValid, t]);

  return {
    resetPasswordToken: resetPasswordTokenValidity?.isValid ? resetPasswordToken : null,
    isSuccess,
    isFetching,
    isError,
    serverError,
  };
};
