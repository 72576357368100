import { useQuery } from "@tanstack/react-query";
import { getOfferId } from "api/offer.api";
import { Navigate, useParams } from "react-router-dom";

const OfferRedirectFromKey = () => {
  const { key: tempKey = "" } = useParams<{ key: string }>();
  const {
    isLoading,
    isError,
    data: offerId,
  } = useQuery({ queryKey: ["offerId", tempKey], queryFn: () => getOfferId(tempKey) });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Navigate to="/error/500" replace={true} />;
  }

  if (!offerId) {
    return <Navigate to="/error/offer/404" replace={true} />;
  }

  return <Navigate to={`/project/offer/detail/${offerId}`} replace={true} />;
};

export default OfferRedirectFromKey;
