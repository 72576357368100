import PartnerPortalLogo from "components/svgs/Logo";
import { ReactNode } from "react";
import PageFooter from "./PageFooter";

interface Props {
  title: string;
  children?: ReactNode;
}

const LegalLayoutPage = ({ title, children }: Props) => {
  return (
    <div className="flex h-full flex-col">
      <nav className="shadow-sm">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex w-64 shrink-0 flex-col items-start justify-center">
                <a href="/">
                  <PartnerPortalLogo size="normal" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="relative bg-zodiac-300">
        <div className="absolute inset-0">
          <img className="h-full w-full object-cover" src="/images/banner-5.jpg" alt="acolad banner" />
          <div className="absolute inset-0 bg-zodiac-300 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">{title}</h1>
        </div>
      </div>
      <main className="flex-1 border-y border-slate-200 bg-gray-100 dark:border-slate-600 dark:bg-gray-700">
        <div className="sm:px-6 lg:px-8">{children}</div>
      </main>
      <PageFooter />
    </div>
  );
};

export default LegalLayoutPage;
