import ListViewSwitch from "components/ListViewSwitch";
import SearchInput from "components/input/SearchInput";
import { ListFilterParams } from "components/typings";
import { TaskBase } from "pages/project/common/taskBase.typing";
import { cn } from "utils/ui";
import TaskBaseSortByDropdown from "./TaskBaseSortByDropdown";

interface Props {
  isDetailOpen: boolean;
  params: ListFilterParams<TaskBase>;
  onChange: (params: ListFilterParams<TaskBase>) => void;
  isUserSupplier: boolean;
}

const TaskBaseListFilter = ({ isDetailOpen, params, onChange, isUserSupplier }: Props) => {
  const { term, sortBy, view, sortDirection } = params;
  const handleOnChange = (fromParams: Partial<ListFilterParams<TaskBase>>) => {
    onChange({ ...params, ...fromParams });
  };

  return (
    <div className="text-color-base bg-color-base mb-4 flex flex-row items-start rounded-md border border-slate-200 p-4 sm:items-center dark:border-slate-800">
      <div className="flex-1">
        <div className="flex max-w-md flex-col sm:flex-row sm:items-center sm:space-x-4">
          <div className="mb-4 flex-1 sm:mb-0">
            <SearchInput onSearch={(fromTerm) => handleOnChange({ term: fromTerm })} term={term} />
          </div>
          <div
            className={cn("max-w-fit", view === "card" ? "" : isDetailOpen ? "block 2xl:hidden" : "block xl:hidden")}
          >
            <TaskBaseSortByDropdown
              onSort={(fromSortBy, fromSortDirection) =>
                handleOnChange({ sortBy: fromSortBy, sortDirection: fromSortDirection })
              }
              sortBy={sortBy}
              sortDirection={sortDirection}
              isUserSupplier={isUserSupplier}
            />
          </div>
        </div>
      </div>
      <ListViewSwitch view={view} onSwitchView={(fromView) => handleOnChange({ view: fromView })} />
    </div>
  );
};

export default TaskBaseListFilter;
