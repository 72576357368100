import { useMutation } from "@tanstack/react-query";
import api from "api/api";
import { TaskDto, XtmJobsLinkDto, XtmProjectDto, XtmProjectsDtoResponse, XtmStepsDto } from "api/api.typing";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const fetchAnonymousXtmTask = async (token: string): Promise<TaskDto> => {
  const response = await api.get<TaskDto>(`/api/anonymousTasks/${token}`);
  return response.data;
};

export const fetchAnonymousXtmDelegatedJobsTask = async (token: string): Promise<string | null> => {
  const response = await api.get<XtmJobsLinkDto>(`/api/anonymousTasks/${token}/delegatedJobsTask`);
  return response?.data?.url ?? null;
};

export const fetchAnonymousXtmProjects = async (token: string): Promise<XtmProjectDto[]> => {
  const response = await api.get<XtmProjectsDtoResponse>(`/api/anonymousTasks/${token}/xtmProjects`);
  return response?.data?.xtmProjects ?? [];
};

export const fetchAnonymousXtmStepProgress = async (token: string, signal?: AbortSignal): Promise<XtmStepsDto> => {
  const result = await api.get<XtmStepsDto>(`/api/anonymousTasks/${token}/xtmStepsProgress`, { signal });
  return result?.data;
};

export const requestAnonymousDeliverXtmTask = async (token: string): Promise<AxiosResponse<void>> => {
  return await api.put<void>(`/api/anonymousTasks/${token}/deliver`);
};

export const useAnonymousDeliverXtmTaskMutation = (token: string) => {
  const { t } = useTranslation();

  const { mutate, ...others } = useMutation<unknown, AxiosError, void>({
    mutationFn: () => {
      // fullDelivery only when userMark the task as delivered and all file have been uploaded
      return requestAnonymousDeliverXtmTask(token);
    },
    retry: false,
    onError: () => {
      toast.error(t("task.modal.error"));
    },
    onSuccess: async () => {
      toast.success(t("task.modal.deliver.success"));
    },
  });

  return { deliverTask: mutate, ...others };
};
