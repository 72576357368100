import { useEffect } from "react";
import { detectTheme } from "utils/theme";

/**
 * On load will detect theme and sync it with local storage and switch document classes accordingly
 */
function ThemeProvider() {
  useEffect(() => {
    detectTheme(); // trigger theme detection
  }, []);

  // no need to render anything
  return null;
}

export default ThemeProvider;
