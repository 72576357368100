export interface BaseComponentProps {
  dataTestId?: string;
  dataActionName?: string;
}

export type Fields = { [key: string]: unknown };

export type BaseSelectModel = { id: string | number };

export type ListViewType = "table" | "card";

export type ListFilterParams<TModel> = {
  sortBy: keyof TModel | null;
  term: string | null;
  view: ListViewType;
  sortDirection: SortDirectionType;
};

export type SortDirectionType = "asc" | "desc";

export const LIST_URL_PARAM_NAMES = {
  SORT_BY: "sortBy",
  SORT_DIR: "sortDir",
  TERM: "term",
  VIEW: "view",
};
