import Badge from "components/Badge";
import { RequestStatus } from "pages/project/request/request.typing";
import { useTranslation } from "react-i18next";

interface Props {
  status: number;
}

const RequestStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return status === RequestStatus.Accepted ? (
    <Badge color="green" data-test="request-status-badge-accepted">
      {t("task-base.badge.accepted")}
    </Badge>
  ) : (
    <Badge color="sky" data-test="request-status-badge-pending">
      {t("task-base.badge.pending")}
    </Badge>
  );
};

export default RequestStatusBadge;
