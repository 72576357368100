import { BaseComponentProps } from "components/typings";
import Highlighter from "react-highlight-words";

interface Props extends Pick<BaseComponentProps, "dataTestId"> {
  term: string | null | undefined;
  text: string | null | undefined;
  className?: string;
}

const HighlightText = ({ term, text, dataTestId, className = "" }: Props) => {
  if (text === null || !text) return text;
  return (
    <Highlighter
      data-test={dataTestId}
      className={className}
      highlightClassName="text-zodiac-500 bg-goldfish-300 dark:text-slate-100/90 dark:bg-goldfish-500/80"
      searchWords={term !== null && term !== undefined ? [term] : []}
      autoEscape={true}
      textToHighlight={text}
    />
  );
};

export default HighlightText;
