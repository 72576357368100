import { useDeliverTaskMutation } from "query/task.query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MEMSOURCE_CAT_TOOL_ID, QAFormType, XTM_CAT_TOOL_ID } from "pages/project/common/taskBase.typing";
import { TASK_LIST_TYPE } from "pages/project/task/task.typing";

type QAFormRatingType = {
  title: string;
  titleId: number;
  ratings: Array<{ key: number; value: boolean }>;
};

export interface DeliverFormModel {
  comment?: string;
  qaFormRatings: QAFormRatingType[];
}

function initForm({ ratings: fromRatings, titles }: QAFormType): QAFormRatingType[] {
  return titles.map(({ value: title, key: titleId }) => {
    const ratings = fromRatings.map(({ key }) => {
      return { key, value: false };
    });
    return { title, titleId, ratings };
  });
}

export const COMMENT_MAX_CHAR_LIMIT = 1024;

export const useDeliverTaskForm = (
  taskId: string,
  qaForm?: QAFormType,
  shouldDisplayQAForm?: boolean,
  catToolId?: number,
  isUserSupplier?: boolean
) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<string | null>(null);
  const { register, reset, handleSubmit, watch, setValue, formState } = useForm<DeliverFormModel>({
    defaultValues: { qaFormRatings: [] },
    mode: "onChange",
  });
  const qaFormRatings = watch("qaFormRatings");

  const isQAFromMandatory = catToolId !== MEMSOURCE_CAT_TOOL_ID && catToolId !== XTM_CAT_TOOL_ID; // not mandatory for MEMSOURCE and XTM

  useEffect(() => {
    if (qaForm) {
      setValue("qaFormRatings", initForm(qaForm));
    }
  }, [qaForm, setValue]);

  const onReset = () => {
    reset();
    setValidationError(null);
    navigate(`/project/task/list/${TASK_LIST_TYPE.ongoing}/${taskId}?${params.toString()}`);
  };

  const { deliverTask, ...others } = useDeliverTaskMutation(taskId, catToolId, isUserSupplier);

  return {
    ...others,
    qaFormRatings,
    formState,
    setValue,
    register,
    validationError,
    reset: onReset,
    deliver: handleSubmit(({ comment, qaFormRatings }) => {
      if (shouldDisplayQAForm) {
        // convert booleans to numbers
        const ratings = qaFormRatings.map(({ ratings, titleId }) => {
          const rating = ratings.find(({ value }) => value);
          return { key: titleId, value: rating?.key ?? null };
        });

        if (isQAFromMandatory) {
          const totalChoices = ratings.reduce((acc, { value }) => {
            return acc + (value ? 1 : 0);
          }, 0);

          const percentageOfAnsweredQuestions = (totalChoices / ratings.length) * 100;
          if (percentageOfAnsweredQuestions < 80) {
            setValidationError("task.modal.error-qa-from");
            return;
          }
        }

        setValidationError(null);
        deliverTask(true, true, { comment, ratings });
      } else {
        deliverTask(true, true);
      }
    }),
  };
};
