import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import NavigationTabsHeader from "components/header/NavigationTabsHeader";
import TaskBaseListFilter from "pages/project/common/taskBase/filter/TaskBaseListFilter";
import { useTaskBaseFilter } from "pages/project/common/taskBase/filter/useTaskBaseFilter";
import TaskBaseListCard from "pages/project/common/taskBase/list/TaskBaseListCard";
import TaskBaseListTable from "pages/project/common/taskBase/list/TaskBaseListTable";
import AcceptValidationRequestModal from "pages/project/request/components/modals/AcceptValidationRequestModal";
import RejectValidationRequestModal from "pages/project/request/components/modals/RejectValidationRequestModal";
import RequestSideBar from "pages/project/request/components/sidebar/RequestSideBar";
import { REQUEST_LIST_TYPE, RequestListRouteParam, RequestListType } from "pages/project/request/request.typing";
import { useValidationRequestCounts, useValidationRequestListByType } from "query/request.query";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { cn } from "utils/ui";

const partialTabs: Array<{ name: string; to: RequestListType; count: 0 }> = [
  { name: "request.navigation.pending", to: REQUEST_LIST_TYPE.pending, count: 0 },
];

function RequestListPage() {
  const { t } = useTranslation();
  const [filterParams, onChangeParams] = useTaskBaseFilter("request");
  const { id: requestId, type = REQUEST_LIST_TYPE.pending, action } = useParams<RequestListRouteParam>();
  const { requests, hasRequests, isLoading, error } = useValidationRequestListByType(type, filterParams);
  const counts = useValidationRequestCounts();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const methods = useForm();
  const taskBaseStartDateLabel = t("task-base.fields.estimatedStartOn");
  const isDetailOpen = !!requestId;
  const tabs = partialTabs.map((t) => ({
    ...t,
    count: t.to === "pending" ? counts.pending : counts.accepted,
    buildUrl: () => `/project/request/list/${t.to}?${searchParams.toString()}`,
  }));

  const handleOnDecline = () => {
    navigate(`/project/request/list/${type}/${requestId}/decline?${searchParams.toString()}`);
  };

  const handleOnAccept = () => {
    navigate(`/project/request/list/${type}/${requestId}/accept?${searchParams.toString()}`);
  };

  const handleOnSideBarClose = () => {
    navigate(`/project/request/list/${type}?${searchParams.toString()}`);
  };

  return (
    <div className="min-h-full">
      <div className="flex flex-col sm:flex-1">
        <NavigationTabsHeader
          tabs={tabs}
          selectedTab={type}
          dataTestId="requests-nav"
          pageName="requests"
          subTitle={t(`request.subTitle.${type}`)}
          title={t("request.title")}
        />
        <div className="flex flex-row">
          <div className={cn("flex-1", { "hidden lg:block": isDetailOpen })}>
            <div className={cn("my-4 ml-4", { "mr-4": !isDetailOpen })}>
              <TaskBaseListFilter
                isDetailOpen={isDetailOpen}
                params={filterParams}
                onChange={onChangeParams}
                isUserSupplier={false}
              />
              <Loader isShown={isLoading} />
              {!isLoading && !hasRequests && (
                <EmptyState
                  title={t(`request.empty.${type}.title`)}
                  subtitle={
                    error
                      ? t("error:common.server")
                      : filterParams.term && filterParams.term.length > 0
                        ? t("request.empty.noResult")
                        : t(`request.empty.${type}.subtitle`)
                  }
                />
              )}
              {!isLoading &&
                hasRequests &&
                (filterParams.view === "table" ? (
                  <TaskBaseListTable
                    type="request"
                    tasksBase={requests}
                    selectedTaskBaseId={requestId}
                    listFilterParams={filterParams}
                    onChange={onChangeParams}
                    taskBaseColumnName={t("request.fields.request")}
                    taskBaseStartDateLabel={taskBaseStartDateLabel}
                    isUserSupplier={false}
                  />
                ) : (
                  <TaskBaseListCard
                    type="request"
                    tasksBase={requests}
                    selectedTaskBaseId={requestId}
                    listFilterParams={filterParams}
                    taskBaseStartDateLabel={taskBaseStartDateLabel}
                    isUserSupplier={false}
                  />
                ))}
            </div>
          </div>
          {!isLoading && hasRequests && requestId && (
            <>
              <RequestSideBar
                selectedRequestId={requestId}
                onClose={handleOnSideBarClose}
                onErrorNode={() => (
                  <Navigate to={`/project/requests/list/${type}?${filterParams.toString()}`} replace={true} />
                )}
                onAccept={handleOnAccept}
                onDecline={handleOnDecline}
                filterType={type}
              />
              {action === "accept" && <AcceptValidationRequestModal requestId={requestId} show={true} />}
              {action === "decline" && (
                <FormProvider {...methods}>
                  <RejectValidationRequestModal filterType={type} requestId={requestId} show={true} />
                </FormProvider>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestListPage;
