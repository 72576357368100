import { FileTextIcon, LucideIcon } from "lucide-react";

interface Props {
  title: string;
  subtitle?: string;
  icon?: LucideIcon;
}

const EmptyState = ({ title, subtitle, icon: Icon = FileTextIcon }: Props) => {
  return (
    <div className="mt-12 text-center">
      <Icon className="mx-auto size-10 text-gray-400" />
      <h3 data-test-id="empty-state-title" className="text-color-primary mt-2 text-2xl font-medium">
        {title}
      </h3>
      {subtitle && (
        <p data-test="empty-state-subtitle" className="text-color-secondary mx-auto mt-1 max-w-lg text-sm">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default EmptyState;
