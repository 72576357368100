import { datadogRum } from "@datadog/browser-rum";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getXSRFToken, requestLogin } from "api/user.api";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { formatServerError } from "utils/format";
import * as yup from "yup";
import { ObjectSchema } from "yup";

type LoginFormModel = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("error");

  const schema: ObjectSchema<LoginFormModel> = yup.object().shape({
    email: yup.string().email(t("yup.email.badFormat")).required(t("yup.email.required")),
    password: yup.string().required(t("yup.password.required")),
    rememberMe: yup.boolean().default(false).optional(),
  });
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<LoginFormModel>({
    resolver: yupResolver(schema),
  });

  const {
    isPending,
    error: axiosError,
    mutate,
    isSuccess,
    isError,
  } = useMutation<unknown, AxiosError, LoginFormModel>({
    mutationKey: ["user-login"],
    mutationFn: ({ email, password, rememberMe }: LoginFormModel) => requestLogin(email, password, rememberMe),
    retry: false,
    onSuccess: async (_: unknown, { email, rememberMe }: LoginFormModel) => {
      datadogRum.setUser({ email: email });
      datadogRum.addAction("login-success", { email, rememberMe });
      queryClient.removeQueries(); // invalidate all new login
      const from = (location.state as { from: Location })?.from?.pathname ?? "/";
      navigate(from, { replace: true });
    },
    onError: (error: AxiosError, { email, rememberMe }: LoginFormModel) => {
      datadogRum.addAction("login-failed", {
        email,
        rememberMe,
        error: { status: error.response?.status, message: error.message, response: error.response?.data },
      });
      resetField("password");
    },
  });

  useEffect(() => {
    datadogRum.addAction("fetch-XSRF");
    getXSRFToken().catch(() => Promise.resolve());
  }, []);

  const serverError = () => {
    return formatServerError(axiosError, (axiosResponse) => {
      if (axiosResponse.status === 401) {
        return t("login.credentials");
      }
    });
  };

  const onSubmit: SubmitHandler<LoginFormModel> = ({ email, password, rememberMe }) => {
    mutate({ email, password, rememberMe });
  };

  return {
    isPending,
    isSuccess,
    isError,
    serverError: serverError(),
    errors,
    register,
    login: handleSubmit(onSubmit),
  };
};
