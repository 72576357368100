import ErrorLayoutPage from "layout/ErrorLayoutPage";
import { useTranslation } from "react-i18next";
import { NotFoundPageSource } from "routes/routes.typing";

interface Props {
  source?: NotFoundPageSource;
}

function NotFoundPage({ source }: Props) {
  const { t } = useTranslation("error");

  let description = "";

  switch (source) {
    case NotFoundPageSource.Offer:
      description = t("page.notfound.request-description");
      break;

    case NotFoundPageSource.Task:
      description = t("page.notfound.task-description");
      break;

    default:
      description = t("page.notfound.description");
  }

  return <ErrorLayoutPage errorCode={404} title={t("page.notfound.title")} description={description} />;
}

export default NotFoundPage;
