import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Loader from "components/Loader";
import Button from "components/buttons/Button";
import TextAreaFormField from "components/form/TextAreaFormField";
import Modal from "components/modal/Modal";
import { useAcceptOfferForm } from "pages/project/offer/components/modals/useAcceptOfferForm";
import { useOffer } from "query/offer.query";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  offerId?: string;
}

const AcceptOfferModal = ({ show, offerId }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "offer.modal.accept" });
  const { isLoading: isOfferLoading, selectedOffer } = useOffer(offerId);
  const { accept, reset, register, isPending } = useAcceptOfferForm(offerId ?? "");

  if (isOfferLoading || !selectedOffer) {
    return null;
  }

  return (
    <>
      <Modal isShown={show} onClose={() => null}>
        <div>
          {/*Icon*/}
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          {/*Header*/}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
              <div>{t("title")}</div>
              <div>{selectedOffer.taskCode}</div>
            </Dialog.Title>
            <div className="mt-6 flex flex-col text-center">
              <div className="text-base font-medium">{t("subTitle")}</div>
            </div>
          </div>

          {/*body*/}
          <div>
            <form className="mx-4 mt-6 flex flex-col space-y-5" onSubmit={accept}>
              {isPending ? (
                <Loader isShown={true} />
              ) : (
                <TextAreaFormField
                  id="comment"
                  label={t("form.comment")}
                  className="max-h-52"
                  {...register("comment")}
                />
              )}
              <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
                <Button type="reset" disabled={isPending} color="ternary" label={t("cancel")} onClick={reset} />
                <Button type="submit" disabled={isPending} color="primary" label={t("confirm")} />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AcceptOfferModal;
