import api from "api/api";
import { LanguageType, SUPPORTED_LANGUAGES } from "utils/language";
import {
  ValidationExecutionRequestDto,
  ValidationRequestDtoListResponse,
  ValidationRequestRejectionReasonDto,
  ZipFullDownloadDtoResponse,
} from "api/api.typing";
import { requestDownloadFile } from "api/utils";

const VALIDATION_REQUESTS_BASE = "/api/validationRequests";

export const getValidationRequestId = async (tempKey: string): Promise<number | null> => {
  const validationEmailCustomerContact = 9;
  return await api
    .get(`/api/AnonymousKeyStores/getByKey?anonymousObject=${validationEmailCustomerContact}&relatorKey=${tempKey}`)
    .then((response) => response.data.relatedId)
    .catch(() => null);
};

export const fetchValidationRequest = async (
  language: LanguageType,
  id: string
): Promise<ValidationExecutionRequestDto> => {
  const response = await api.get<ValidationExecutionRequestDto>(
    `${VALIDATION_REQUESTS_BASE}/${id}?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );

  return response.data;
};

export const fetchValidationRequests = async (language: LanguageType): Promise<ValidationRequestDtoListResponse> => {
  const response = await api.get<ValidationRequestDtoListResponse>(
    `${VALIDATION_REQUESTS_BASE}?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );
  return response.data;
};

export const requestZipFullValidationRequestDownload = async (
  requestId: string,
  language: LanguageType,
  hubConnectionId: string
): Promise<ZipFullDownloadDtoResponse> => {
  const response = await api.post(`${VALIDATION_REQUESTS_BASE}/${requestId}/zipFullDownload`, {
    validationRequestCustomerContactId: requestId,
    languageCode: SUPPORTED_LANGUAGES[language].tempoCode,
    hubConnectionId,
  });

  return response.data;
};

export const requestDownloadFullValidationRequestZip = async (requestId: string, guid: string) => {
  await requestDownloadFile(`${VALIDATION_REQUESTS_BASE}/${requestId}/fullDownload?guid=${guid}`, {
    name: "file",
    extension: "zip",
  });
};

export const requestAcceptValidationRequest = async (requestId: string, comment?: string): Promise<void> => {
  await api.put<void>(`${VALIDATION_REQUESTS_BASE}/${requestId}/accept`, {
    validationExecutionRequestCustomerContactId: requestId,
    comment: comment,
  });
};

export const requestRejectValidationRequest = async (
  requestId: string,
  reasonId: string,
  comment?: string
): Promise<void> => {
  await api.put<void>(`${VALIDATION_REQUESTS_BASE}/${requestId}/reject`, {
    validationExecutionRequestCustomerContactId: requestId,
    RejectReasonId: reasonId,
    comment: comment,
  });
};

export const fetchValidationRejectReasons = async (
  language: LanguageType
): Promise<ValidationRequestRejectionReasonDto[]> => {
  const response = await api.get<ValidationRequestRejectionReasonDto[]>(
    `/api/ValidationRequestRejectionReasons?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );
  return response.data;
};
