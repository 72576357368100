import Error from "components/Error";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/routes.const";
import { useNavigate } from "react-router-dom";
import Button from "components/buttons/Button";

interface Props {
  error: string;
}

export function ResetPasswordTokenError({ error }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-6">
      <Error error={error} />
      <Button
        onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
        label={t("set-password.ask-again")}
        className="text-sm font-medium"
        dataActionName="resend-reset-password-request"
        dataTestId="link-resend-reset-password-request"
      />
    </div>
  );
}
