import { Link, LinkProps, useSearchParams } from "react-router-dom";
import { FC } from "react";

const LinkWithParams: FC<LinkProps> = ({ to, children, ...props }: LinkProps) => {
  const [params] = useSearchParams();
  return (
    <Link to={`${to}?${params.toString()}`} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithParams;
