import { use } from "i18next";
import { DateTime } from "luxon";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES_KEYS, detectLanguage } from "utils/language";
import enumEn from "./en/enum.json";
import errorEn from "./en/error.json";
import translationEn from "./en/translation.json";
import enumFr from "./fr/enum.json";
import errorFr from "./fr/error.json";
import translationFr from "./fr/translation.json";

export const resources = {
  en: { translation: translationEn, error: errorEn, enum: enumEn },
  fr: { translation: translationFr, error: errorFr, enum: enumFr },
};

void use(initReactI18next).init({
  lng: detectLanguage(),
  supportedLngs: SUPPORTED_LANGUAGES_KEYS,
  defaultNS: "translation",
  ns: ["translation", "error", "enum"],
  nonExplicitSupportedLngs: true, // Allows "en-US" and "en-UK" to be implicitly supported as "en"
  fallbackLng: DEFAULT_LANGUAGE,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format, lng) => {
      if (value === undefined || value === null) return value;

      if (format === "number") {
        return value.toLocaleString(DEFAULT_LANGUAGE);
      }
      const date = new Date(value);
      let dateTimeFormat = DateTime.DATE_MED;
      if (format === "mediumDateTime") {
        dateTimeFormat = DateTime.DATETIME_MED;
      }
      if (!isNaN(date.getTime())) {
        return DateTime.fromJSDate(date)
          .setLocale(lng ?? DEFAULT_LANGUAGE)
          .toLocaleString(dateTimeFormat);
      }
      return value ?? "-";
    },
  },
  resources,
});
