import { USER_TYPES, User, WorkflowExecutionType } from "api/api.typing";
import { clsx } from "clsx";
import Badge from "components/Badge";
import EmptyState from "components/EmptyState";
import { Button } from "components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { FileBoxIcon, FileTextIcon, InfoIcon, MessageSquareMoreIcon } from "lucide-react";
import { SideBar } from "pages/project/common/SideBar";
import {
  MEMSOURCE_CAT_TOOL_ID,
  TaskFileUploadType,
  XTM_CAT_TOOL_ID,
  getCatToolName, TRANSLATE5_CAT_TOOL_ID,
} from "pages/project/common/taskBase.typing";
import { FileListTable } from "pages/project/common/taskBase/list/FileListTable";
import { MemSourceProjectLinksTable } from "../../../common/taskBase/list/MemSourceProjectLinksTable";
import { XtmJobLinksTable } from "pages/project/common/taskBase/list/XtmJobLinksTable";
import { TaskBaseDetail } from "pages/project/common/taskBase/sidebar/TaskBaseDetail";
import TaskBaseFilesDownload from "pages/project/common/taskBase/sidebar/TaskBaseFilesDownload";
import TaskBaseFilesUpload from "pages/project/common/taskBase/sidebar/TaskBaseFilesUpload";
import TaskBaseNotesUpload from "pages/project/common/taskBase/sidebar/TaskBaseNotesUpload";
import TaskInstructions from "pages/project/common/taskBase/sidebar/TaskInstructions";
import UploadTaskMaterialModal from "pages/project/task/components/modals/UploadTaskMaterialModal";
import { DeliveryButton } from "pages/project/task/components/sidebar/DeliveryButton";
import { Task, TaskListType, TaskStatus } from "pages/project/task/task.typing";
import { useDownloadPurchaseOrder, useTask } from "query/task.query";
import { useUser } from "query/user.query";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTaskFileStore } from "store/useTaskFileStore";
import { QaFormSidebar } from "./QaFormSidebar";
import { UserHint } from "./UserHint";
import { XtmsideBar } from "./XtmSegmentSideBar";
import { Translate5ProjectLinksTable } from "../../../common/taskBase/list/Translate5ProjectLinksTable";

interface Props {
  selectedTaskId: string;
  onClose: () => void;
  onErrorNode: () => void;
  onDeliverClicked: () => void;
  filterType: TaskListType;
}

const TaskSideBar = ({ selectedTaskId, ...props }: Props) => {
  const { t } = useTranslation();
  const { isLoading, selectedTask } = useTask(selectedTaskId);
  const { data: currentUser } = useUser();

  if (isLoading || !currentUser) {
    return null;
  }

  if (!selectedTask) {
    if (selectedTaskId) {
      // todo remove this when get taskBase by id is ready
      toast.error(
        <div className="ml-2 flex flex-col space-y-1">
          <span className="text-color-primary">{t("task.detail.error.title")}</span>
          <span className="text-color-secondary text-sm">{t("task.detail.error.description")}</span>
        </div>,
        {
          id: "no-task-id",
          className: "bg-color-primary",
        }
      );
      props.onErrorNode();
      return null;
    }
    return null;
  }

  return <TaskSideBarInner {...props} selectedTask={selectedTask} currentUser={currentUser} />;
};

type TaskSideBarInnerProps = Omit<Props, "selectedTaskId"> & { selectedTask: Task; currentUser: User };

type UploadModalState = TaskFileUploadType | "hidden";

const TaskSideBarInner: FC<TaskSideBarInnerProps> = ({
  selectedTask,
  currentUser,
  ...props
}: TaskSideBarInnerProps) => {
  const isMemsource =
    selectedTask.catToolId === MEMSOURCE_CAT_TOOL_ID &&
    selectedTask.workflowExecutionType === WorkflowExecutionType.CAT;
  const isXtm =
    selectedTask.catToolId === XTM_CAT_TOOL_ID && selectedTask.workflowExecutionType === WorkflowExecutionType.CAT;
  const isTranslate5 =
    selectedTask.catToolId === TRANSLATE5_CAT_TOOL_ID &&
    selectedTask.workflowExecutionType === WorkflowExecutionType.CAT;

  const { t } = useTranslation();
  const [uploadModalState, setUploadModalState] = useState<UploadModalState>("hidden");

  const { downloadPurchaseOrder } = useDownloadPurchaseOrder();

  const downloadState = useTaskFileStore((fileState) => ({
    shouldDisableAction: fileState.shouldDisableAction,
    requestZipFullDownload: fileState.requestZipFullDownload,
  }));

  const isDownloadDisabled = downloadState.shouldDisableAction(selectedTask.id);
  const isTaskOngoing = selectedTask.status === TaskStatus.OnGoing;
  const isTaskUpcoming = selectedTask.status === TaskStatus.UpComing;
  const isTaskDelivered = selectedTask.status === TaskStatus.Delivered;
  const canUpload = isTaskOngoing || isTaskDelivered;
  const isUserSupplier = currentUser.userType === USER_TYPES.supplier;
  const isCanEditSupplierContact =
    isUserSupplier &&
    isTaskUpcoming &&
    selectedTask.supplier.supplierInternallyManaged &&
    selectedTask.supplier.tasksVisibleForAllContacts;

  return (
    <>
      <SideBar
        onClose={props.onClose}
        title={
          <>
            <h2 className="text-color-primary h-full text-3xl font-medium sm:whitespace-nowrap">
              <button type="button" className="cursor-pointer text-left outline-0">
                {selectedTask.taskCode}
              </button>
            </h2>
            <Badge color="goldfish">{t("task.fields.task")}</Badge>
            <Badge>{selectedTask.taskType}</Badge>
            {getCatToolName(selectedTask.catToolId) && (
              <Badge dataTestId="task-type-value">{getCatToolName(selectedTask.catToolId)}</Badge>
            )}
          </>
        }
      >
        <div className="mb-2 flex flex-col gap-2 sm:flex-row" data-test="delivery-container">
          {isUserSupplier ? (
            <Button
              variant="outline"
              data-test="btn-download-po"
              className="flex w-full flex-nowrap gap-2 text-nowrap"
              onClick={() => downloadPurchaseOrder(selectedTask.id)}
            >
              <FileTextIcon className="h-5 w-5 shrink-0" data-icon="FileTextIcon" />
              <span>{t("translation:fileDownload.po")}</span>
            </Button>
          ) : null}
          {isTaskOngoing ? (
            <DeliveryButton
              taskId={selectedTask.id}
              isTaskMemSourceCatTool={isMemsource}
              isXtmCatTool={isXtm}
              shouldDisplayQAForm={selectedTask.shouldDisplayQAForm}
              onDeliverClicked={props.onDeliverClicked}
              isUserSupplier={isUserSupplier}
            />
          ) : null}
        </div>
        {isTaskOngoing ? (
          <div>
            <XtmsideBar isXtmCatTool={isXtm} taskId={selectedTask.id} />
            <QaFormSidebar shouldDisplayQAForm={selectedTask.shouldDisplayQAForm} />
          </div>
        ) : null}
        <UserHint
          fileTabName={isXtm || isMemsource ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")}
        />

        <Tabs defaultValue="overview" className="-mx-6 mt-4 border-t border-gray-300 dark:border-t-gray-600">
          <TabsList className="mx-2 flex flex-col items-start gap-2 border-y border-gray-300 py-4 sm:mx-0 sm:flex-row sm:justify-stretch sm:border-y-0 sm:pb-0 dark:border-gray-600">
            <TabsTrigger value="overview" data-test="overview-tab">
              <InfoIcon className="mr-2 size-4" />
              {t("translation:task.tabs.general")}
            </TabsTrigger>
            <TabsTrigger value="files" data-test="files-tab">
              <FileBoxIcon className="mr-2 size-4" />
              {isXtm || isMemsource ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")}
            </TabsTrigger>
            {isUserSupplier ? (
              <TabsTrigger value="instructions" data-test="instructions-tab">
                <MessageSquareMoreIcon className="mr-2 size-4" /> {t("translation:task.tabs.instructions")}
              </TabsTrigger>
            ) : null}
          </TabsList>
          <TabsContent value="overview" className="px-6">
            <TaskBaseDetail
              selectedTaskBase={selectedTask}
              taskBaseStartDateLabel={
                isTaskUpcoming ? t("task-base.detail.estimatedStartDate") : t("task-base.detail.startDate")
              }
              isUserSupplier={isUserSupplier}
              isCanEditSupplierContact={isCanEditSupplierContact}
            />
          </TabsContent>
          <TabsContent value="files" className="px-6 py-3">
            <div className={clsx(`grid grid-rows-3 gap-2 sm:grid-cols-3 sm:grid-rows-1`)}>
              <TaskBaseFilesDownload
                selectedTaskBase={selectedTask}
                disableDownload={isDownloadDisabled}
                onRequestZipFullDownload={(request, currentLanguage, hubConnectionId) =>
                  downloadState.requestZipFullDownload(request.id, request, currentLanguage, hubConnectionId)
                }
              />
              <TaskBaseFilesUpload
                canUpload={canUpload}
                onClick={() => setUploadModalState("delivery")}
                taskCode={selectedTask.taskCode}
              />
              <TaskBaseNotesUpload
                canUpload={canUpload}
                onClick={() => setUploadModalState("notes")}
                taskCode={selectedTask.taskCode}
              />
            </div>
            {!isXtm && isTaskUpcoming ? (
              <EmptyState
                title={t("task.detail.files.title-no-delivered-files")}
                subtitle={t("task.detail.files.title-no-delivered-files-detail")}
              />
            ) : null}
            {isXtm && (
              <div className="my-4 px-2">
                <XtmJobLinksTable isTaskOngoing={isTaskOngoing} taskId={selectedTask.id} />
              </div>
            )}

            {!isTaskUpcoming && (
              <div className="flex flex-col gap-2">
                {isMemsource && (
                  <div className="my-4 px-2">
                    <MemSourceProjectLinksTable taskId={selectedTask.id} />
                  </div>
                )}
                {isTranslate5 && (
                  <div className="my-4 px-2">
                    <Translate5ProjectLinksTable taskId={selectedTask.id} />
                  </div>
                )}
                {canUpload && (
                  <div className="my-4 px-2">
                    <FileListTable taskId={selectedTask.id} />
                  </div>
                )}
              </div>
            )}
          </TabsContent>

          {isUserSupplier ? (
            <TabsContent value="instructions" className="px-6">
              <TaskInstructions taskId={selectedTask.id} />
            </TabsContent>
          ) : null}
        </Tabs>
      </SideBar>
      {/*Important to recreate the modal each time to remove the old state. */}
      {uploadModalState !== "hidden" && (
        <UploadTaskMaterialModal
          onClose={() => setUploadModalState("hidden")}
          fileType={uploadModalState}
          taskId={selectedTask.id}
          catToolId={selectedTask.catToolId}
          taskCode={selectedTask.taskCode}
          canDeliver={
            isTaskOngoing &&
            !isMemsource &&
            selectedTask.taskType !== "QualityControl" &&
            !selectedTask.shouldDisplayQAForm
          }
          isUserSupplier={isUserSupplier}
        />
      )}
    </>
  );
};

export default TaskSideBar;
