import { datadogRum } from "@datadog/browser-rum";

export function setLocalStorageValue(key: string, value: string | object, action: string) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event("storage"));
  } catch (err) {
    datadogRum.addError(err, { action, from: "localstorage" });
  }
}

export function getLocalStorageValue<T extends string | object>(key: string, action: string): T | null {
  try {
    const value = localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : null;
  } catch (err) {
    datadogRum.addError(err, { action, from: "localstorage" });
    return null;
  }
}
