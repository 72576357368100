import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { REQUEST_LIST_TYPE } from "pages/project/request/request.typing";
import { useAcceptValidationRequestMutation } from "query/request.query";

export interface AcceptFormModel {
  comment?: string;
}

export const useAcceptValidationRequestForm = (requestId: string) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm<AcceptFormModel>();

  const onReset = () => {
    reset();
    navigate(`/project/request/list/${REQUEST_LIST_TYPE.pending}/${requestId}?${params.toString()}`);
  };

  const onSuccess = () => {
    reset();
    navigate(`/project/request/list/${REQUEST_LIST_TYPE.pending}/${requestId}?${params.toString()}`);
  };

  const { mutate, ...others } = useAcceptValidationRequestMutation(requestId, onSuccess);

  return { ...others, register, reset: onReset, accept: handleSubmit((modelParams) => mutate(modelParams)) };
};
