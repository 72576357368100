import { TaskBase } from "pages/project/common/taskBase.typing";
import { taskBasePriceDisplay } from "pages/project/common/taskBase/taskBasePrice";
import { useTranslation } from "react-i18next";
import UpdateSupplierContactModal from "../../../task/components/modals/UpdateSupplierContactModal";
import SideBarLabelMultiValue from "./SideBarLabelMultiValue";
import SideBarLabelValue from "./SideBarLabelValue";
import UpdateSideBarValue from "./UpdateSideBarValue";

interface Props {
  selectedTaskBase: TaskBase;
  taskBaseStartDateLabel: string;
  isUserSupplier: boolean;
  isCanEditSupplierContact?: boolean;
}

export const TaskBaseDetail = ({ selectedTaskBase, isUserSupplier, isCanEditSupplierContact, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 ">
      <div className="sm:col-span-2">
        <SideBarLabelValue
          label={t("task-base.fields.projectName")}
          value={selectedTaskBase.projectName ?? "-"}
          taskId={selectedTaskBase.id}
        />
      </div>
      <SideBarLabelValue
        label={t("task-base.detail.sourceLanguage")}
        value={selectedTaskBase.sourceLanguage ?? "-"}
        taskId={selectedTaskBase.id}
      />
      <SideBarLabelValue
        label={t("task-base.detail.targetLanguage")}
        value={selectedTaskBase.targetLanguage ?? "-"}
        taskId={selectedTaskBase.id}
      />
      <SideBarLabelValue
        label={t("task-base.detail.customer")}
        value={selectedTaskBase.customerName ?? "-"}
        taskId={selectedTaskBase.id}
      />
      <div className="flex flex-col items-start space-y-2">
        <label className="text-color-secondary">{t("task-base.detail.contact")}</label>
        <div className="flex flex-col justify-center space-y-0">
          <span className="font-medium capitalize">{selectedTaskBase.contactName ?? "-"}</span>
          {selectedTaskBase.contactEmail && (
            <a
              href={`mailto:${selectedTaskBase.contactEmail}`}
              data-test={`label-email-value-${selectedTaskBase.id}`}
              className="offset-ring-500 cursor-pointer select-none text-sm text-ribbon-500 outline-0 hover:underline dark:text-ribbon-400"
            >
              {selectedTaskBase.contactEmail}
            </a>
          )}
        </div>
      </div>
      <SideBarLabelValue
        label={props.taskBaseStartDateLabel}
        value={
          selectedTaskBase.startDate
            ? t("enum:dates.mediumDateTime", { date: selectedTaskBase.startDate })
            : t("task-base.fields.startOnEmpty")
        }
        taskId={selectedTaskBase.id}
      />
      <SideBarLabelValue
        label={t("task-base.detail.dueDate")}
        value={t("enum:dates.mediumDateTime", { date: selectedTaskBase.dueDate })}
        taskId={selectedTaskBase.id}
      />
      <SideBarLabelMultiValue
        label={t("task-base.detail.quantity")}
        values={
          selectedTaskBase.secondQuantity
            ? [
                `${t("enum:intlNumber", { val: selectedTaskBase.quantity })}`,
                `${t("enum:intlNumber", { val: selectedTaskBase.secondQuantity })}`,
              ]
            : [`${t("enum:intlNumber", { val: selectedTaskBase.quantity })}`]
        }
        taskId={selectedTaskBase.id}
      />
      <SideBarLabelMultiValue
        label={t("task-base.detail.unit")}
        values={[selectedTaskBase.unit, selectedTaskBase.secondUnit]}
        taskId={selectedTaskBase.id}
      />
      {isUserSupplier ? (
        <SideBarLabelValue
          label={t("task-base.detail.total")}
          value={taskBasePriceDisplay(selectedTaskBase)}
          taskId={selectedTaskBase.id}
        />
      ) : null}
      {isCanEditSupplierContact ? (
        <UpdateSideBarValue
          task={selectedTaskBase}
          label={"task-base.detail.supplier-contact"}
          value={selectedTaskBase.supplier.supplierContact.name ?? "-"}
          renderModal={(isUpdateModalShown, toggleDisplayUpdateModalShown) =>
            isUpdateModalShown ? (
              <UpdateSupplierContactModal
                task={selectedTaskBase}
                shown={isUpdateModalShown}
                onClose={() => {
                  toggleDisplayUpdateModalShown(false);
                }}
              />
            ) : null
          }
        />
      ) : isUserSupplier ? (
        <SideBarLabelValue
          label={t("task-base.detail.supplier-contact")}
          value={selectedTaskBase.supplier?.supplierContact?.name ?? "-"}
          taskId={selectedTaskBase.id}
        />
      ) : null}
    </div>
  );
};
