import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppRoutes from "routes/AppRoutes";
import { loadAppcuesScripts } from "utils/appcues";
import { displayWarning } from "utils/consoleWarning";
import bootstrapDatadog from "utils/datadog";
import "./i18n/config";
import "./index.css";

// check if we should load appcue, if PARTNER_PORTAL_APPCUES_ENABLED = true
// Appcue js files will be looaded
// To check if Appcue is active or not. Execute "window.AppCues.debug()" in the browser console (you should see debug dialog)
loadAppcuesScripts();

if (import.meta.env.PROD) {
  bootstrapDatadog();
  displayWarning();
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <AppRoutes />
  </StrictMode>
);
