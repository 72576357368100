import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  fileTabName: string;
}
export function UserHint({ fileTabName }: Props) {
  const { t } = useTranslation();
  const [isVisble, setIsVisible] = useState(() => localStorage.getItem("user-hint") !== "false");
  const onClose = () => {
    setIsVisible(false);
    localStorage.setItem("user-hint", "false");
  };
  if (!isVisble) return null;
  return (
    <Alert variant="info" className="mt-2" onClose={onClose}>
      <AlertTitle className="text-sm">{t("task.detail.userHint.title")}</AlertTitle>
      <AlertDescription className="text-xs">
        <Trans
          i18nKey="task.detail.userHint.description"
          values={{ fileTabName }}
          components={{
            s: <span className="font-semibold" />,
          }}
        />
      </AlertDescription>
    </Alert>
  );
}
