import Loader from "components/Loader";
import { XtmSegments } from "pages/project/common/taskBase/list/XtmSegments";
import { useXtmData } from "query/task.query";

interface Props {
  taskId: string;
  isXtmCatTool: boolean;
}
export function XtmsideBar({ taskId, isXtmCatTool }: Props) {
  const { steps, isFetching: xtmIsFetching } = useXtmData(taskId, true, isXtmCatTool);
  if (!isXtmCatTool) {
    return null;
  }
  if (xtmIsFetching) {
    return <Loader isShown={true} />;
  }
  // not all segments are done
  return steps ? <XtmSegments xtmSteps={steps} /> : null;
}
