import { Popover, Transition } from "@headlessui/react";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { UploadPopoverMenu } from "pages/project/task/components/UploadDialog/UploadPopoverMenu";
import UploadPopoverStatuses from "pages/project/task/components/UploadDialog/UploadPopoverStatuses";
import UploadPopoverTitle from "pages/project/task/components/UploadDialog/UploadPopoverTitle";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { canCancelAllUploads, isFilesUploading, useFileUploadStore } from "store/useFileUploadStore";
import { cn } from "utils/ui";

const UploadPopover: FC = () => {
  const {
    taskCode,
    fileStatuses: fileStatusesMap,
    fileUploaded,
    fileFailedOrCanceled,
    cancel,
    cancelAll,
    reset,
  } = useFileUploadStore();

  const { t } = useTranslation();

  const fileStatuses = [...fileStatusesMap.values()];

  const isHidden =
    fileStatuses.filter((fs) => fs.status !== "waiting").length === 0 &&
    fileUploaded.length === 0 &&
    fileFailedOrCanceled.length === 0;

  const isCanCancelAll = canCancelAllUploads(fileStatusesMap);
  const isUploadInProgress = isFilesUploading(fileStatusesMap);

  if (isHidden) {
    return null;
  }

  return (
    <Popover className="relative" defaultChecked={true}>
      {({ open }) => (
        <>
          <Popover.Button
            type="button"
            data-test="upload-dialog-toggle"
            as={"button"}
            className={cn(
              "bg-transparent p-2.5 hover:text-gray-500 dark:text-slate-400 dark:hover:bg-slate-700",
              isUploadInProgress || open ? "text-ribbon-500" : "text-gray-400",
              {
                "rounded-full ring ring-ribbon-500": open,
                "animate-pulse": !open && isUploadInProgress,
              }
            )}
          >
            <span
              data-test="upload-dialog-toggle-children"
              className={cn("flex  items-center space-x-2", { "text-ribbon-500": isUploadInProgress || open })}
            >
              <CloudArrowUpIcon className="h-6 w-6" data-icon="CloudUploadIcon" />
              {isUploadInProgress && (
                <span className={cn("text-base", { "font-medium": open })}>
                  {t("task.upload.popover.btn-uploading")}
                </span>
              )}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              data-test="upload-dialog-panel"
              className="absolute -right-4 z-10 mt-2 w-screen max-w-2xl px-4"
            >
              <div className="rounded-lg bg-white shadow-lg ring-1 ring-black/5 dark:bg-slate-700">
                <div className="mx-4 border-b border-gray-200 py-2 dark:border-slate-500">
                  <div className="flex items-center">
                    <UploadPopoverTitle
                      isUploadInProgress={isUploadInProgress}
                      taskCode={taskCode}
                      fileStatuses={fileStatuses}
                    />
                    <UploadPopoverMenu
                      isUploadInProgress={isUploadInProgress}
                      reset={reset}
                      cancelAll={cancelAll}
                      isCanCancelAll={isCanCancelAll}
                    />
                  </div>
                </div>
                <UploadPopoverStatuses fileStatuses={fileStatuses} onCancel={cancel} />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default UploadPopover;
