import { BaseComponentProps } from "components/typings";
import { FC, PropsWithChildren } from "react";
import { cn } from "utils/ui";
import styles from "./Badge.module.css";

interface Props extends Omit<BaseComponentProps, "dataActionName"> {
  className?: string;
  color?: "default" | "green" | "sky" | "goldfish";
}

const Badge: FC<PropsWithChildren<Props>> = ({
  dataTestId,
  children,
  className = "",
  color = "default",
}: PropsWithChildren<Props>) => {
  return (
    <div className={cn(styles.badge, styles[color], className)} data-test={dataTestId}>
      {children}
    </div>
  );
};

export default Badge;
