import { createTaskBaseFileStore } from "store/createTaskBaseFileStore";
import { ValidationRequest } from "pages/project/request/request.typing";
import { requestDownloadFullValidationRequestZip, requestZipFullValidationRequestDownload } from "api/request.api";

export const useValidationRequestFileStore = createTaskBaseFileStore<ValidationRequest>(
  "request",
  (request, currentLanguage, hubConnectionId) =>
    requestZipFullValidationRequestDownload(request.id, currentLanguage, hubConnectionId),
  (id, guid) => requestDownloadFullValidationRequestZip(id, guid)
);
