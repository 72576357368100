import { Popover, Transition } from "@headlessui/react";
import { BaseComponentProps } from "components/typings";
import { Fragment, MutableRefObject, ReactElement, ReactNode } from "react";
import { cn } from "utils/ui";

interface Props extends BaseComponentProps {
  className?: string;
  selection: ReactNode;
  children: (
    open: boolean,
    close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void
  ) => ReactElement;
}

const Dropdown = ({ className, children, selection, dataTestId, dataActionName }: Props) => {
  return (
    <Popover data-test={dataTestId} data-dd-action-name={dataActionName} className={cn("relative", className)}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={cn(
              "rounded-lg p-2.5 text-sm font-medium",
              "hover:bg-slate-100 focus:outline-none focus:ring-4 focus:ring-slate-200",
              "dark:hover:bg-slate-700 dark:focus:ring-slate-700"
            )}
          >
            {selection}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute bottom-12 right-0 z-10">
              <div className="overflow-hidden rounded-lg bg-white p-2 shadow-lg dark:bg-slate-700">
                {children(open, close)}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Dropdown;
