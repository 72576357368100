import { Navigate, useParams } from "react-router-dom";
import { useTask } from "query/task.query";
import { mapToListType } from "./task.typing";

const TaskRedirectFromId = () => {
  const { id: taskId = "" } = useParams<{ id: string }>();
  const { isLoading, selectedTask } = useTask(taskId);

  if (isLoading) return null;

  if (!selectedTask) {
    return <Navigate to="/error/task/404" replace={true} />;
  }

  const listType = mapToListType(selectedTask.status);

  if (!listType) return <Navigate to="/error/500" replace={true} />;

  return <Navigate to={`/project/task/list/${listType}/${taskId}`} replace={true} />;
};

export default TaskRedirectFromId;
