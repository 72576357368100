import { ComputerDesktopIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import Dropdown from "components/Dropdown/Dropdown";
import DropdownItem from "components/Dropdown/DropdownItem";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeType, getTheme, updateTheme } from "utils/theme";

const THEMES = Object.entries({ light: SunIcon, dark: MoonIcon, system: ComputerDesktopIcon });

function ThemeSwitch() {
  const { t } = useTranslation("translation");
  const [theme, setTheme] = useState<ThemeType>(() => getTheme());

  const handleOnThemeChange = (fromTheme: ThemeType) => {
    setTheme(fromTheme);
    updateTheme(fromTheme);
  };

  return (
    <Dropdown
      dataTestId="btn-switch-theme"
      dataActionName={`switch-theme-${theme}`}
      selection={
        <div className="flex flex-row items-center">
          <MoonIcon className="hidden h-5 w-5 dark:block" />
          <SunIcon className="block h-5 w-5 dark:hidden" />
        </div>
      }
    >
      {(_, close) => {
        return (
          <div className="grid gap-1">
            {THEMES.map(([key, Icon]) => (
              <DropdownItem
                key={key}
                isActive={key === theme}
                dataTestId={`btn-change-theme-${key}`}
                dataActionName={`change-theme-to-${key}`}
                onClick={() => {
                  handleOnThemeChange(key as ThemeType);
                  close();
                }}
              >
                <div className="flex items-center space-x-2">
                  <Icon className="h-5 w-5" />
                  <span>{t(`theme.${key}`)}</span>
                </div>
              </DropdownItem>
            ))}
          </div>
        );
      }}
    </Dropdown>
  );
}

export default ThemeSwitch;
