import { BaseComponentProps } from "components/typings";
import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";
import { cn } from "utils/ui";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  id: string;
  label?: string;
}

type CheckboxProps = Props & BaseComponentProps;

type CheckboxFormProps<FormModel extends { [x: string]: unknown }> = CheckboxProps &
  Omit<ReturnType<UseFormRegister<FormModel>>, "name"> & { name: string };

function CheckBoxInner(
  { id, label, className, dataTestId, dataActionName, ...props }: CheckboxProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <div className="flex items-center">
      <input
        {...props}
        ref={ref}
        id={id}
        data-test={dataTestId}
        data-dd-action-name={dataActionName}
        type="checkbox"
        className={cn(
          "h-4 w-4 rounded border-gray-300 focus:ring-ribbon-300 dark:focus:ring-offset-gray-700",
          className
        )}
      />
      {label && (
        <label htmlFor={id} className="ml-2 block select-none text-sm">
          {label}
        </label>
      )}
    </div>
  );
}

function CheckboxFormInner<T extends { [x: string]: unknown }>(
  { id, label, className, dataTestId, dataActionName, ...props }: CheckboxFormProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <div className="flex items-center">
      <input
        {...props}
        ref={ref}
        id={id}
        data-test={dataTestId}
        data-dd-action-name={dataActionName}
        type="checkbox"
        className={cn(
          "h-4 w-4 rounded border-gray-300 focus:ring-ribbon-300 dark:focus:ring-offset-gray-700",
          className
        )}
      />
      {label && (
        <label htmlFor={id} className="ml-2 block select-none text-sm">
          {label}
        </label>
      )}
    </div>
  );
}

const CheckboxForm = forwardRef(CheckboxFormInner);
export const Checkbox = forwardRef(CheckBoxInner);

export default CheckboxForm;
