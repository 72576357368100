import { Navigate, useParams } from "react-router-dom";
import { useValidationRequest } from "query/request.query";
import { mapToListType } from "pages/project/request/request.typing";

const RequestRedirectFromId = () => {
  const { id: requestId = "" } = useParams<{ id: string }>();
  const { isLoading, validationRequest } = useValidationRequest(requestId);

  if (isLoading) return null;

  if (!validationRequest) {
    return <Navigate to="/error/404" replace={true} />;
  }

  const listType = mapToListType(validationRequest.status);

  if (!listType) return <Navigate to="/error/500" replace={true} />;

  return <Navigate to={`/project/request/list/${listType}/${requestId}`} replace={true} />;
};

export default RequestRedirectFromId;
