import { PreInvoiceDetailDto, PreInvoiceDto, PreInvoiceTaskDto } from "api/api.typing";
import api from "api/api";
import { LanguageType, SUPPORTED_LANGUAGES } from "utils/language";

export async function fetchPreInvoices(signal?: AbortSignal): Promise<PreInvoiceDto[]> {
  const response = await api.get<{ supplierPreInvoices: PreInvoiceDto[] }>("/api/SupplierPreInvoices", { signal });
  return response.data.supplierPreInvoices;
}

export async function fetchPreInvoiceTasks(
  preInvoiceId: number,
  language: LanguageType,
  signal?: AbortSignal
): Promise<PreInvoiceTaskDto[]> {
  const response = await api.get<{ supplierPreInvoiceTasks: PreInvoiceTaskDto[] }>(
    `/api/SupplierPreInvoices/${preInvoiceId}?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`,
    { signal }
  );
  return response.data.supplierPreInvoiceTasks;
}

export async function fetchPreInvoiceDetail(
  preInvoiceId: number,
  language: LanguageType,
  signal?: AbortSignal
): Promise<PreInvoiceDetailDto> {
  const response = await api.get<PreInvoiceDetailDto>(
    `/api/SupplierPreInvoices/detail/${preInvoiceId}?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`,
    { signal }
  );
  return response.data;
}
