import { useQuery } from "@tanstack/react-query";
import { getValidationRequestId } from "api/request.api";
import { Navigate, useParams } from "react-router-dom";

const RequestRedirectFromKey = () => {
  const { key: tempKey = "" } = useParams<{ key: string }>();
  const {
    isLoading,
    isError,
    data: requestId,
  } = useQuery({ queryKey: ["requestId", tempKey], queryFn: () => getValidationRequestId(tempKey) });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Navigate to="/error/500" replace={true} />;
  }

  if (!requestId) {
    return <Navigate to="/error/404" replace={true} />;
  }

  return <Navigate to={`/project/request/detail/${requestId}`} replace={true} />;
};

export default RequestRedirectFromKey;
