import api from "./api";
import { User } from "api/api.typing";

export const getXSRFToken = () => {
  return api.get("/api");
};

export const fetchMe = async (): Promise<User> => {
  const response = await api.get<User>("/api/users/me", {
    validateStatus: function (status) {
      return status === 200 || status === 401;
    },
  });
  return response.data;
};

export const requestLogin = async (email: string, password: string, rememberMe: boolean): Promise<void> => {
  const response = await api.post("/api/account/login", {
    username: email,
    password,
    rememberMe,
  });
  return response.data;
};

export const requestLogout = (): Promise<void> => api.post("/api/account/logoff");

export const requestResetPasswordLink = async (email: string): Promise<string> => {
  await api.post("/api/account/forgotpassword", { email });

  return email;
};

export const requestUpdatePassword = async (newPassword: string, resetPasswordToken: string): Promise<void> => {
  await api.post("/api/account/resetpassword", {
    newPassword,
    resetPasswordToken,
  });
};

export interface ResetPasswordTokenValidity {
  isValid: boolean | undefined;
}

export const getResetPasswordTokenValidity = async (
  resetPasswordToken: string | null
): Promise<ResetPasswordTokenValidity> => {
  if (!resetPasswordToken) {
    return { isValid: false };
  }
  const response = await api.get<{ isValid: boolean }>(
    `/api/account/checkResetPasswordToken?token=${resetPasswordToken}`
  );

  return { isValid: response.data.isValid };
};
