import Button from "components/buttons/Button";
import { Illustration500, Illustrations404 } from "components/svgs/Illustrations";
import PageFooter from "layout/PageFooter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes.const";

interface Props {
  title: string;
  description: string;
  errorCode: 401 | 404 | 500;
}

const ErrorLayoutPage = ({ title, errorCode, description }: Props) => {
  const { t } = useTranslation("error");
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(ROUTES.HOME, { replace: true });
  };
  return (
    <>
      <div className="flex min-h-full flex-col pt-16">
        <main className="mx-auto flex w-full grow flex-col px-4 sm:justify-center sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center space-x-4 py-16 lg:flex-row">
            <div className="text-center">
              <p className="text-base font-semibold uppercase tracking-wide text-ribbon-500 dark:text-ribbon-300">
                {t("page.error-code", { errorCode })}
              </p>
              <h1 className="text-color-primary mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">{title}</h1>
              <p className="text-color-base mt-2 text-base">{description}</p>
              <div className="mt-6">
                <Button onClick={handleOnClick} label={t("page.goBackHome")} className="inline-flex items-center" />
              </div>
            </div>
            {errorCode === 404 && (
              <div className="order-first mb-16 hidden w-80 sm:block lg:order-last lg:w-full lg:max-w-md">
                <Illustrations404 />
              </div>
            )}

            {errorCode === 500 && (
              <div className="order-first mb-16 hidden w-96 sm:block lg:order-last lg:w-full lg:max-w-xl">
                <Illustration500 />
              </div>
            )}
          </div>
        </main>
        <PageFooter />
      </div>
    </>
  );
};

export default ErrorLayoutPage;
