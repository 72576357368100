import { useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "@tanstack/react-query";

function GlobalLoadingIndicatorProvider() {
  const isFetching = useIsFetching({ predicate: (query) => !query.queryKey?.includes("user") });
  const { t } = useTranslation();
  const loadingRef = useRef<string>();

  useEffect(() => {
    toast.dismiss(loadingRef.current);
    if (isFetching) {
      loadingRef.current = toast.loading(t("translation:common.loading"));
    }
  }, [isFetching, t]);

  return null;
}

export default GlobalLoadingIndicatorProvider;
