import Collapse from "components/Collapse";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { useTranslate5ProjectLinkList } from "query/task.query";
import { useTranslation } from "react-i18next";

interface Props {
  taskId: string;
}

export const Translate5ProjectLinksTable = ({ taskId }: Props) => {
  const { t } = useTranslation();
  const { links, hasLinks, isFetching, error } = useTranslate5ProjectLinkList(taskId);

  if (isFetching) {
    return <Loader isShown={true} />;
  }

  if (!hasLinks) {
    return (
      <EmptyState
        title={t("task.detail.jobLinks.translate5.title")}
        subtitle={error ? t("error:common.server") : t("task.detail.catProjectLinks.title-no-project-links-detail")}
      />
    );
  }

  return (
    <Collapse
      defaultOpen={true}
      header={
        <h5 className="my-2 space-x-2 font-medium">
          <span>{t("task.detail.jobLinks.translate5.title")}</span>
          <span>({links.length})</span>
        </h5>
      }
    >
      <ul className="ml-6 max-h-[200px] list-none overflow-y-auto">
        {links.map((link) => (
          <li key={link.projectId} className="my-2">
            <a
              href={link.projectLink}
              rel="noopener noreferrer"
              target="_blank"
              data-test="link-open-translate5-link"
              data-dd-action-name="btn-open-translate5-link"
              className="cursor-pointer select-none text-base text-ribbon-500 outline-ribbon-300 hover:underline dark:text-ribbon-400"
            >
              <span>{link.projectName}</span>
            </a>
          </li>
        ))}
      </ul>
    </Collapse>
  );
};
