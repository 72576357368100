import { useTranslation } from "react-i18next";
import { LanguageType, SUPPORTED_LANGUAGES, updateLanguage } from "utils/language";
import Dropdown from "components/Dropdown/Dropdown";
import DropdownItem from "components/Dropdown/DropdownItem";

const LANGUAGES = Object.entries(SUPPORTED_LANGUAGES);

function LanguageDropdown() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (langKey: LanguageType) => {
    updateLanguage(langKey);
  };

  const [, { name: CurrentName, flag: CurrentFlag }] = LANGUAGES.find(([key]) => key === i18n.language) ?? LANGUAGES[0]; // take first

  return (
    <Dropdown
      dataTestId="btn-open-language-dropdown"
      dataActionName="open-language-dropdown"
      selection={
        <div className="flex flex-row items-center space-x-2">
          <CurrentFlag />
          <span>{CurrentName}</span>
        </div>
      }
    >
      {(_, close) => (
        <div className="grid gap-1">
          {LANGUAGES.map(([key, { name, flag: Flag }]) => {
            const isActive = key === i18n.language;
            return (
              <DropdownItem
                key={key}
                dataTestId={`btn-change-language-${key}`}
                dataActionName={`change-language-to-${key}`}
                isActive={isActive}
                onClick={() => {
                  handleChangeLanguage(key as LanguageType);
                  close();
                }}
              >
                <Flag />
                <span>{name}</span>
              </DropdownItem>
            );
          })}
        </div>
      )}
    </Dropdown>
  );
}

export default LanguageDropdown;
