import { useEffect, useState } from "react";
import uniqBy from "lodash/uniqBy";

export const useManagedFilesToBeUploaded = (acceptedFiles: File[]) => {
  const [files, setFiles] = useState<File[]>([]);

  const removeFile = (fromFile: File) => {
    setFiles(files.filter((file) => file.name !== fromFile.name));
  };

  useEffect(() => {
    setFiles((state) => uniqBy([...state, ...acceptedFiles], "name"));
  }, [acceptedFiles]);

  return { files, removeFile };
};
