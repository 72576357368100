import Button from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/routes.const";
import Success from "components/Success";
import { useNavigate } from "react-router-dom";

interface Props {
  emailSentTo: string;
  onResendResetLink: () => void;
  isCreateAccount: boolean;
}

export const ForgotPasswordSuccess = ({ emailSentTo, onResendResetLink, isCreateAccount }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "forgot-password" });
  const navigate = useNavigate();

  return (
    <>
      <Success>
        <div className="flex flex-col space-y-1">
          <span>{t(isCreateAccount ? "success.email-sent-create" : "success.email-sent-reset")}</span>
          <span className="font-bold">{emailSentTo}</span>
        </div>
      </Success>
      <div className="mt-6 flex flex-col items-start space-y-6">
        <Button
          onClick={() => navigate(ROUTES.LOGIN)}
          label={t("go-to-login")}
          className="w-full text-sm font-medium"
          dataActionName="go-to-login"
          dataTestId="link-forgot-password-to-login"
        />
        <button
          onClick={onResendResetLink}
          type="button"
          className="select-none text-sm font-medium text-ribbon-500 outline-ribbon-300 hover:underline dark:text-ribbon-400"
          data-dd-action-name="resend-forgot-password-request"
          data-test="link-resend-reset-password-request"
        >
          {t(isCreateAccount ? "success.re-send-create-link" : "success.re-send-reset-link")}
        </button>
      </div>
    </>
  );
};
