import { PencilIcon } from "@heroicons/react/20/solid";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskBase } from "../../taskBase.typing";
import SideBarLabelValue from "./SideBarLabelValue";

interface Props {
  label: string;
  value: string;
  task: TaskBase;
  renderModal: (isShown: boolean, toggleIsShown: (isShown: boolean) => void) => ReactElement | null;
}

const UpdateSideBarValue = ({ label, value, task, renderModal }: Props) => {
  const { t } = useTranslation();
  const [isUpdateModalShown, toggleDisplayUpdateModalShown] = useState<boolean>(false);
  return (
    <div className="flex flex-nowrap items-center gap-2">
      <SideBarLabelValue
        label={t(label)}
        value={value}
        taskId={task.id}
        icon={
          <button
            className="h-[16px] w-[16px] text-gray-400 hover:cursor-pointer hover:text-gray-900 dark:hover:text-gray-200 "
            onClick={() => toggleDisplayUpdateModalShown(true)}
          >
            <PencilIcon className="h-4 w-4" />
          </button>
        }
      />
      {renderModal(isUpdateModalShown, toggleDisplayUpdateModalShown)}
    </div>
  );
};

export default UpdateSideBarValue;
