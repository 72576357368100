import AuthenticationLayout from "layout/AuthenticationLayout";
import { useTranslation } from "react-i18next";
import { useGetResetPasswordTokenValidity } from "pages/authentication/setPassword/useGetSetPasswordTokenValidity";
import Loader from "components/Loader";
import { SetPasswordForm } from "pages/authentication/setPassword/SetPasswordForm";
import { ResetPasswordTokenError } from "pages/authentication/setPassword/SetPasswordTokenError";

function ResetPasswordPage() {
  const { isSuccess, isFetching, resetPasswordToken, serverError } = useGetResetPasswordTokenValidity();

  const { t } = useTranslation("translation", { keyPrefix: "set-password" });

  return (
    <AuthenticationLayout title={t("title")}>
      {isSuccess && resetPasswordToken && <SetPasswordForm resetPasswordToken={resetPasswordToken} />}
      <Loader isShown={isFetching} />
      {!isFetching && serverError && <ResetPasswordTokenError error={serverError} />}
    </AuthenticationLayout>
  );
}

export default ResetPasswordPage;
