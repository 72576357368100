import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { fetchEntityInvoiceInformation } from "api/entity.api";

export const useEntityInvoiceInformation = (entityId: number) => {
  const { data = null, isLoading } = useQuery<string, AxiosError>({
    queryKey: ["invoice-information", entityId],
    queryFn: ({ signal }) => fetchEntityInvoiceInformation(entityId, signal),
  });

  return { invoiceInformation: data, isLoading };
};
