interface Props {
  label: string;
  values: string[];
  taskId: string;
}

const SideBarLabelMultiValue = ({ label, values, taskId }: Props) => {
  return (
    <div className="flex flex-col items-start space-y-2">
      <label className="text-color-secondary">{label}</label>
      {values.map((value, i) => (
        <span
          key={`label-${label}-value-${i}`}
          data-test={`label-${label}-value-${taskId}-${i}`.replace(/\s/g, "_")}
          className="font-medium"
        >
          {value ?? ""}
        </span>
      ))}
    </div>
  );
};

export default SideBarLabelMultiValue;
