import Button from "components/buttons/Button";
import CopyToClipboard from "components/CopyToClipboard";
import { useTranslation } from "react-i18next";
import { LoaderIcon } from "react-hot-toast";
import { ArrowPathIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

interface Props {
  isLoading: boolean;
  label: string;
  canCopy: boolean;
  url: string | null;
  onClick?: () => void;
}

export const ShareLink = ({ isLoading, url, canCopy, label, onClick }: Props) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <div className="flex flex-nowrap items-center gap-2">
        <LoaderIcon />
        <span title="xtmProjectName" className="max-w-[200px] truncate sm:max-w-lg">
          {label}
        </span>
      </div>
    );
  }

  if (url === null) {
    return (
      <div className="flex flex-nowrap items-center gap-2">
        <Button color="ternary" className="hidden px-2 py-1 text-xs font-medium sm:block" onClick={() => onClick?.()}>
          {t("task.detail.jobLinks.generate-link")}
        </Button>
        <Button color="ternary" className="px-1.5 py-0.5 text-xs font-medium sm:hidden" onClick={() => onClick?.()}>
          <ArrowPathIcon className="h-4 w-4" />
        </Button>
        <span title="xtmProjectName" className="max-w-[200px] truncate sm:max-w-lg">
          {label}
        </span>
      </div>
    );
  }

  const link = (
    <a
      rel="noopener noreferrer"
      target="_blank"
      data-test="link-open-xtm-link"
      data-dd-action-name="btn-open-xtm-link"
      className="cursor-pointer select-none text-base text-ribbon-500 outline-ribbon-300 hover:underline dark:text-ribbon-400"
      onClick={() => onClick?.()}
      href={url}
    >
      <span className="flex flex-nowrap items-center gap-1">
        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
        <span title="xtmProjectName" className="max-w-[200px] truncate sm:max-w-lg">
          {label}
        </span>
      </span>
    </a>
  );

  if (canCopy)
    return (
      <CopyToClipboard value={url} onClick={onClick}>
        {link}
      </CopyToClipboard>
    );
  return link;
};
