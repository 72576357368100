import { AnonymousXtmOpenEditor } from "anonymous/pages/AnonymousXtmOpenEditor";
import { AnonymousXtmTask } from "anonymous/pages/AnonymousXtmTask";
import { USER_TYPES } from "api/api.typing";
import MainLayout from "layout/MainLayout";
import NotAllowedPage from "pages/NotAllowedPage";
import NotFoundPage from "pages/NotFoundPage";
import ServerErrorPage from "pages/ServerErrorPage";
import ForgotPasswordPage from "pages/authentication/forgotPassword/ForgotPasswordPage";
import LoginPage from "pages/authentication/login/LoginPage";
import ResetPasswordPage from "pages/authentication/setPassword/ResetPasswordPage";
import LegalInfoPage from "pages/legal/LegalInfoPage";
import LegalPrivacyPage from "pages/legal/LegalPrivacyPage";
import { PreInvoiceDetailPage } from "pages/pre-invoice/PreInvoiceDetailPage";
import PreInvoiceListPage from "pages/pre-invoice/PreInvoiceListPage";
import ProjectLayout from "pages/project/ProjectLayout";
import OfferListPage from "pages/project/offer/OfferListPage";
import OfferRedirectFromId from "pages/project/offer/OfferRedirectFromId";
import OfferRedirectFromKey from "pages/project/offer/OfferRedirectFromKey";
import { OFFER_LIST_TYPE } from "pages/project/offer/offer.typing";
import RequestListPage from "pages/project/request/RequestListPage";
import RequestRedirectFromId from "pages/project/request/RequestRedirectFromId";
import RequestRedirectFromKey from "pages/project/request/RequestRedirectFromKey";
import { REQUEST_LIST_TYPE } from "pages/project/request/request.typing";
import TaskListPage from "pages/project/task/TaskListPage";
import TaskRedirectFromId from "pages/project/task/TaskRedirectFromId";
import CookieConsentProvider from "providers/CookieConsentProvider";
import GlobalLoadingIndicatorProvider from "providers/GlobalLoadingIndicatorProvider";
import HubProvider from "providers/HubProvider";
import ReactQueryProvider from "providers/ReactQueryProvider";
import ThemeProvider from "providers/ThemeProvider";
import { useUser } from "query/user.query";
import { Toaster } from "react-hot-toast";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import RequireAuth from "routes/RequireAuth";
import { ROUTES } from "routes/routes.const";
import { NotFoundPageSource } from "./routes.typing";

const RedirectUser = () => {
  const { data: user } = useUser();

  const initialUrl =
    !user || user.userType === USER_TYPES.supplier
      ? `/project/offer/list/${OFFER_LIST_TYPE.pending}`
      : `/project/request/list/${REQUEST_LIST_TYPE.pending}`;

  return <Navigate to={initialUrl} />;
};

const AuthorizedOnlyForSupplier = () => {
  const { data: user } = useUser();

  if (user?.userType !== USER_TYPES.supplier) return <Navigate to={"/error/401"} replace />;

  return <Outlet />;
};

const AuthorizedOnlyForCustomerContact = () => {
  const { data: user } = useUser();

  if (user?.userType !== USER_TYPES.customer) return <Navigate to={"/error/401"} replace />;

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ReactQueryProvider>
          <Outlet />
          <HubProvider />
          <Toaster
            position="top-right"
            toastOptions={{ duration: 10000, className: "bg-white dark:bg-slate-900 text-color-primary" }}
          />
          <ThemeProvider />
          <CookieConsentProvider />
          <GlobalLoadingIndicatorProvider />
        </ReactQueryProvider>
      }
    >
      {/*App routes, can be only shown when user is authenticated*/}
      <Route
        path={ROUTES.HOME}
        element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }
      >
        <Route index element={<RedirectUser />} />
        {/*todo replace with DashboardPage once ready*/}
        <Route path="/dashboard" element={<RedirectUser />} />
        {/*todo replace with DashboardPage once ready*/}
        <Route path="/project" element={<ProjectLayout />}>
          {/*Task*/}
          <Route path="task/detail/:id" element={<TaskRedirectFromId />} />
          <Route path="task/list/:type" element={<TaskListPage />}>
            <Route path=":id/:action" element={null} />
            <Route path=":id" element={null} />
          </Route>
          {/*offer*/}
          <Route path={"offer"} element={<AuthorizedOnlyForSupplier />}>
            <Route path="newOffer/:key" element={<OfferRedirectFromKey />} />
            <Route path="detail/:id" element={<OfferRedirectFromId />} />
            <Route path="list/:type" element={<OfferListPage />}>
              <Route path=":id/:action" element={null} />
              <Route path=":id" element={null} />
            </Route>
          </Route>
          {/*Request*/}
          <Route path={"request"} element={<AuthorizedOnlyForCustomerContact />}>
            <Route path="newRequest/:key" element={<RequestRedirectFromKey />} />
            <Route path="detail/:id" element={<RequestRedirectFromId />} />
            <Route path="list/:type" element={<RequestListPage />}>
              <Route path=":id/:action" element={null} />
              <Route path=":id" element={null} />
            </Route>
          </Route>
        </Route>
        {/*pre-invoices*/}
        <Route path="pre-invoice" element={<AuthorizedOnlyForSupplier />}>
          <Route index={true} element={<PreInvoiceListPage />} />
          <Route path="detail/:id" element={<PreInvoiceDetailPage />} />
        </Route>
      </Route>
      {/* Footer */}
      <Route path="/legal/info" element={<LegalInfoPage />} />
      <Route path="/legal/privacy" element={<LegalPrivacyPage />} />
      {/*Authentication related routes*/}
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={ROUTES.REGISTER} element={<ForgotPasswordPage isCreateAccount={true} />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path="xtm/:token" element={<AnonymousXtmTask />} />
      <Route path="/task/xtm/job/editor/:token" element={<AnonymousXtmOpenEditor />} />
      {/*Error routes*/}
      <Route path="/error/401" element={<NotAllowedPage />} />
      <Route path="/error/404" element={<NotFoundPage />} />
      <Route path="/error/offer/404" element={<NotFoundPage source={NotFoundPageSource.Offer} />} />
      <Route path="/error/task/404" element={<NotFoundPage source={NotFoundPageSource.Task} />} />
      <Route path="/error/500" element={<ServerErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

function AppRoutes() {
  return <RouterProvider router={router} />;
}

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default AppRoutes;
