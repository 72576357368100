type ReturnType = { score: number; isValidPassword: boolean };

export function getPasswordScore(password: string): ReturnType {
  const length = password?.trim()?.length ?? 0;
  let score = 0;

  if (length === 0) return { score: 0, isValidPassword: false };
  if (length < 4) return { score: 1, isValidPassword: false };

  if (password.match(/(?=^.*$)(?=.*\W+).*$/)) score++; // at least one special chars

  if (password.match(/(?=^.*$)(?=.*[A-Z]).*$/)) score++; // at least one upper case

  if (password.match(/(?=^.*$)(?=.*[a-z]).*$/)) score++; // at least one lower case

  if (password.match(/(?=^.*$)(?=.*\d).*$/)) score++; // at least one digit

  if (length >= 8) score++;

  return { score, isValidPassword: score === 5 };
}
