import Badge from "components/Badge";
import { OfferStatus } from "pages/project/offer/offer.typing";
import { useTranslation } from "react-i18next";

interface Props {
  status: number;
}

const OfferStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return status === OfferStatus.Accepted ? (
    <Badge color="green" data-test="market-email-status-badge-accepted">
      {t("task-base.badge.accepted")}
    </Badge>
  ) : (
    <Badge color="sky" data-test="market-email-status-badge-pending">
      {t("task-base.badge.pending")}
    </Badge>
  );
};

export default OfferStatusBadge;
