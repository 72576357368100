import Loader from "components/Loader";
import { PreInvoiceDetailComponent } from "pages/pre-invoice/common/PreInvoiceDetailComponent";
import { PreInvoice } from "pages/pre-invoice/pre-invoice.typing";
import { usePreInvoice } from "query/pre-invoice.query";
import { useMemo } from "react";

interface Props {
  preInvoice: PreInvoice;
}

export function PreInvoicePreview({ preInvoice }: Props) {
  const { preInvoiceTasks, isLoading } = usePreInvoice(preInvoice.id);

  const preInvoiceWithTasks = useMemo(() => ({ ...preInvoice, tasks: preInvoiceTasks }), [preInvoice, preInvoiceTasks]);

  if (isLoading) {
    return <Loader isShown={isLoading} className="text-left" />;
  }

  return (
    <div className="mt-6">
      <PreInvoiceDetailComponent preInvoice={preInvoiceWithTasks} />
    </div>
  );
}
