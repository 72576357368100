import { Updater, VisibilityState } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

export function useDataTableColumVisibility(dataTableName: string) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  useEffect(() => {
    const savedValue = getLocalStorageValue<VisibilityState>(dataTableName, "save-table-columns");
    setColumnVisibility(savedValue ?? {});
  }, [dataTableName]);

  function onUpdateColumnVisibility(updaterOrValue: Updater<VisibilityState>) {
    let value;
    if (typeof updaterOrValue === "function") {
      value = updaterOrValue(columnVisibility);
    } else {
      value = updaterOrValue;
    }
    setLocalStorageValue(dataTableName, value, "save-table-columns");
    setColumnVisibility(value);
  }

  return { columnVisibility, onUpdateColumnVisibility };
}
