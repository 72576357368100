import { Row } from "@tanstack/react-table";
import { isAfter, isBefore, isEqual, parseISO, startOfDay } from "date-fns";

function isDateRange(obj: unknown): obj is { from: Date; to: Date } {
  return typeof obj === "object" && obj !== null && "from" in obj && "to" in obj;
}

export function filterDateRange<TData>(row: Row<TData>, id: string, value: unknown) {
  if (!isDateRange(value)) {
    return true;
  }
  const beginDate = value?.from;
  const endDate = value?.to;
  const columnValueWithTime = parseISO(row.getValue(id));
  const columnValue = startOfDay(columnValueWithTime);
  const isBeforeOrEqual = isBefore(columnValue, endDate) || isEqual(columnValue, endDate);
  const isAfterOrEqual = isAfter(columnValue, beginDate) || isEqual(columnValue, beginDate);
  const isBeforeIfDefined = endDate ? isBeforeOrEqual : true;
  return isAfterOrEqual && isBeforeIfDefined;
}

export function filterStingInclude<TData>(row: Row<TData>, id: string, value: unknown) {
  if (!Array.isArray(value)) {
    return true;
  }

  return value.includes(row.getValue(id)?.toString());
}
