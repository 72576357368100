import { TaskBase } from "pages/project/common/taskBase.typing";

export const OFFER_LIST_TYPE: Record<OfferListType, OfferListType> = {
  pending: "pending",
  accepted: "accepted",
};

export type OfferListType = "pending" | "accepted";
export type OfferActionType = "decline" | "accept";

export type OfferListRouteParam = { type: OfferListType; id: string; action: OfferActionType };

export interface Offer extends Omit<TaskBase, "id" | "unit" | "secondUnit"> {
  id: string; // convert to string, easier to manage in react-router
  taskId: string;
  status: OfferStatus;
  searchFields: string;
  unit: string;
  secondUnit: string;
}

export interface RejectReason {
  id: string;
  label: string;
}

export enum OfferStatus {
  Sent = 1,
  Accepted = 2,
}

export const mapToListType = (status: OfferStatus): OfferListType | null => {
  if (status === OfferStatus.Sent) {
    return OFFER_LIST_TYPE.pending;
  }
  if (status === OfferStatus.Accepted) {
    return OFFER_LIST_TYPE.accepted;
  }
  return null;
};
