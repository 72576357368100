// noinspection AllyPlainJsInspection
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

export type ThemeType = "dark" | "light" | "system";

export const detectTheme = () => {
  const theme = getTheme();
  updateTheme(theme);
};

export const getTheme = (): ThemeType => {
  const savedTheme = getLocalStorageValue<ThemeType>("pp-theme", "read dark theme status");
  return savedTheme ?? "system";
};

export const updateTheme = (theme: ThemeType) => {
  setLocalStorageValue("pp-theme", theme, "Switch theme");
  let themeToUpdate = theme;
  if (theme === "system") {
    themeToUpdate = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
  if (themeToUpdate === "dark") {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
};
