import { useTranslation } from "react-i18next";
import Button from "components/buttons/Button";
import { ROUTES } from "routes/routes.const";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "components/Success";

export function ResetPasswordSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("translation", { keyPrefix: "set-password" });

  return (
    <div className="flex flex-col space-y-6">
      <Success message={t("update-success")} />

      <Button
        onClick={() => navigate(ROUTES.LOGIN, location.state ? { state: location.state } : undefined)}
        label={t("go-to-login")}
        className="text-sm font-medium"
        dataActionName="go-to-login"
        dataTestId="link-reset-password-to-login"
      />
    </div>
  );
}
