import { ShareLink } from "components/ShareLink";

interface Props {
  xtmProjectName: string;
  anonymousJobsLink: string;
}

const XtmShareJobLink = ({ anonymousJobsLink, xtmProjectName }: Props) => {
  return <ShareLink url={anonymousJobsLink} label={xtmProjectName} isLoading={false} canCopy={true} />;
};

export default XtmShareJobLink;
