import { Menu } from "@headlessui/react";
import { BarsArrowDownIcon, BarsArrowUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { SortDirectionType } from "components/typings";
import { TaskBaseFields } from "pages/project/common/taskBase.typing";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

const FIELDS: Array<{ name: string; value: TaskBaseFields }> = [
  { name: "task-base.sortBy.projectName", value: "projectName" },
  { name: "task-base.sortBy.customer", value: "customerName" },
  { name: "task-base.sortBy.supplierContact", value: "supplierContactName" },
  { name: "task-base.sortBy.sourceLanguage", value: "sourceLanguage" },
  { name: "task-base.sortBy.targetLanguage", value: "targetLanguage" },
  { name: "task-base.sortBy.startDate", value: "startDate" },
  { name: "task-base.sortBy.dueDate", value: "dueDate" },
  { name: "task-base.sortBy.quantity", value: "quantity" },
  { name: "task-base.sortBy.taskType", value: "taskType" },
  { name: "task-base.sortBy.total", value: "price" },
];

const CUSTOMER_EXCLUDED_FILTERS: Array<string> = ["supplierContactName", "price"];

interface Props {
  sortBy: TaskBaseFields | null;
  sortDirection: SortDirectionType;
  onSort: (sortBy: TaskBaseFields | null, sortDir: SortDirectionType) => void;
  isUserSupplier: boolean;
}

const TaskBaseSortByDropdown = ({ sortBy, onSort, sortDirection, isUserSupplier }: Props) => {
  const { t } = useTranslation("translation");

  const selectedSortBy = FIELDS.find(({ value }) => value === sortBy) ?? null;
  const fields = isUserSupplier ? FIELDS : FIELDS.filter((field) => !CUSTOMER_EXCLUDED_FILTERS.includes(field.value));
  const handleOnSort = (fromSortBy: TaskBaseFields | null) => {
    const fromSortDirection = fromSortBy !== sortBy ? "asc" : sortDirection === "asc" ? "desc" : "asc";
    onSort(
      sortBy === fromSortBy && fromSortDirection === "asc" && sortDirection === "desc" ? null : fromSortBy,
      fromSortDirection
    );
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        data-test="btn-toggle-sort-dropdown"
        data-dd-action-name="toggle-sort-dropdown"
        className={cn(
          "inline-flex rounded-md border border-gray-300 px-4 py-2 text-sm font-medium shadow-sm dark:border-slate-600",
          sortBy
            ? "offset-ring-300 bg-ribbon-500 text-slate-100"
            : "bg-color-secondary text-color-primary offset-ring-500 hover:bg-slate-100 dark:text-slate-200 dark:hover:bg-slate-700"
        )}
      >
        {sortDirection === "asc" ? (
          <BarsArrowUpIcon data-test="icon-task-base-sort-asc" className="mr-3 h-5 w-5" aria-hidden="true" />
        ) : (
          <BarsArrowDownIcon data-test="icon-task-base-sort-desc" className="mr-3 h-5 w-5" aria-hidden="true" />
        )}
        <span>{t("task-base.sort")}</span>
        {selectedSortBy && (
          <>
            <span className="mr-1">:</span>
            <span className="text-sm">{t(selectedSortBy.name)}</span>
          </>
        )}
        <ChevronDownIcon className="-mr-1.5 ml-2.5 h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items
        className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-slate-700"
        data-test="sort-dropdown-list"
      >
        {fields.map(({ name, value }) => (
          <div className="py-1" key={value}>
            <Menu.Item>
              {() => (
                <button
                  type="button"
                  tabIndex={0}
                  data-test={`btn-sort-by-${value}`}
                  data-dd-action-name={`btn-sort-by-${value}`}
                  onClick={() => handleOnSort(value)}
                  className={cn(
                    sortBy === value ? "font-medium text-ribbon-500 dark:text-ribbon-400" : "",
                    "w-full px-4 py-0.5 text-left text-sm transition duration-150 ease-in-out hover:bg-slate-100 sm:text-base  dark:hover:bg-slate-600 dark:focus:outline-slate-600 dark:focus:ring-slate-600"
                  )}
                >
                  {t(name)}
                </button>
              )}
            </Menu.Item>
          </div>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default TaskBaseSortByDropdown;
