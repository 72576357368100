import { datadogRum } from "@datadog/browser-rum";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { User } from "api/api.typing";
import { fetchMe, getXSRFToken, requestLogout } from "api/user.api";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes.const";

export const useUser = () => {
  const { data, isLoading, error } = useQuery<User, AxiosError>({ queryKey: ["user"], queryFn: fetchMe, retry: 2 });
  return { data, isLoading, error };
};

export const useLogoutMutation = (redirectToLogin = true) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    datadogRum.addAction("fetch-XSRF");
    getXSRFToken().catch(() => Promise.resolve());
  }, []);

  return useMutation<void, AxiosError>({
    mutationFn: requestLogout,
    onSuccess: async () => {
      datadogRum.addAction("logout-success", {
        email: queryClient.getQueryData<User>(["user"])?.email,
      });
    },
    onError: (error) => {
      datadogRum.addAction("logout-failed", {
        email: queryClient.getQueryData<User>(["user"])?.email,
        error: { status: error.response?.status, message: error.message, response: error.response?.data },
      });
    },
    onSettled: async () => {
      datadogRum.clearUser();

      queryClient.removeQueries(); // remove all queries
      if (redirectToLogin) {
        navigate(ROUTES.LOGIN);
      }
    },
  });
};
