import AnchorLink from "components/AnchorLink";
import CheckboxForm from "components/CheckboxForm";
import Error from "components/Error";
import Button from "components/buttons/Button";
import InputForm from "components/input/InputForm";
import AuthenticationLayout from "layout/AuthenticationLayout";
import { useLogin } from "pages/authentication/login/useLogin";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/routes.const";

function LoginPage() {
  const { t } = useTranslation();
  const { isPending, login, register, errors, serverError } = useLogin();

  return (
    <AuthenticationLayout title={t("login.title")}>
      <form className="space-y-6" onSubmit={login} noValidate={true}>
        <InputForm
          id="email-input"
          type="email"
          label={t("login.email.label")}
          autoComplete="username"
          autoFocus={true}
          {...register("email")}
          error={errors.email?.message}
        />
        <div className="space-y-1">
          <InputForm
            id="password-input"
            type="password"
            label={t("login.password.label")}
            autoComplete="current-password"
            hideOnDataDog={true}
            {...register("password")}
            error={errors.password?.message}
          />
        </div>
        <div className="flex items-center justify-between">
          <CheckboxForm
            id="remember-me"
            label={t("login.remember-me.label")}
            {...register("rememberMe", { required: false })}
          />
          <AnchorLink
            to={ROUTES.FORGOT_PASSWORD}
            label={t("login.forgot-password")}
            className="text-sm font-medium"
            dataActionName="open-forgot-password"
            dataTestId="link-forgot-password"
          />
        </div>
        {!isPending && <Error error={serverError} />}
        <Button
          type="submit"
          id="login-submit-button"
          className="w-full"
          label={t("login.sign-in")}
          disabled={isPending}
        />
        <div>
          <div className="flex items-baseline space-x-2 text-sm">
            <span className="text-color-secondary font-medium">{t("login.not-registered")}</span>
            <AnchorLink
              to={ROUTES.REGISTER}
              label={t("login.register")}
              className="font-medium"
              dataActionName="open-register"
              dataTestId="link-register"
            />
          </div>
        </div>
      </form>
    </AuthenticationLayout>
  );
}

export default LoginPage;
