import { useAnonymousDelegatedXtmJobLink } from "../query/xtm.task.query";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useEffect } from "react";

export const AnonymousXtmOpenEditor = () => {
  const { token = "" } = useParams<{
    token: string;
  }>();
  const { isLoading, url, isSuccess } = useAnonymousDelegatedXtmJobLink(token);

  useEffect(() => {
    if (isSuccess && url) {
      location.href = url;
    }
  }, [isSuccess, url]);

  if (isLoading) {
    return <Loader isShown={true} />;
  }
  return <></>;
};
