import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Error from "components/Error";
import Loader from "components/Loader";
import Button from "components/buttons/Button";
import TextAreaFormField from "components/form/TextAreaFormField";
import Modal from "components/modal/Modal";
import { MEMSOURCE_CAT_TOOL_ID } from "pages/project/common/taskBase.typing";
import { COMMENT_MAX_CHAR_LIMIT, useDeliverTaskForm } from "pages/project/task/components/modals/useDeliverTaskForm";
import QAForm from "pages/project/task/components/qaForm/qaForm";
import { useQAForm, useTask } from "query/task.query";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  taskId: string;
  isUserSupplier: boolean;
}

const DeliverTaskModal = ({ show, taskId, isUserSupplier }: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: isUserSupplier ? "task.modal.deliver" : "task.modal.validate",
  });
  const { isLoading: isTaskLoading, selectedTask } = useTask(taskId);
  const { isLoading: isQAFormLoading, qaForm } = useQAForm(selectedTask);
  const { deliver, reset, register, formState, isPending, qaFormRatings, setValue, validationError } =
    useDeliverTaskForm(
      taskId,
      qaForm,
      selectedTask?.shouldDisplayQAForm ?? false,
      selectedTask?.catToolId,
      isUserSupplier
    );

  if (isTaskLoading || !selectedTask) {
    return null;
  }

  return (
    <>
      <Modal
        isShown={show}
        onClose={reset}
        maxWidthClassName={selectedTask.shouldDisplayQAForm ? "sm:max-w-7xl" : undefined}
      >
        <div>
          {/*Icon*/}
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          {/*Header*/}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
              <div>{t("title")}</div>
              <div>{selectedTask.taskCode}</div>
            </Dialog.Title>
            <div className="mt-6 flex flex-col text-center">
              <div className="text-base font-medium">
                {t(
                  selectedTask.shouldDisplayQAForm
                    ? selectedTask.catToolId === MEMSOURCE_CAT_TOOL_ID
                      ? "subTitle-cat-tool"
                      : "subTitle-with-qaform"
                    : "subTitle"
                )}
              </div>
            </div>
          </div>

          {/*body*/}
          <div>
            <form className="mx-4 mt-6 flex flex-col space-y-5" onSubmit={deliver}>
              {selectedTask.shouldDisplayQAForm ? (
                <>
                  {isQAFormLoading ? (
                    <Loader isShown={true} />
                  ) : qaForm ? (
                    <QAForm
                      data={qaForm}
                      qaFormRatings={qaFormRatings}
                      onqaFormRatingChange={(updatedRatings) => setValue("qaFormRatings", updatedRatings)}
                    />
                  ) : null}

                  <TextAreaFormField
                    id="comment"
                    label={t("form.comment")}
                    className="max-h-52"
                    {...register("comment", {
                      maxLength: {
                        value: COMMENT_MAX_CHAR_LIMIT,
                        message: t(`form.commentInvalid`, { maxCharLimit: COMMENT_MAX_CHAR_LIMIT }),
                      },
                    })}
                    error={formState.errors.comment?.message}
                    maxCharLimit={COMMENT_MAX_CHAR_LIMIT}
                  />
                  {validationError ? <Error error={validationError} /> : null}
                </>
              ) : null}
              <div className="ml-auto w-full max-w-3xl">
                <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
                  <Button type="reset" disabled={isPending} color="ternary" label={t("cancel")} onClick={reset} />
                  <Button type="submit" disabled={isPending} color="primary" label={t("confirm")} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DeliverTaskModal;
