import { yupResolver } from "@hookform/resolvers/yup";
import { RequestListType, ValidationRequestRejectReason } from "pages/project/request/request.typing";
import { useRejectValidationRequestMutation } from "query/request.query";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ObjectSchema } from "yup";

export interface RejectionFormModel {
  reason: ValidationRequestRejectReason;
  comment?: string;
  newDeadline?: Date | undefined;
}

export const useRejectValidationRequestForm = (requestId: string, filterType: RequestListType) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema: ObjectSchema<RejectionFormModel> = yup.object().shape({
    reason: yup.object().shape({
      id: yup.string().required(t("request.modal.decline.reasonRequired")),
      label: yup.string().default("").optional(),
    }),
    comment: yup.string().optional(),
    newDeadline: yup.date().optional(),
  });

  const {
    watch,
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RejectionFormModel>({
    resolver: yupResolver(schema),
  });

  const onReset = () => {
    reset();
    navigate(`/project/request/list/${filterType}/${requestId}?${params.toString()}`);
  };

  const onSuccess = () => {
    reset();
    navigate(`/project/request/list/${filterType}?${params.toString()}`);
  };

  const { mutate, ...others } = useRejectValidationRequestMutation(requestId, onSuccess);

  const isRejectReasonShortDeadline = watch("reason")?.id === "1"; // If the selected reason is "Short deadline"

  return {
    ...others,
    isRejectReasonShortDeadline,
    register,
    control,
    reset: onReset,
    formErrors: errors,
    reject: handleSubmit((modelParams) => mutate(modelParams)),
  };
};
