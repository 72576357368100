import LegalLayoutPage from "layout/LegalLayoutPage";
import { useTranslation } from "react-i18next";

function LegalInfoPage() {
  const { t } = useTranslation();

  return (
    <LegalLayoutPage title={t("footer.legal")}>
      <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 sm:py-24 lg:px-8">
        <h2 className="text-color-primary text-3xl font-extrabold">Company name and form:</h2>
        <p className="text-color-secondary mt-3 text-lg">
          NIKITA (ACOLAD GROUP), French simplified joint-stock company
        </p>
        <p className="text-color-secondary mt-3 text-lg">
          Registered office: 11 rue Lazare Hoche, 92 100 Boulogne-Billancourt, France
        </p>
        <p className="text-color-secondary mt-3 text-lg">RCS: Nanterre 814 416 822</p>
        <p className="text-color-secondary mt-3 text-lg">Share capital: 42 438 736,00 €</p>
        <p className="text-color-secondary mt-3 text-lg">Email: contact@acolad.com</p>
        <p className="text-color-secondary mt-3 text-lg">Telephone: +33 (0)1 46 04 66 00</p>
        <p className="text-color-secondary mt-3 text-lg">Publishing director: Olivier MARCHETEAU</p>

        <h2 className="text-color-primary mt-8 text-3xl font-extrabold">Hosting provider:</h2>
        <p className="text-color-secondary mt-3 text-lg">Name and form: Microsoft NV (MICROSOFT AZURE)</p>
        <p className="text-color-secondary mt-3 text-lg">
          Address: Brussels National Airport - Passport Building 1K Luchthavenlaan,1930 Zaventem, Belgium
        </p>
        <p className="text-color-secondary mt-3 text-lg">Comp.register number: 0437.910.359</p>
        <p className="text-color-secondary mt-3 text-lg">Email: BNLCS@microsoft.com</p>

        <h2 className="text-color-primary mt-8 text-3xl font-extrabold">DPO:</h2>
        <p className="text-color-secondary mt-3 text-lg">Carlos Grau Palou</p>
        <p className="text-color-secondary mt-3 text-lg">Telephone: +39 (0) 515 88 17 73</p>
        <p className="text-color-secondary mt-3 text-lg">Email: dpo@acolad.com</p>
      </div>
    </LegalLayoutPage>
  );
}

export default LegalInfoPage;
