import Loader from "components/Loader";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { ArrowLeftIcon, DownloadIcon, LinkIcon, Loader2Icon } from "lucide-react";
import { PreInvoiceTasksTable } from "pages/pre-invoice/PreInvoiceTasksTable";
import { PreInvoiceDetail } from "pages/pre-invoice/pre-invoice.typing";
import { useEntityInvoiceInformation } from "query/entity.query";
import { useDownloadSupplierPreInvoice } from "query/task.query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  preInvoice: PreInvoiceDetail;
  shownInDetailPage?: boolean;
}

export function PreInvoiceDetailComponent({ preInvoice, shownInDetailPage = false }: Props) {
  const { t } = useTranslation();
  const { invoiceInformation, isLoading: invoiceInformationIsLoading } = useEntityInvoiceInformation(
    preInvoice.entityId
  );
  const { downloadDownloadSupplierPreInvoice, isLoading: downloadIsLoading } = useDownloadSupplierPreInvoice();

  const title = (
    <div className="text-color-primary flex max-w-fit items-center gap-2 text-lg font-semibold sm:text-3xl">
      <span>{t("pre-invoice-detail.title")}</span> <span>#{preInvoice.documentNumber}</span>
    </div>
  );

  return (
    <div className="mx-auto max-w-screen-2xl">
      {shownInDetailPage ? (
        <Button variant="ghost" className="-ml-3 mb-4">
          <Link to="/pre-invoice" className="flex flex-nowrap items-center gap-2">
            <ArrowLeftIcon className="h-4 w-4" />
            <span>{t("pre-invoice-detail.backToPreinvoices")}</span>
          </Link>
        </Button>
      ) : null}
      <div className="flex flex-nowrap items-center justify-between gap-2">
        <div>
          {shownInDetailPage ? (
            title
          ) : (
            <Link to={`./detail/${preInvoice.id}`} className="hover:cursor-pointer hover:underline">
              <span className="flex flex-nowrap items-center gap-2">
                <LinkIcon className="h-6 w-6" /> {title}
              </span>
            </Link>
          )}
        </div>
        <Button
          title={t("fileDownload.pre-invoice")}
          disabled={downloadIsLoading}
          onClick={() => downloadDownloadSupplierPreInvoice(preInvoice.id)}
        >
          <div className="flex flex-nowrap items-center gap-1">
            <span className="h-6 w-6">
              {downloadIsLoading ? <Loader2Icon className="animate-spin" /> : <DownloadIcon />}
            </span>
            <span>{t("pre-invoice-detail.download")}</span>
          </div>
        </Button>
      </div>

      <div className="mt-6 flex flex-col gap-4 sm:flex-row sm:justify-between">
        <div>
          <Loader isShown={invoiceInformationIsLoading} />
          {invoiceInformation ? <div dangerouslySetInnerHTML={{ __html: invoiceInformation }}></div> : null}
        </div>
        <div className="flex min-w-[350px] grow-0 flex-col gap-2 rounded-lg border border-slate-100 bg-slate-50 p-4 dark:border-slate-700 dark:bg-slate-800">
          <div className="text-color-secondary text-sm font-medium uppercase tracking-wide">
            {t("pre-invoice-detail.amount")}
          </div>
          <div className="flex flex-nowrap gap-1 text-2xl">
            <span className="text-color-primary font-semibold">{t("enum:intlNumber", { val: preInvoice.price })}</span>
            <span className="text-color-secondary font-light">{preInvoice.currencyCode}</span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-nowrap gap-2">
        <div className="text-color-secondary"> {t("pre-invoice-detail.issuedOn")}</div>
        <div className="text-color-primary font-medium">
          {t("enum:dates.mediumDate", { date: preInvoice.reIssuedDate ?? preInvoice.issuedDate })}
        </div>
      </div>

      <Separator className="mb-4 mt-2" />

      <PreInvoiceTasksTable preInvoiceId={preInvoice.id} tasks={preInvoice.tasks} />
    </div>
  );
}
