import { datadogRum } from "@datadog/browser-rum";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { requestResetPasswordLink } from "api/user.api";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatServerError } from "utils/format";
import * as yup from "yup";

type ForgotPasswordModel = {
  email: string;
};

export const useForgotPassword = () => {
  const { t } = useTranslation(["error", "translation"]);

  const schema = yup.object().shape({
    email: yup.string().email(t("yup.email.badFormat")).required(t("yup.email.required")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordModel>({
    resolver: yupResolver(schema),
  });

  const {
    isPending,
    error: axiosError,
    mutate,
    isSuccess,
    isError,
    data,
    reset,
  } = useMutation<string, AxiosError, ForgotPasswordModel>({
    retry: false,
    mutationKey: ["forgot-password"],
    mutationFn: ({ email }) => requestResetPasswordLink(email),
    onSuccess: async (response: string, { email }: ForgotPasswordModel) => {
      datadogRum.addAction("forgot-password-success", { email });
    },
    onError: (error: AxiosError, { email }: ForgotPasswordModel) => {
      datadogRum.addAction("forgot-password-failed", {
        email,
        error: { status: error.response?.status, message: error.message, response: error.response?.data },
      });
    },
  });

  const serverError = () => {
    return formatServerError(axiosError, (axiosResponse) => {
      if (axiosResponse.status !== 400 || !Array.isArray(axiosResponse.data) || axiosResponse.data.length === 0) {
        return null;
      }
      if (axiosResponse.data[0] === "CONTACT_NOT_FOUND") {
        return t("forgot-password.email-not-found");
      }
    });
  };

  const onSubmit: SubmitHandler<ForgotPasswordModel> = ({ email }) => {
    mutate({ email });
  };

  return {
    isPending,
    isSuccess,
    isError,
    serverError: serverError(),
    errors,
    register,
    emailSentTo: data,
    reset,
    forgotPassword: handleSubmit(onSubmit),
  };
};
