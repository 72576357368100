import { usePreInvoiceDetail } from "query/pre-invoice.query";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { PreInvoiceDetailComponent } from "pages/pre-invoice/common/PreInvoiceDetailComponent";

export function PreInvoiceDetailPage() {
  const { id = "" } = useParams<{ id: string }>();
  const preInvoiceId = Number.parseInt(id);
  const { preInvoice = null, isLoading } = usePreInvoiceDetail(preInvoiceId);

  if (isLoading) {
    return <Loader isShown={true} />;
  }

  if (!preInvoice) {
    return null;
  }

  return (
    <div className="h-full min-h-screen flex-1 bg-white px-4 py-6 dark:bg-slate-950">
      <PreInvoiceDetailComponent preInvoice={preInvoice} shownInDetailPage={true} />
    </div>
  );
}
