import { create } from "zustand";
import { uploadSingleTaskFile } from "api/task.api";
import some from "lodash/some";
import { chunk } from "lodash";
import { TaskFileUploadType } from "pages/project/common/taskBase.typing";

export interface FileUploadStatus {
  file: File;
  progression: number;
  abortController?: AbortController;
  remainingTime?: string;
  status: "waiting" | "uploading" | "uploaded" | "failed" | "canceled";
}

interface FileUploadState {
  taskId: string | null;
  taskCode: string | null;
  fileStatuses: Map<string, FileUploadStatus>;
  fileUploaded: FileUploadStatus[];
  fileFailedOrCanceled: FileUploadStatus[];
  upload: (
    taskId: string,
    taskCode: string,
    isTaskDelivered: boolean,
    files: File[],
    fileType: TaskFileUploadType,
    onDeliverTask: (markAsDelivered: boolean, allFilesHaveBeenUploaded: boolean) => void
  ) => void;
  cancel: (file: File) => void;
  cancelAll: () => void;
  reset: () => void;
}

export const MAX_PROGRESS_LIMIT_BEFORE_CANCEL = 90;
export const NBR_PARALLEL_UPLOAD = 3;

const getFilesByStatus = (filestatuses: Map<string, FileUploadStatus>) => {
  const statuses = [...filestatuses.values()];
  const fileUploaded = statuses.filter((x) => x.status === "uploaded");
  const fileFailedOrCanceled = statuses.filter((x) => x.status !== "uploaded");
  return [fileUploaded, fileFailedOrCanceled];
};

export const canCancelUpload = (fileUploadStatus: FileUploadStatus | undefined): boolean => {
  if (!fileUploadStatus) {
    return false;
  }
  const isWaiting = fileUploadStatus.status === "waiting";
  const isUploadingAndUnderTheLimit =
    fileUploadStatus.status === "uploading" && fileUploadStatus.progression < MAX_PROGRESS_LIMIT_BEFORE_CANCEL;
  return isWaiting || isUploadingAndUnderTheLimit;
};

export const canCancelAllUploads = (fileUploadStatus: Map<string, FileUploadStatus>): boolean => {
  if (!fileUploadStatus || fileUploadStatus.size === 0) {
    return false;
  }
  return some([...fileUploadStatus.values()], canCancelUpload);
};

export const isFilesUploading = (fileUploadStatus: Map<string, FileUploadStatus>): boolean => {
  if (!fileUploadStatus || fileUploadStatus.size === 0) {
    return false;
  }
  return some([...fileUploadStatus.values()], ({ status }) => ["waiting", "uploading"].includes(status));
};

export const useFileUploadStore = create<FileUploadState>((setState, getState) => {
  function cancel(file: File) {
    const fileUploadStatus = getState().fileStatuses.get(file.name);
    if (!canCancelUpload(fileUploadStatus)) {
      return; // api limitation if it's almost uploaded we cannot cancel the request.
    }
    fileUploadStatus?.abortController?.abort(); // abort the request
    // mark the file as canceled
    setState((state) => {
      const { fileStatuses } = state;
      fileStatuses.set(file.name, { file: file, progression: 0, status: "canceled" });
      return { fileStatuses };
    });
  }

  function cancelAll() {
    const filesToCancel = [...getState().fileStatuses.values()]
      .filter((fs) => ["waiting", "uploading"].includes(fs.status))
      .map((fs) => fs.file);
    for (const file of filesToCancel) {
      getState().cancel(file);
    }
  }

  function reset() {
    setState({ taskId: null, taskCode: null, fileStatuses: new Map(), fileUploaded: [], fileFailedOrCanceled: [] });
  }

  function uploadOneFile(taskId: string, fileType: TaskFileUploadType, file: File) {
    const onUploading = (abort: AbortController) =>
      setState((state) => {
        const { fileStatuses } = state;
        fileStatuses.set(file.name, { file, progression: 0, status: "uploading", abortController: abort });
        return { fileStatuses };
      });

    const onProgress = (progress: number, remainingTime?: string) =>
      setState((state) => {
        const { fileStatuses } = state;
        const value = fileStatuses.get(file.name) as FileUploadStatus;
        fileStatuses.set(file.name, { ...value, status: "uploading", progression: progress, remainingTime });
        return { fileStatuses };
      });

    const onUploaded = () =>
      setState((state) => {
        const { fileStatuses } = state;
        fileStatuses.set(file.name, { file, progression: 100, status: "uploaded" });
        return { fileStatuses };
      });

    const onFailed = (isCancellation: boolean) =>
      setState((state) => {
        const { fileStatuses } = state;
        fileStatuses.set(file.name, { file, progression: 0, status: isCancellation ? "canceled" : "failed" });
        return { fileStatuses };
      });

    const status = getState().fileStatuses.get(file.name)?.status;
    if (status === "canceled") {
      return Promise.resolve();
    }

    return uploadSingleTaskFile(taskId, file, fileType, onUploading, onProgress, onUploaded, onFailed);
  }

  async function upload(
    taskId: string,
    taskCode: string,
    isTaskDelivered: boolean,
    files: File[],
    fileType: TaskFileUploadType,
    onDeliverTask: (markAsDelivered: boolean, allFilesHaveBeenUploaded: boolean, comment?: string) => void
  ) {
    // starting
    setState(() => {
      const fileStatuses = new Map();
      for (const file of files) {
        fileStatuses.set(file.name, { file, progression: 0, status: "waiting" });
      }
      return { fileStatuses, taskId, taskCode, fileUploaded: [], fileFailedOrCanceled: [] };
    });

    const chunkedFiles = chunk(files, NBR_PARALLEL_UPLOAD);
    for (const someFiles of chunkedFiles) {
      // uploading file by file
      await Promise.all(someFiles.map((f) => uploadOneFile(taskId, fileType, f)));
    }

    const fileStatuses = getState().fileStatuses;
    const [fileUploaded, fileFailedOrCanceled] = getFilesByStatus(fileStatuses);

    // upload is done
    setState({ fileUploaded, fileFailedOrCanceled });

    // only if any file isUploaded
    // we can call api to notify the PM about new delivery
    if (fileUploaded.length > 0) {
      const allFilesHaveBeenUploaded = fileFailedOrCanceled.length === 0;
      await onDeliverTask(isTaskDelivered, allFilesHaveBeenUploaded);
    }
  }

  return {
    taskId: null,
    taskCode: null,
    fileStatuses: new Map(),
    fileUploaded: [],
    fileFailedOrCanceled: [],
    upload,
    cancel,
    cancelAll,
    reset,
  };
});
