// noinspection AllyPlainJsInspection

import { Duration } from "luxon";
import { DurationUnit } from "luxon/src/duration";
import { DEFAULT_LANGUAGE, LanguageType } from "utils/language";
import { t } from "i18next";
import capitalize from "lodash/capitalize";
import { AxiosError, AxiosResponse } from "axios";

const FILE_SIZES = ["Bytes", "KB", "MB", "GB", "TB"];

export const bytesToSize = (bytes: number): string => {
  if (bytes === undefined || bytes === null || isNaN(bytes) || bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const unit = FILE_SIZES[i];
  const value = Math.round(bytes / Math.pow(1024, i));
  return `${value} ${unit}`;
};
export const formatUserName = (firstName: string, lastname: string) => {
  return `${capitalize(firstName)} ${lastname?.toUpperCase() ?? ""}`.trim();
};

export function toHuman(
  dur: Duration,
  smallestUnit: DurationUnit = "seconds",
  locale: LanguageType = DEFAULT_LANGUAGE
): string {
  const units: DurationUnit[] = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds"];
  const smallestIdx = units.indexOf(smallestUnit);
  const entries = Object.entries(
    dur
      .shiftTo(...units)
      .normalize()
      .toObject()
  ).filter(([, amount], idx) => amount > 0 && idx <= smallestIdx);

  return Duration.fromObject(entries.length === 0 ? { [smallestUnit]: 0 } : fromEntries(entries), {
    locale,
  }).toHuman();
}

function fromEntries(iterable: [string, unknown][]) {
  return [...iterable].reduce(
    (obj, [key, val]) => {
      obj[key] = val;
      return obj;
    },
    {} as Record<string, unknown>
  );
}

export const formatServerError = (
  axiosError: AxiosError | null,
  getCustomMessage?: (axiosResponse: AxiosResponse) => string | null | undefined
): string | null => {
  if (!axiosError) {
    return null;
  }
  if (!axiosError.response) {
    if (axiosError.message.includes("Network")) {
      return t("error:common.network");
    } else {
      return t("error:common.server");
    }
  }
  return getCustomMessage?.(axiosError.response) ?? t("error:common.server");
};

export const toBoolean = (value: unknown): boolean | undefined => {
  if (typeof value === "boolean") {
    return value;
  }
  if (typeof value === "number") {
    return value === 1 ? true : value === 0 ? false : undefined;
  }
  if (typeof value === "string") {
    const stringValue = value.trim().toLowerCase();
    return stringValue === "true" ? true : stringValue === "false" ? false : undefined;
  }
  return undefined;
};
