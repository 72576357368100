import { WorkflowExecutionType } from "api/api.typing";
import Badge from "components/Badge";
import EmptyState from "components/EmptyState";
import Button from "components/buttons/Button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { FileBoxIcon, InfoIcon, MessageSquareMoreIcon } from "lucide-react";
import { SideBar } from "pages/project/common/SideBar";
import { XTM_CAT_TOOL_ID, getCatToolName } from "pages/project/common/taskBase.typing";
import { XtmJobLinksTable } from "pages/project/common/taskBase/list/XtmJobLinksTable";
import { TaskBaseDetail } from "pages/project/common/taskBase/sidebar/TaskBaseDetail";
import TaskBaseFilesDownload from "pages/project/common/taskBase/sidebar/TaskBaseFilesDownload";
import TaskInstructions from "pages/project/common/taskBase/sidebar/TaskInstructions";
import OfferStatusBadge from "pages/project/offer/components/sidebar/OfferStatusBadge";
import { OfferListType, OfferStatus } from "pages/project/offer/offer.typing";
import { UserHint } from "pages/project/task/components/sidebar/UserHint";
import { useOffer } from "query/offer.query";
import { ReactElement } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOfferFileStore } from "store/useOfferFileStore";

interface Props {
  selectedOfferId?: string;
  onClose: () => void;
  onErrorNode: () => ReactElement;
  onDecline: () => void;
  onAccept: () => void;
  filterType: OfferListType;
}

function OfferSideBar({ selectedOfferId, ...props }: Props) {
  const { t } = useTranslation();
  const { isLoading, selectedOffer } = useOffer(selectedOfferId);

  const state = useOfferFileStore((state) => ({
    shouldDisableAction: state.shouldDisableAction,
    requestZipFullDownload: state.requestZipFullDownload,
  }));

  if (isLoading) {
    return null;
  }

  if (!selectedOffer) {
    if (selectedOfferId) {
      // todo remove this when get offer by id is ready
      toast.error(
        <div className="ml-2 flex flex-col space-y-1">
          <span className="text-color-primary">{t("offer.detail.error.title")}</span>
          <span className="text-color-secondary text-sm">{t("offer.detail.error.description")}</span>
        </div>,
        {
          id: "no-offer-id",
          className: "bg-color-primary",
        }
      );
      return props.onErrorNode();
    }
    return null;
  }

  const shouldDisableDownload = state.shouldDisableAction(selectedOffer.id);
  const isOfferAccepted = selectedOffer.status === OfferStatus.Accepted;
  const isXtm =
    selectedOffer.catToolId === XTM_CAT_TOOL_ID && selectedOffer.workflowExecutionType == WorkflowExecutionType.CAT;

  return (
    <SideBar
      onClose={props.onClose}
      title={
        <>
          <h2 className="text-color-primary h-full whitespace-nowrap text-3xl font-medium">
            <button type="button" className="cursor-pointer outline-0">
              {selectedOffer.taskCode}
            </button>
          </h2>
          <Badge color="goldfish">{t("offer.fields.offer")}</Badge>
          <Badge>{selectedOffer.taskType}</Badge>
          {getCatToolName(selectedOffer.catToolId) && (
            <Badge dataTestId="task-type-value">{getCatToolName(selectedOffer.catToolId)}</Badge>
          )}
          <OfferStatusBadge status={selectedOffer.status} />
        </>
      }
    >
      {!isOfferAccepted && (
        <div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <Button color="ternary" label={t("offer.detail.decline")} onClick={props.onDecline} />
          <Button color="primary" label={t("offer.detail.accept")} onClick={props.onAccept} />
        </div>
      )}

      <UserHint fileTabName={isXtm ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")} />

      <Tabs defaultValue="overview" className="-mx-6 mt-4 border-t border-gray-300 dark:border-t-gray-600">
        <TabsList className="mx-2 flex flex-col items-start gap-2 border-y border-gray-300 py-4 sm:mx-0 sm:flex-row sm:justify-stretch sm:border-y-0 sm:pb-0 dark:border-gray-600">
          <TabsTrigger value="overview" data-test="overview-tab">
            <InfoIcon className="mr-2 size-4" />
            {t("translation:task.tabs.general")}
          </TabsTrigger>
          <TabsTrigger value="files" data-test="files-tab">
            <FileBoxIcon className="mr-2 size-4" />
            {isXtm ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")}
          </TabsTrigger>
          <TabsTrigger value="instructions" data-test="instructions-tab">
            <MessageSquareMoreIcon className="mr-2 size-4" /> {t("translation:task.tabs.instructions")}
          </TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="px-6">
          <TaskBaseDetail
            selectedTaskBase={selectedOffer}
            taskBaseStartDateLabel={t("task-base.detail.estimatedStartDate")}
            isUserSupplier={true}
            isCanEditSupplierContact={false}
          />
        </TabsContent>

        <TabsContent value="files" className="px-6">
          <div className="my-6">
            <TaskBaseFilesDownload
              selectedTaskBase={selectedOffer}
              disableDownload={shouldDisableDownload}
              onRequestZipFullDownload={(offer, currentLanguage, hubConnectionId) =>
                state.requestZipFullDownload(offer.id, offer, currentLanguage, hubConnectionId)
              }
            />
          </div>
          {isXtm ? (
            <div className="my-4 border-t border-gray-200 px-2 pt-4 dark:border-slate-600">
              <XtmJobLinksTable isTaskOngoing={false} taskId={selectedOffer.taskId} />
            </div>
          ) : (
            <EmptyState
              title={t("task.detail.files.title-no-delivered-files")}
              subtitle={t("task.detail.files.title-no-delivered-files-detail")}
            />
          )}
        </TabsContent>

        <TabsContent value="instructions" className="px-6">
          <TaskInstructions taskId={selectedOffer.taskId} />
        </TabsContent>
      </Tabs>
    </SideBar>
  );
}

export default OfferSideBar;
