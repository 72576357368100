import ErrorLayoutPage from "layout/ErrorLayoutPage";
import { useTranslation } from "react-i18next";

function ServerErrorPage() {
  const { t } = useTranslation("error");

  return <ErrorLayoutPage errorCode={500} title={t("page.server.title")} description={t("page.server.description")} />;
}

export default ServerErrorPage;
