import { ShareLink } from "components/ShareLink";

interface Props {
  anonymousJobsLink: string;
  xtmProjectName: string;
}

function AnonymousXtmShareJobLink({ anonymousJobsLink, xtmProjectName }: Props) {
  return <ShareLink url={anonymousJobsLink} label={xtmProjectName} isLoading={false} canCopy={false} />;
}

export default AnonymousXtmShareJobLink;
