import LegalLayoutPage from "layout/LegalLayoutPage";
import { useTranslation } from "react-i18next";

function LegalPrivacyPage() {
  const { t } = useTranslation();

  return (
    <LegalLayoutPage title={t("footer.privacy")}>
      <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 sm:py-24 lg:px-8">
        <p className="mt-2">
          The Acolad Group and its affiliate companies (hereinafter “Acolad”) may collect and process information on
          persons visiting their website https://acolad.com), through applications, software, online services or when
          providing services such as support, handling enquiries about its products and services and through any other
          means.
        </p>
        <p className="mt-2">
          Acolad in its capacity of “data controller”, guarantees that it processes Personal data in compliance with all
          legal provisions related to the protection of Personal data and, in particular, with the General Data
          Protection Regulation (UE) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the
          “GDPR”).
        </p>
        <p className="mt-2">
          In order to inform you of the purposes of the processing of Personal data and the means implemented by Acolad,
          in compliance with the applicable laws and regulation, Acolad has established this Privacy Policy Website
          &amp; Cookies (hereinafter the “Privacy Policy”). This Privacy Policy supplements any information you have
          already been given on this subject. Furthermore, Acolad may provide you with additional information to address
          specific circumstances (not covered by this Privacy Policy) in which your Personal data may be collected and
          processed.
        </p>
        <p className="mt-2">
          The word “you” shall mean anyone, wherever located, who falls within the scope of this Privacy Policy.
          “Personal data” shall mean any information relating to an identified or identifiable natural person. “Us/we”
          shall mean Acolad.
        </p>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">1. How do we collect your Personal data ?</h2>
        <p className="mt-2">
          Your Personal data can be collected during the navigation on our website through cookies (see section bellow
          “Use of cookies”).
        </p>
        <p className="mt-2">Acolad may also collect Personal data that you provide us with through our website when:</p>
        <ul className="list-inside list-disc">
          <li>You submit a request for quotation</li>
          <li>You register to our newsletter</li>
          <li>You contact us through our contact form to request information (such as on our products and services)</li>
          <li>You register to our online seminars through the learning store</li>
          <li>
            You apply to a become part of our international network of expert vendors (including external translators
            and interpreters) (hereinafter “Vendors Network”)
          </li>
          <li>You manage and access your client portal.</li>
        </ul>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold"> 2. Processing of your Personal data</h2>
        <p className="mt-2">
          Acolad processes only necessary data relating to the processing purposes and with respect to the data
          minimization principle.
        </p>
        <p className="mt-2">
          We have established the following table to provide you with an overview on which categories of data we
          process, for which purpose(s) and according to which legal basis.
        </p>
        <table className="mt-2 w-full border-collapse border border-slate-400 bg-white text-sm shadow-sm dark:border-slate-500 dark:bg-slate-800">
          <tbody>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Purpose </strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Category of data </strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Legal basis </strong>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Manage and answer requests submitted through the “contact form”.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Data on your identity (name, surname), contact data (email address, phone number), information on your
                company (name of your company) and any other data you may provide.
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Legitimate interest of Acolad to provide information on its business, products and services.
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Manage requests for quotations.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Data on your identity (name, surname), contact data (email address, phone number) , information on your
                company and your request (name of your company, main sector of activities, country) and any other data
                you may provide.
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Legitimate interest of Acolad to develop its business relationships.
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Manage the subscription to our newsletter.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Email address.
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Your consent.
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Manage the purchase and registration to online seminars through the learning store.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Data on your identity (name, surname), contact data (email address, postal address, phone number…) and
                data related to your company (name of the company, tax identification number, payment information…).
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Performance of a contract to which you are a party.
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Analyse and manage the application you submit to join our Vendors Network.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Data on your identity (title, name, surname, age, nationality…), contact data (email address, telephone
                number, postal address, link to your professional network profil....) and data related to your
                professional skills and status (diplomas, information contained in your resume, language competences,
                professional status/name of your employer...).
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Legitimate interest of Acolad to analyse application and develop its Vendors Network.
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Manage your client portal and offer you services through the portal.{" "}
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Data on your identity (name, surname), contact data (IP address, email address, postal address, phone
                number…), data related to your company (name of the company, payment information…) any data provided
                that may be necessary to the performance of services.
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                Legitimate interest of Acolad to provide its services to its clients.
              </td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">3. Automated decision making</h2>
        <p className="mt-2">
          The Personal data we collect does not lead to an automated decision-making within the meaning of Article 13.2
          (f) of the GDPR.
        </p>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">4. Personal data recipients </h2>
        <p className="mt-2">
          The Personal data collected are for the exclusive use of Acolad and its affiliate companies which may be
          involved in the provision of the services, may perform data processing on Acolad’s behalf or for data
          centralisation purposes.
        </p>
        <p className="mt-2">
          For technical reasons, Acolad may outsource some services to providers (such as hosting providers, IT solution
          providers…). Acolad is informing you that the information it collects on its website is only processed by
          providers authorized by Acolad and that such providers cannot sell your information to third parties.
        </p>
        <p className="mt-2">
          Acolad may also be requested to communicate your information to administrative and judicial authorities to
          comply with its statutory obligations.
        </p>
        <p className="mt-2">
          Third parties to whom we transfer your Personal data are responsible for their compliance with the applicable
          data protection legislation.
        </p>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">
          5. Transfer of Personal data outside the EEA{" "}
        </h2>
        <p className="mt-2">
          Wherever possible, your Personal data is processed with the European Union (EU)/European Economic Area
          (EEA)/United Kingdom. Acolad may, if necessary, transfer your Personal data to a third country or to an
          international organization located outside these territories.
        </p>
        <p className="mt-2">
          In such case, Acolad ensures that it takes all necessary and appropriate measures to comply with the
          applicable law, by:
        </p>
        <ul className="list-inside list-disc">
          <li>
            Transferring data to countries and international organizations which are subject to an adequacy decision
            issued by the European Commission{" "}
          </li>
          <li>
            Transferring data to recipients who have signed the Standard Contractual Clauses established by the European
            Commission.
          </li>
        </ul>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">6. Retention period</h2>
        <p className="mt-2">
          Acolad does not retain your Personal data any longer than necessary for the purpose for which they were
          collected, unless Acolad is legally obliged to retain your Personal data for a longer period.
        </p>
        <table className="mt-2 w-full border-collapse border border-slate-400 bg-white text-sm shadow-sm dark:border-slate-500 dark:bg-slate-800">
          <tbody>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Data processed </strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Retention period </strong>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Data collected as part of the subscription to our newsletter.</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  3 years from the time of the last contact we had with you, unless you revoke your consent.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Data collected via the contact form.</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Duration of the processing of the request for information or the time of the exchange.{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Data collected for a request for quotation.</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Duration of the processing of the request for quotation or the time of the exchange.
                </p>
                <p className="mt-2">
                  If such quotation is accepted, the retention period will be the time necessary for the purpose of
                  providing the related services.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Data collected to manage the purchase and registration to online seminars through the learning store.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">5 years from the end of the provision of the services.</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Data collected to manage your application to our network of translators. </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  2 years for the last contact we had with you, unless you exercise your right to object to the
                  processing of your Personal data.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Data collected to manage your client portal and offer you services through the portal.{" "}
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  3 years following the end of the commercial relationship, except in the event of a legal archiving
                  obligation or for evidential purposes or accounting obligations.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">7. Your rights</h2>
        <p className="mt-2">
          Acolad will comply with the rights of the data subjects ensuing from the legislation. Under the GDPR, you have
          the following rights with regard to your Personal data:
        </p>
        <ul className="list-inside list-disc">
          <li>right of access to your Personal data</li>
          <li>right to rectification or improvement of your Personal data</li>
          <li>right to be forgotten</li>
          <li>right to restriction of the processing of your Personal data</li>
          <li>right to data portability</li>
        </ul>
        <p className="mt-2">
          When Personal data are processed for communication and direct marketing purposes taking place because of
          Acolad’s legitimate interest, you have the right to object to the processing without having to justify a
          legitimate interest.
        </p>
        <p className="mt-2">
          In order to exercise the above-mentioned rights, you can submit a request to Acolad’s Data Protection Officer
          via email at the following address: dpo@acolad.com.
        </p>
        <p className="mt-2">
          If your Personal data have been processed on the basis of consent, you have the right to revoke this consent
          at any time.
        </p>
        <p className="mt-2">
          If you see reason to do so, you have the right, at any moment, to submit a complaint to the French data
          protection authority (CNIL) located at 3, Place de Fontenoy, 75007 Paris.
        </p>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">
          8. Appropriate technical and organisational measures{" "}
        </h2>
        <p className="mt-2">
          Acolad implements appropriate technical and organisational measures to ensure the integrity, availability, and
          security of your Personal data against any loss, destruction, damage, modification, disclosure and against any
          form of unlawful processing.
        </p>
        <p className="mt-2">
          Acolad has established technical measures (such as anti-virus and firewalls, etc.) and organisational measures
          (such as a login/password system, physical protection means, etc.) to ensure the protection of Personal data.
          Computer workstations are systematically locked and only accessible via a login and password system.
        </p>
        <p className="mt-2">
          We require our staff and all third parties working for Acolad to adhere to strict security and information
          protection standards, including contractual obligations to protect all data and to apply strict data transfer
          measures.
        </p>
        <h2 className="text-color-primary mt-4 text-xl font-extrabold">9. Use of Cookies</h2>
        <p className="mt-2"> Acolad collects Personal data through its website which uses cookies.</p>
        <p className="mt-2">
          A cookie is a small text file that websites place on your device as you are browsing. Cookies are processed
          and stored by your web browser. Every time you visit our website using your device, your device sends back
          data from the cookie file, which enables our website to recognize the device which is connecting to it. They
          are different types of cookies and they may for instance be used to memorize customer ID with a merchant site,
          the content of your shopping cart, to trace your navigation for statistical or advertising purposes.
        </p>
        <h3 className="text-color-primary mt-4 p-10 font-extrabold"> 1. Information we collect about you</h3>
        <p className="mt-2">
          On each visit to our website or platforms we may automatically collect the following information:{" "}
        </p>
        <ul className="list-inside list-disc">
          <li>
            technical information, including the Internet protocol (IP) address used to connect your device to the
            Internet, your login information, browser type and version, time zone setting, browser plug-in types and
            version, operating system and platform;
          </li>
          <li>
            information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and
            from our site (including date and time), products you viewed or searched for, page response times, download
            errors, length of visits to certain pages, interaction information (such as scrolling, click and
            mouse-overs), and methods used to browse away from the page.
          </li>
        </ul>
        <p className="mt-2">
          We do not collect your name, address, telephone number, or email address. The information we collect is used
          to build a profile of the user of the device which connects to our website and is used in connection with the
          use of the website only.
        </p>
        <h3 className="text-color-primary mt-4 p-10 font-extrabold"> 2. Types of cookies we use </h3>
        <p className="mt-2">Strictly necessary cookies</p>
        <p className="mt-2">
          These cookies are necessary for the website to function and cannot be switched off in our systems. They are
          usually only set in response to actions taken by you which amount to a request for services, such as setting
          your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about
          these cookies, but some parts of the website may not work then.
        </p>
        <table className="mt-2 w-full border-collapse border border-slate-400 bg-white text-sm shadow-sm dark:border-slate-500 dark:bg-slate-800">
          <tbody>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Cookie</strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Description</strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  <strong>Duration</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">.AspNetCore.AcoladPartnerPortal</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">This technical cookie is used for user authentication</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Session</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">.AspNetCore.Antiforgery</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Helps prevent Cross-Site Request Forgery (CSRF) attacks</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Session</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">XSRF-TOKEN</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Helps prevent Cross-Site Request Forgery (CSRF) attacks</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Session</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="mt-2">Analytic cookies</p>
        <p className="mt-2">
          Acolad uses analytics tools and other third party technologies, such as Google Analytics, to collect
          non-personal information in the form of various usage and user metrics when you use our online website and
          services. These tools and technologies collect and analyze certain types of information, including cookies, IP
          addresses, device and software identifiers, referring and exit URLs, onsite behavior and usage information,
          feature use metrics and statistics, usage and purchase history, MAC Address, mobile unique device ID, and
          other similar information.
        </p>
        <table className="mt-2 w-full border-collapse border border-slate-400 bg-white text-sm shadow-sm dark:border-slate-500 dark:bg-slate-800">
          <tbody>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Cookie</strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <strong>Description</strong>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  <strong>Duration</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_ga</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  This cookie name is associated with Google Universal Analytics - which is a significant update to
                  Google's more commonly used analytics service. This cookie is used to distinguish unique users by
                  assigning a randomly generated number as a client identifier. It is included in each page request in a
                  site and used to calculate visitor, session and campaign data for the sites analytics reports. By
                  default it is set to expire after 2 years, although this is customizable by website owners.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">2 years</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_gid</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  This cookie name is associated with Google Universal Analytics. It appears to store and update a
                  unique value for each page visited.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Session</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_hjSessionUser</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Hotjar cookie that is set when a user first lands on a page with the Hotjar script. It is used to
                  persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in
                  subsequent visits to the same site will be attributed to the same user ID.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">365 days</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_hjSession</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  A cookie that holds the current session data. This ensues that subsequent requests within the session
                  window will be attributed to the same Hotjar session
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">30 minutes</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_hjAbsoluteSessionInProgress</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  This cookie is used to detect the first pageview session of a user. This is a True/False flag set by
                  the cookie.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">30 minutes</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_hjFirstSeen</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  This is set to identify a new user’s first session. It stores a true/false value, indicating whether
                  this was the first time Hotjar saw this user. It is used by Recording filters to identify new user
                  sessions.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Session</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_gcl_au</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  It's the first party cookie for "Conversion Linker" functionality - it takes information in ad clicks
                  and stores it in a first-party cookie so that conversions can be attributed outside the landing page.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">3 months</p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_dd_s</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  DataDog RUM Browser SDK Cookie used to group all events generated from a unique user session across
                  multiple pages. It contains the current session ID, whether the session is excluded due to sampling,
                  and the expiration date of the session.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  The cookie is extended for an extra 15 minutes every time the user interacts with the website, up to
                  the maximum user session duration (4 hours).
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">_lfa</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  This cookie is set by the provider Leadfeeder. This cookie is used for identifying the IP address of
                  devices visiting the website. The cookie collects information such as IP addresses, time spent on
                  website and page requests for the visits.This collected information is used for retargeting of
                  multiple users routing from the same IP address.
                </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">2 years</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="text-color-primary mt-4 p-10 font-extrabold">
          {" "}
          3. Legal basis for the processing of your information
        </h3>
        <p className="mt-2">Acolad processes your information on the following legal basis:</p>
        <table className="mt-2 w-full border-collapse border border-slate-400 bg-white text-sm shadow-sm dark:border-slate-500 dark:bg-slate-800">
          <tbody>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Type of Cookies </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Legal basis </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Strictly necessary cookies </p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">
                  Acolad’s legitimate interest to administer and improve the functioning of its website.{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Functional cookies</p>
              </td>
              <td className="border border-slate-300 p-4 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                <p className="mt-2">Your consent. </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-color-primary mt-4 p-10 font-extrabold">
          {" "}
          4.How to manage your cookies setting on your browser(s){" "}
        </h3>
        <p className="mt-2">
          You are not obliged to accept cookies and can manage your settings on the browser you use to visit our
          website. The browser you use not only allows you to see the cookies that you already have on your device but
          also allows you to control their use and block installation of cookies or receive a warning before a cookie is
          stored on your device.
        </p>

        <p className="mt-2">
          We recommend you use the “Help” function in the browser on your device to manage your browser acceptance and
          use of cookies.
        </p>
        <p className="mt-2">You can also follow our recommendations below :</p>

        <p className="mt-2">Chrome </p>
        <p className="mt-2">
          <a
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fr)"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=fr)
          </a>{" "}
        </p>
        <ul className="list-inside list-disc">
          <li>At the top right of the Chrome Window, click on « More » then « Settings ».</li>
          <li>As the bottom of the page, click on « Advanced Settings ».</li>
          <li>In the section « Privacy and Security », click on « Content Settings » then « Cookies ».</li>
          <li>Uncheck « Allow sites to save / read cookies data ».</li>
        </ul>

        <p className="mt-2">Firefox </p>
        <p className="mt-2">
          <a
            href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites
          </a>
        </p>
        <ul className="list-inside list-disc">
          <li>
            At the top of the Firefox window, click on the Firefox button (Tools menu under Windows XP), then select
            "Options".
          </li>
          <li>Select the "Privacy and Security" panel. In "Content Lock", select the "Custom" option.</li>
          <li>Check "Cookies" to block them.</li>
        </ul>

        <p className="mt-2">Safari</p>
        <p className="mt-2">
          <a
            href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
          </a>
        </p>
        <ul className="list-inside list-disc">
          <li>Go to "Settings" and then to "Preferences".</li>
          <li>On the "Privacy" tab.</li>
          <li>Select "Block all cookies".</li>
        </ul>
        <p className="mt-2">Internet Explorer</p>
        <p className="mt-2">
          <a
            href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </p>
        <ul className="list-inside list-disc">
          <li>
            Click on the gear icon at the top right of the Internet Explorer window and select "Internet Options".
          </li>
          <li>Click on the "Privacy" tab.</li>
          <li>Click on the button "Advanced", check the box "Ignore the automatic management of cookies".</li>
        </ul>

        <p className="mt-2">Opera </p>
        <p className="mt-2">
          <a
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://help.opera.com/en/latest/web-preferences/#cookies
          </a>
        </p>
        <ul className="list-inside list-disc">
          <li>Go to "Settings" and then "Preferences".</li>
          <li>Click on the "Advanced" tab, then "Privacy and security".</li>
          <li>In the "Cookies" box, check the "Never accept cookies" box.</li>
        </ul>

        <p className="mt-2">
          If you choose to disable cookies, it is very likely our website will not function fully on your devices and
          you will not be able to use the full functionality we offer (for instance disabling some functional cookies
          may disable functionalities linked to on-line shopping).
        </p>
        <p className="mt-2">
          You may also deactivate cookies from social networks such as Twitter (
          <a
            href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
            className="text-color-secondary outline-ribbon-500 hover:underline"
          >
            https://help.twitter.com/en/rules-and-policies/twitter-cookies
          </a>
          ).
        </p>

        <h3 className="text-color-primary mt-4 p-10 font-extrabold"> 10. Changes to this Privacy Policy</h3>
        <p className="mt-2">
          We may change this Privacy Policy from time to time. We encourage you to review this Privacy Policy regularly
          for the latest information on our privacy practices.
        </p>

        <p className="mt-2">This Privacy Policy was last amended on January 19th, 2022.</p>
        <h3 className="text-color-primary mt-4 p-10 font-extrabold"> 11. Applicable law </h3>
        <p className="mt-2">
          This Privacy Policy is governed by French law. Any dispute will be submitted to the competent courts of Paris.
        </p>

        <h2 className="text-color-primary mt-4 text-xl font-extrabold">How to contact us</h2>
        <p className="mt-2">
          If you have any questions about this Privacy Policy, please contact us at
          <a href="mailto:cgrau@acolad.com">cgrau@acolad.com</a> .
        </p>

        <h2 className="text-color-primary mt-4 text-xl font-extrabold">DPO contact details </h2>
        <p className="mt-2">Carlos GRAU I PALOU</p>
        <p className="mt-2">Via G. Rivani 83, 40 138 Bologna, Italia </p>
        <p className="mt-2">
          <a href="mailto:DPO@acolad.com">DPO@acolad.com</a> or <a href="mailto:cgrau@acolad.com">cgrau@acolad.com</a>
        </p>
        <p className="mt-2">Data Controller identity</p>
        <p className="mt-2">
          Acolad’s mother entity is NIKITA, a company incorporated under the laws of France whose head office is at 11
          RUE LAZARE HOCHE, 92100 BOULOGNE-BILLANCOURT, registered at the Trade and Companies register of Nanterre under
          n° 814 416 822.
        </p>
      </div>
    </LegalLayoutPage>
  );
}

export default LegalPrivacyPage;
