import { t } from "i18next";
import { ReactElement } from "react";

export const toastContent = (title: string, message: string, onCancel?: () => void): ReactElement => {
  return (
    <div className="flex flex-col justify-center space-y-2">
      <h3 className="text-color-primary font-medium">{title}</h3>
      <div>{message}</div>
      {onCancel && (
        <div className="mt-3">
          <button
            type="button"
            onClick={onCancel}
            className="text-color-primary hover:text-color-secondary rounded border border-gray-300 px-2.5 py-1.5 text-xs font-medium dark:border-slate-700"
          >
            {t("translation:common.cancel")}
          </button>
        </div>
      )}
    </div>
  );
};
