import { WorkflowExecutionType } from "api/api.typing";
import Badge from "components/Badge";
import EmptyState from "components/EmptyState";
import Button from "components/buttons/Button";
import { Alert, AlertDescription } from "components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { AlertTriangleIcon, FileBoxIcon, InfoIcon } from "lucide-react";
import { SideBar } from "pages/project/common/SideBar";
import { XTM_CAT_TOOL_ID, getCatToolName } from "pages/project/common/taskBase.typing";
import { XtmJobLinksTable } from "pages/project/common/taskBase/list/XtmJobLinksTable";
import { TaskBaseDetail } from "pages/project/common/taskBase/sidebar/TaskBaseDetail";
import TaskBaseFilesDownload from "pages/project/common/taskBase/sidebar/TaskBaseFilesDownload";
import RequestStatusBadge from "pages/project/request/components/sidebar/RequestStatusBadge";
import { RequestListType, RequestStatus } from "pages/project/request/request.typing";
import { UserHint } from "pages/project/task/components/sidebar/UserHint";
import { useValidationRequest } from "query/request.query";
import { ReactElement } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useValidationRequestFileStore } from "store/useRequestFileStore";

interface Props {
  selectedRequestId: string;
  onClose: () => void;
  onErrorNode: () => ReactElement;
  onDecline: () => void;
  onAccept: () => void;
  filterType: RequestListType;
}

function RequestSideBar({ selectedRequestId, ...props }: Props) {
  const { t } = useTranslation();
  const { isLoading, validationRequest } = useValidationRequest(selectedRequestId);

  const state = useValidationRequestFileStore((fileState) => ({
    shouldDisableAction: fileState.shouldDisableAction,
    requestZipFullDownload: fileState.requestZipFullDownload,
  }));

  if (isLoading) {
    return null;
  }

  if (!validationRequest) {
    if (selectedRequestId) {
      toast.error(
        <div className="ml-2 flex flex-col space-y-1">
          <span className="text-color-primary">{t("request.detail.error.title")}</span>
          <span className="text-color-secondary text-sm">{t("request.detail.error.description")}</span>
        </div>,
        {
          id: "no-request-id",
          className: "bg-color-primary",
        }
      );
      return props.onErrorNode();
    }
    return null;
  }

  const shouldDisableDownload = state.shouldDisableAction(validationRequest.id);
  const isRequestAccepted = validationRequest.status === RequestStatus.Accepted;
  const isXtm =
    validationRequest.catToolId === XTM_CAT_TOOL_ID &&
    validationRequest.workflowExecutionType === WorkflowExecutionType.CAT;

  return (
    <SideBar
      onClose={props.onClose}
      title={
        <>
          <h2 className="text-color-primary h-full whitespace-nowrap text-3xl font-medium">
            <button type="button" className="cursor-pointer outline-0">
              {validationRequest.taskCode}
            </button>
          </h2>
          <Badge color="goldfish">{t("request.fields.request")}</Badge>
          <Badge>{validationRequest.taskType}</Badge>
          {getCatToolName(validationRequest.catToolId) && (
            <Badge dataTestId="task-type-value">{getCatToolName(validationRequest.catToolId)}</Badge>
          )}
          <RequestStatusBadge status={validationRequest.status} />
        </>
      }
    >
      {!isRequestAccepted && (
        <div className="mb-2">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Button color="ternary" label={t("request.detail.decline")} onClick={props.onDecline} />
            <Button color="primary" label={t("request.detail.accept")} onClick={props.onAccept} />
          </div>
          {validationRequest.acceptedByAnother && (
            <Alert variant="warning" className="mt-4">
              <AlertTriangleIcon className="size-5" />
              <AlertDescription>{t("request.detail.acceptedByAnother")}</AlertDescription>
            </Alert>
          )}
        </div>
      )}
      <UserHint fileTabName={isXtm ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")} />

      <Tabs defaultValue="overview" className="-mx-6 mt-4 border-t border-gray-300 dark:border-t-gray-600">
        <TabsList className="mx-2 flex flex-col items-start gap-2 border-y border-gray-300 py-4 sm:mx-0 sm:flex-row sm:justify-stretch sm:border-y-0 sm:pb-0 dark:border-gray-600">
          <TabsTrigger value="overview" data-test="overview-tab">
            <InfoIcon className="mr-2 size-4" />
            {t("translation:task.tabs.general")}
          </TabsTrigger>
          <TabsTrigger value="files" data-test="files-tab">
            <FileBoxIcon className="mr-2 size-4" />
            {isXtm ? t("translation:task.tabs.files-links") : t("translation:task.tabs.files")}
          </TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="px-6">
          <TaskBaseDetail
            selectedTaskBase={validationRequest}
            taskBaseStartDateLabel={t("task-base.detail.estimatedStartDate")}
            isUserSupplier={false}
            isCanEditSupplierContact={false}
          />
        </TabsContent>

        <TabsContent value="files" className="px-6">
          <div className="my-6">
            <TaskBaseFilesDownload
              selectedTaskBase={validationRequest}
              disableDownload={shouldDisableDownload}
              onRequestZipFullDownload={(request, currentLanguage, hubConnectionId) =>
                state.requestZipFullDownload(request.id, request, currentLanguage, hubConnectionId)
              }
            />
          </div>

          {isXtm ? (
            <div className="my-4 border-t border-gray-200 px-2 pt-4 dark:border-slate-600">
              <XtmJobLinksTable isTaskOngoing={false} taskId={validationRequest.taskId} />
            </div>
          ) : (
            <EmptyState
              title={t("task.detail.files.title-no-delivered-files")}
              subtitle={t("task.detail.files.title-no-delivered-files-detail")}
            />
          )}
        </TabsContent>
      </Tabs>
    </SideBar>
  );
}

export default RequestSideBar;
