import { useSearchParams } from "react-router-dom";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";
import { TaskBase, TaskBaseFields, TaskBaseTypes } from "pages/project/common/taskBase.typing";
import { LIST_URL_PARAM_NAMES, ListFilterParams, ListViewType } from "components/typings";

type UseTaskBaseFilterType = [ListFilterParams<TaskBase>, (params: ListFilterParams<TaskBase>) => void];

const DEFAULT_VIEW_VALUE: ListViewType = "card";

export const useTaskBaseFilter = (objectType: TaskBaseTypes): UseTaskBaseFilterType => {
  const [searchParams, setSearchParameter] = useSearchParams();

  const sortBy = searchParams.get(LIST_URL_PARAM_NAMES.SORT_BY) as TaskBaseFields;
  const sortDirection = (searchParams.get(LIST_URL_PARAM_NAMES.SORT_DIR) as "asc" | "desc") ?? "asc";
  const term = searchParams.get(LIST_URL_PARAM_NAMES.TERM);
  const view = getFromUrlOrLocalStorage(objectType, searchParams.get(LIST_URL_PARAM_NAMES.VIEW));

  const updateSearchParams = (fromParams: ListFilterParams<TaskBase>) => {
    const params = new URLSearchParams();
    if (fromParams.sortBy) {
      params.set(LIST_URL_PARAM_NAMES.SORT_BY, fromParams.sortBy);
      params.set(LIST_URL_PARAM_NAMES.SORT_DIR, fromParams.sortDirection);
    }
    if (fromParams.term) {
      params.set(LIST_URL_PARAM_NAMES.TERM, fromParams.term);
    }
    if (fromParams.view) {
      saveToLocalStorage(objectType, fromParams.view);
      params.set(LIST_URL_PARAM_NAMES.VIEW, fromParams.view);
    } else {
      const view = readFromLocalStorage(objectType) ?? DEFAULT_VIEW_VALUE;
      params.set(LIST_URL_PARAM_NAMES.VIEW, view);
    }
    setSearchParameter(params);
  };

  return [{ sortBy, term, view, sortDirection }, updateSearchParams];
};

const getFromUrlOrLocalStorage = (objectType: TaskBaseTypes, fromSearchParam: string | null): ListViewType => {
  if (fromSearchParam) {
    return fromSearchParam as ListViewType;
  }

  const fromLocalStorage = readFromLocalStorage(objectType);
  if (fromLocalStorage) {
    return fromLocalStorage;
  }

  return DEFAULT_VIEW_VALUE;
};

const readFromLocalStorage = (objectType: TaskBaseTypes) => {
  return getLocalStorageValue<ListViewType>(`pp-view-${objectType}`, `read view param for ${objectType}`);
};

const saveToLocalStorage = (objectType: TaskBaseTypes, view: ListViewType) => {
  setLocalStorageValue(`pp-view-${objectType}`, view, `save view param for ${objectType}`);
};
