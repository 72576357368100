import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import ENV_PARAMS from "utils/environment";
import { v4 as uuidv4 } from "uuid";
import { datadogLogs } from "@datadog/browser-logs";

type RequestResponseData = {
  correlationId: string;
  requestTime: number;
};

const CustomHeadersName = {
  CORRELATION_ID: "X-Correlation-Id",
  REQUEST_TIME: "X-Request-Time",
};

const requestHandler = (
  requestConfig: InternalAxiosRequestConfig<RequestResponseData>
): InternalAxiosRequestConfig<RequestResponseData> => {
  const correlationId = uuidv4();

  datadogLogs.logger.info(`Sending HTTP request to ${requestConfig.url}`, {
    correlationId,
    request: {
      url: requestConfig.url,
      method: requestConfig.method,
      headers: requestConfig.headers,
    },
  });

  requestConfig.headers.set(CustomHeadersName.CORRELATION_ID, correlationId);
  requestConfig.headers.set(CustomHeadersName.REQUEST_TIME, new Date().getTime());

  return requestConfig;
};

const responseHandler = (
  response: AxiosResponse<unknown, RequestResponseData>
): AxiosResponse<unknown, RequestResponseData> => {
  const elapsedMilliseconds = calculateElapsedTime(response);
  datadogLogs.logger.info(`Received HTTP response to ${response.config.url}`, {
    elapsedMilliseconds,
    correlationId: response.config.headers[CustomHeadersName.CORRELATION_ID],
    request: {
      url: response.config.url,
      method: response.config.method,
    },
    response: {
      status: response.status,
      headers: response.headers,
    },
  });
  return response;
};

function calculateElapsedTime(response: AxiosResponse<unknown, RequestResponseData>): number {
  let requestTime: number | null = null;
  try {
    requestTime = response.config.headers[CustomHeadersName.REQUEST_TIME];
  } catch (ignore) {
    console.error("Failed to get request time");
  }
  if (requestTime !== null && requestTime !== undefined && requestTime > 0) {
    return new Date().getTime() - requestTime;
  }
  return 0;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const api = axios.create({
  baseURL: ENV_PARAMS.PARTNER_PORTAL_API_SERVER_URL,
  timeout: 30000,
  withCredentials: true,
});
api.interceptors.request.use(requestHandler, onRequestError);
api.interceptors.response.use(responseHandler, onResponseError);

export default api;
