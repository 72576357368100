import FullPageLoader from "pages/FullPageLoader";
import { useParseToken } from "pages/authentication/setPassword/useGetSetPasswordTokenValidity";
import { useUser } from "query/user.query";
import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "routes/routes.const";

interface Props {
  children?: ReactNode;
}

const RequireAuth = ({ children }: Props) => {
  const { isLoading: isUserLoading, data: user, error: userError } = useUser();

  const hasServerError = userError?.response?.status && userError?.response?.status !== 401;

  if (hasServerError) {
    return <Navigate to="/error/500" replace={true} state={userError} />;
  }

  if (isUserLoading) {
    return <FullPageLoader key={"page-loader"} />;
  }

  if (user?.email && user.email.length > 0) {
    return <>{children}</>;
  }

  return <RedirectNonAuthenticatedUser />;
};

function RedirectNonAuthenticatedUser() {
  const location = useLocation();
  const resetPasswordToken = useParseToken();
  const hasToken = resetPasswordToken && resetPasswordToken.length > 0;
  const url = hasToken
    ? `${ROUTES.RESET_PASSWORD}?token=${resetPasswordToken}` // redirect to reset password page if user was not found but reset password token is present
    : ROUTES.LOGIN; // redirect to login page if user was not found

  return <Navigate to={url} state={{ from: location }} replace={true} />;
}

export default RequireAuth;
