import { BaseComponentProps } from "components/typings";
import { ButtonHTMLAttributes, ReactNode } from "react";
import { cn } from "utils/ui";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, BaseComponentProps {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
}

const DropdownItem = ({ isActive, onClick, dataTestId, dataActionName, children, ...props }: Props) => {
  return (
    <button
      type="button"
      data-test={dataTestId}
      data-dd-action-name={dataActionName}
      {...(isActive ? { tabIndex: 0 } : {})}
      onClick={() => onClick()}
      className={cn(
        "w-full rounded-lg text-left transition duration-150 ease-in-out hover:bg-slate-100 dark:hover:bg-slate-600",
        "focus:outline-none focus:ring-2 focus:ring-slate-200 dark:focus:outline-slate-600 dark:focus:ring-slate-600"
      )}
      {...props}
    >
      <div
        className={cn("flex items-center space-x-2 p-2 text-base font-medium", {
          "font-semibold text-ribbon-500 dark:text-ribbon-400": isActive,
        })}
      >
        {children}
      </div>
    </button>
  );
};

export default DropdownItem;
