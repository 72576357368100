import { t } from "i18next";
import { ErrorCode, FileError } from "react-dropzone";

const excludedExtensions = ["exe", "dll", "sql", "bat", "xlsm", "docm", "ps1", "sh"];
const excludedFileTypes = ["application/octet-stream", "text/x-sh"];
const excludedFileChars = ["*", '"', "/", "\\", ":", "|", "<", ">", "?"];

export const validateFile = (file: File): FileError | null => {
  const noExtResponse = { code: ErrorCode.FileInvalidType, message: t(`task.upload.errors.file-no-ext`) };

  const invalidExtResponse = (ext: string) => {
    return { code: ErrorCode.FileInvalidType, message: t(`task.upload.errors.file-invalid-ext`, { ext }) };
  };

  const invalidNameResponse = (char: string) => {
    return { code: ErrorCode.FileInvalidType, message: t(`task.upload.errors.file-invalid-chars`, { char }) };
  };

  if (excludedFileTypes.includes(file.type)) {
    return invalidExtResponse(file.type);
  }

  if (file.name) {
    if (!file.name.includes(".")) return noExtResponse;

    const extension = file.name.split(".").pop();

    if (!extension) return noExtResponse;

    if (excludedExtensions.includes(extension)) return invalidExtResponse(extension);

    const invalidChars = excludedFileChars.filter((c) => file.name.includes(c));

    if (invalidChars.length > 0) return invalidNameResponse(invalidChars.join(","));
  }
  return null;
};
