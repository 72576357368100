import AnonymousXtmShareJobLink from "anonymous/pages/AnonymousXtmShareJobLink";
import { useAnonymousXtmData } from "anonymous/query/xtm.task.query";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";

interface Props {
  isTaskOngoing: boolean;
  token: string;
}

export const AnonymousXtmJobLinksTable = ({ token, isTaskOngoing }: Props) => {
  const { t } = useTranslation();
  const { projects, isFetching, error } = useAnonymousXtmData(token, isTaskOngoing);

  if (isFetching) {
    return <Loader isShown={true} />;
  }

  if (projects.length === 0) {
    return (
      <EmptyState
        title={t("task.detail.jobLinks.title-no-job-links")}
        subtitle={error ? t("error:common.server") : t("task.detail.jobLinks.title-no-job-links-detail")}
      />
    );
  }

  return (
    <div className="px-4 pb-4">
      <h5 className="text-color-secondary mb-2 space-x-1">
        <span>{t("task.detail.jobLinks.xtm.title")}</span>
        <span>({projects.length})</span>
      </h5>
      <ul className="-my-4 ml-2 mt-2 list-none">
        {projects.map((project, index) => (
          <li key={`xtm-link ${index}`} className="my-2">
            <AnonymousXtmShareJobLink
              key={`xtm-link-anonymous-${index}`}
              anonymousJobsLink={project.anonymousJobsLink}
              xtmProjectName={project.xtmProjectName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
