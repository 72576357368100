import { TaskBaseDto } from "api/api.typing";
import { ListFilterParams } from "components/typings";
import { Task } from "pages/project/task/task.typing";
import { Offer } from "pages/project/offer/offer.typing";
import orderBy from "lodash/orderBy";
import { TaskBase } from "pages/project/common/taskBase.typing";
import { ValidationRequest } from "pages/project/request/request.typing";

export function mapToTaskBase(taskBaseDto: TaskBaseDto): TaskBase {
  const quantity = formatQuantity(taskBaseDto.quantity, taskBaseDto.unit?.decimals);
  const unit = taskBaseDto.unit?.description ?? "";
  const secondUnit = taskBaseDto.secondUnit?.description ?? "";
  const secondQuantity = formatQuantity(taskBaseDto.secondQuantity, taskBaseDto.secondUnit?.decimals);
  const currency = taskBaseDto.currency ?? "";
  const supplierContactName = taskBaseDto?.supplier?.supplierContact?.name ?? "";
  return {
    ...taskBaseDto,
    quantity,
    secondQuantity,
    unit,
    secondUnit,
    currency,
    supplierContactName,
    id: taskBaseDto.id.toString(),
    searchFields: [
      taskBaseDto.taskCode,
      taskBaseDto.taskType,
      quantity,
      unit,
      currency,
      taskBaseDto.projectName,
      taskBaseDto.customerName,
      taskBaseDto.sourceLanguage,
      taskBaseDto.sourceLanguageCode,
      taskBaseDto.targetLanguage,
      taskBaseDto.targetLanguageCode,
      supplierContactName,
    ]
      .join(" ")
      .toLowerCase(),
  };
}

function formatQuantity(quantity: number | null, decimals: number | null | undefined): number {
  const formattedQuantity = (quantity ?? 0).toFixed(decimals ?? 0);
  return parseFloat(formattedQuantity);
}

export function filterAndSortList<T extends Offer | Task>(data: T[], params?: ListFilterParams<T>): T[] {
  let result = data;
  if (!params) {
    return result;
  }
  const { term, sortBy, sortDirection } = params;
  if (term) {
    result = result.filter((t) => t.searchFields.includes(term.toLowerCase()));
  }
  if (sortBy) {
    result = orderBy(result, sortBy, sortDirection);
  }
  return result;
}

export function filterAndSortValidationRequestList<T extends ValidationRequest | Task>(
  data: T[],
  params?: ListFilterParams<T>
): T[] {
  let result = data;
  if (!params) {
    return result;
  }
  const { term, sortBy, sortDirection } = params;
  if (term) {
    result = result.filter((t) => t.searchFields.includes(term.toLowerCase()));
  }
  if (sortBy) {
    result = orderBy(result, sortBy, sortDirection);
  }
  return result;
}
