import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { OfferDto } from "api/api.typing";
import { fetchOffers, fetchRejectReasons, requestAcceptOffer, requestRejectOffer } from "api/offer.api";
import { AxiosError } from "axios";
import { toastContent } from "components/toastContent";
import { ListFilterParams } from "components/typings";
import { t } from "i18next";
import { filterAndSortList, mapToTaskBase } from "pages/project/common/taskBase/taskBaseMapper";
import { OFFER_LIST_TYPE, Offer, OfferListType, OfferStatus, RejectReason } from "pages/project/offer/offer.typing";
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { formatServerError } from "utils/format";
import { LanguageType } from "utils/language";

const mapper = (offer: OfferDto): Offer => {
  const taskBase = mapToTaskBase(offer);
  return {
    ...taskBase,
    taskId: offer.taskId.toString(),
    status: offer.status,
  };
};

export const useOfferList = (params?: ListFilterParams<Offer>) => {
  const { i18n } = useTranslation();

  const {
    data = [],
    isLoading,
    error,
  } = useQuery<Offer[], AxiosError>({
    queryKey: ["offers", i18n.language],
    queryFn: async () => {
      const response = await fetchOffers(i18n.language as LanguageType);
      return response.offers.map(mapper);
    },
    select: useCallback((offers: Offer[]) => filterAndSortList(offers, params), [params]),
  });
  const hasOffers = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch offer list");
      if (message) {
        toast.error(toastContent(t("translation:offer.error-title.offer-list"), message), {
          id: "offer-list-fetch-error",
        });
      }
    }
  }, [error]);

  return { offers: data, hasOffers, isLoading, error };
};

export const useOfferListByType = (type: OfferListType, params?: ListFilterParams<Offer>) => {
  const { offers, isLoading, ...others } = useOfferList(params);

  const offersByType = useMemo(() => {
    const tabOfferStatus = OFFER_LIST_TYPE.pending === type ? OfferStatus.Sent : OfferStatus.Accepted;
    return offers.filter((o) => o.status === tabOfferStatus);
  }, [offers, type]);

  return { ...others, isLoading, hasOffers: !isLoading && offersByType.length > 0, offers: offersByType };
};

export const useOfferCounts = () => {
  const { offers } = useOfferList();
  const pendingCount = offers.filter((t) => t.status === OfferStatus.Sent).length;
  const offerCount = offers.filter((t) => t.status === OfferStatus.Accepted).length;
  return { pending: pendingCount, accepted: offerCount };
};

export const useOffer = (selectedId?: string) => {
  // todo better is to call the api to get an offer/task by id
  const { offers, isLoading } = useOfferList();
  const selectedOffer = offers.find((offer) => offer.id === selectedId);
  return { isLoading, selectedOffer };
};

export const useOfferRejectionReason = () => {
  const { i18n } = useTranslation();

  const { error, ...others } = useQuery<RejectReason[], AxiosError>({
    queryKey: ["rejectionReason", i18n.language],
    queryFn: async () => {
      const data = await fetchRejectReasons(i18n.language as LanguageType);
      return data.map<RejectReason>(({ id, description }) => ({ id: id.toString(), label: description }));
    },
    retry: false,
  });

  useEffect(() => {
    if (error) {
      datadogLogs.logger.error("fetch-rejectReasons-failed");
      toast.error(
        toastContent(t("translation:offer.error-title.reject-reasons"), t("offer.modal.decline.failFetchRejectReason")),
        {
          id: "reject-reasons-fetch-error",
        }
      );
    }
  }, [error]);

  return { error, ...others };
};

export const useAcceptOfferMutation = (offerId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<unknown, AxiosError, { comment?: string }>({
    mutationFn: (form) => {
      return requestAcceptOffer(offerId, form.comment);
    },

    retry: false,
    onError: (error, form) => {
      toast.error(t("offer.modal.accept.error"));
      datadogRum.addError("accept-offer-failed", { offerId, comment: form.comment, error });
    },
    onSuccess: async () => {
      toast.success(t("offer.modal.accept.success"));
      await queryClient.cancelQueries({ queryKey: ["offers"] });
      const allCachedOffers = queryClient.getQueriesData({ queryKey: ["offers"] });
      allCachedOffers.forEach(([keys]) => {
        queryClient.setQueryData(keys, (oldData = []) => {
          const offers = oldData as Offer[];
          return offers.map((o) => (o.id !== offerId ? o : { ...o, status: OfferStatus.Accepted }));
        });
      });
      onSuccess?.();
    },
  });
};

export const useRejectOfferMutation = (offerId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<unknown, AxiosError, { reason: RejectReason; comment?: string; newDeadline?: Date }>({
    mutationFn: (form) => {
      const comment =
        form.newDeadline && form.reason.id == "1"
          ? `${form.comment} [Suggested new deadline: ${form.newDeadline}]`
          : form.comment;
      return requestRejectOffer(offerId, form.reason.id, comment);
    },
    onError: (error, form) => {
      toast.error(t("offer.modal.error"));
      datadogRum.addError("reject-offer-failed", {
        offerId,
        reason: form.reason,
        comment: form.comment,
        error,
      });
    },
    onSuccess: async (_, form) => {
      toast.success(t("offer.modal.decline.success"));
      datadogRum.addAction("reject-offer-success", {
        offerId,
        reason: form.reason,
        comment: form.comment,
      });
      await queryClient.cancelQueries({ queryKey: ["offers"] });
      const allCachedOffers = queryClient.getQueriesData({ queryKey: ["offers"] });
      allCachedOffers.forEach(([keys]) => {
        queryClient.setQueryData<Offer[]>(keys, (old = []) => old?.filter((offer) => offer.id !== offerId));
      });
      onSuccess?.();
    },
  });
};
