import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import { InfoIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
interface Props {
  shouldDisplayQAForm: boolean;
}
export function QaFormSidebar({ shouldDisplayQAForm }: Props) {
  const { t } = useTranslation();
  if (!shouldDisplayQAForm) {
    return null;
  }
  return (
    <div data-test="delivery-container-memsource-qa-from" className="mt-2">
      <Alert variant="info">
        <InfoIcon className="size-4" />
        <AlertTitle className="text-xs">QA form</AlertTitle>
        <AlertDescription className="text-xs">{t("task.detail.cat-tool-deliver-message")}</AlertDescription>
      </Alert>
    </div>
  );
}
