import Collapse from "components/Collapse";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { LinkIcon } from "lucide-react";
import XtmShareJobLink from "pages/project/common/taskBase/list/XtmShareJobLink";
import { useXtmData } from "query/task.query";
import { useTranslation } from "react-i18next";

interface Props {
  isTaskOngoing: boolean;
  taskId: string;
}

export const XtmJobLinksTable = ({ taskId, isTaskOngoing }: Props) => {
  const { t } = useTranslation();
  const { projects, isFetching, error } = useXtmData(taskId, isTaskOngoing, true);

  if (isFetching) {
    return <Loader isShown={true} />;
  }

  return (
    <Collapse
      defaultOpen={projects.length < 10}
      header={
        <h5 className="my-2 space-x-2 font-medium">
          <span>{t("task.detail.jobLinks.xtm.title")}</span>
          <span>({projects.length})</span>
        </h5>
      }
    >
      {projects.length === 0 ? (
        <EmptyState
          icon={LinkIcon}
          title={t("task.detail.files.title-no-delivered-files")}
          subtitle={error ? t("error:common.server") : t("task.detail.files.title-no-delivered-files-detail")}
        />
      ) : (
        <ul className="-my-4 ml-6 mt-2 list-none">
          {projects.map((project, index) => (
            <li key={`xtm link ${index}`} className="my-2">
              <XtmShareJobLink anonymousJobsLink={project.anonymousJobsLink} xtmProjectName={project.xtmProjectName} />
            </li>
          ))}
        </ul>
      )}
    </Collapse>
  );
};
