import { AxiosResponse } from "axios";
import api from "api/api";

export async function requestDownloadFile(
  url: string,
  defaultFile: {
    name: string;
    extension: string;
  }
): Promise<void> {
  const response = await api.get(url, { responseType: "blob", timeout: 0 }); // no timeout for blob
  const fileName = getDownloadedFileNameFromResponse(response, defaultFile);
  const href = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

function getDownloadedFileNameFromResponse(
  response: AxiosResponse,
  defaultFile: {
    name: string;
    extension: string;
  }
): string {
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      const fileNameFromHeader = matches[1].replace(/['"]/g, "");
      return fileNameFromHeader.includes(".") ? fileNameFromHeader : `${fileNameFromHeader}.${defaultFile.extension}`;
    }
  }
  return `${defaultFile.name}.${defaultFile.extension}`;
}
