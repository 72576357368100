import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion";
import { t } from "i18next";
import { MessageSquareMoreIcon } from "lucide-react";
import { useTaskInstructionList } from "query/task.query";

interface Props {
  taskId: string;
}

function TaskInstructions({ taskId }: Props) {
  const { isLoading, instructions, error } = useTaskInstructionList(taskId);

  if (isLoading) {
    return <Loader isShown={true} />;
  }

  if (instructions.length === 0) {
    return (
      <EmptyState
        icon={MessageSquareMoreIcon}
        title={t("task.detail.instructions.title-no-instructions")}
        subtitle={error ? t("error:common.server") : t("task.detail.instructions.title-no-instructions-detail")}
      />
    );
  }

  return (
    <Accordion type="single" collapsible className="w-full">
      {instructions.map(({ title, description, formattedText, instructionId }, index) => {
        const hasContent = isHasContent(formattedText) || isHasContent(description);
        const titleOrDefault = isHasContent(title) ? title : `${t("task.detail.instructions.label")} ${index + 1}`;
        return (
          <AccordionItem value={instructionId.toString()} key={instructionId} className="border-b-0">
            {hasContent ? (
              <AccordionTrigger>{titleOrDefault}</AccordionTrigger>
            ) : (
              <div className="py-4 font-medium capitalize">{titleOrDefault}</div>
            )}
            <AccordionContent className="mb-2 ml-4 border-l-2 border-gray-300 pb-0 pl-2 dark:border-gray-500">
              {isHasContent(formattedText) ? <span dangerouslySetInnerHTML={{ __html: formattedText }} /> : description}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

const isHasContent = (content: string | null | undefined): content is string => {
  return !!content && content.trim().length > 0;
};

export default TaskInstructions;
