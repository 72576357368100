import { useQuery } from "@tanstack/react-query";
import { PreInvoiceDetailDto, PreInvoiceDto, PreInvoiceTaskDto } from "api/api.typing";
import { fetchPreInvoiceDetail, fetchPreInvoiceTasks, fetchPreInvoices } from "api/pre-invoice.api";
import { AxiosError } from "axios";
import { toastContent } from "components/toastContent";
import { t } from "i18next";
import uniqBy from "lodash/uniqBy";
import { PreInvoice, PreInvoiceDetail, PreInvoiceTask } from "pages/pre-invoice/pre-invoice.typing";
import { useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatServerError } from "utils/format";
import { LanguageType } from "utils/language";

function preInvoiceMapper(preInvoiceDto: PreInvoiceDto): PreInvoice {
  return { ...preInvoiceDto };
}

function preInvoiceTasksMapper(preInvoiceDto: PreInvoiceTaskDto): PreInvoiceTask {
  return { ...preInvoiceDto };
}

function preInvoiceDetailMapper(preInvoiceDto: PreInvoiceDetailDto): PreInvoiceDetail {
  const invoice = preInvoiceMapper(preInvoiceDto.invoice);
  return { ...invoice, tasks: preInvoiceDto.tasks.map(preInvoiceTasksMapper) };
}

export const usePreInvoiceList = () => {
  const {
    data = [],
    isLoading,
    error,
  } = useQuery<PreInvoice[], AxiosError>({
    queryKey: ["pre-invoices"],
    queryFn: async ({ signal }) => {
      const response = await fetchPreInvoices(signal);
      return response.map(preInvoiceMapper);
    },
  });
  const hasPreInvoices = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      if (message) {
        toast.error(toastContent(t("pre-invoice.error-title.pre-invoice-list"), message), {
          id: "pre-invoice-list-fetch-error",
        });
      }
    }
  }, [error]);

  return { preInvoices: data, hasOffers: hasPreInvoices, isLoading, error };
};

export function useEntityPreInvoiceList() {
  const { preInvoices } = usePreInvoiceList();

  const entities = useMemo(
    () =>
      uniqBy(
        preInvoices
          .filter((v) => !!v.branchId)
          .map((v) => ({
            value: v.branchId.toString(),
            label: v.branchName,
          })),
        "value"
      ),
    [preInvoices]
  );
  return { entities };
}

export function useLanguagesPreInvoiceTaskList(preInvoiceId: number) {
  const { preInvoiceTasks } = usePreInvoice(preInvoiceId);

  const { sourceLanguages, targetLanguages, taskTypes } = useMemo(() => {
    const sourceLanguages = uniqBy(
      preInvoiceTasks
        .filter((v) => v.sourceLanguage)
        .map((v) => ({
          value: v.sourceLanguage.toString(),
          label: v.sourceLanguage,
        })),
      "value"
    );
    const targetLanguages = uniqBy(
      preInvoiceTasks
        .filter((v) => v.targetLanguage)
        .map((v) => ({
          value: v.targetLanguage.toString(),
          label: v.targetLanguage,
        })),
      "value"
    );
    const taskTypes = uniqBy(
      preInvoiceTasks
        .filter((v) => v.taskType)
        .map((v) => ({
          value: v.taskType.toString(),
          label: v.taskType,
        })),
      "value"
    );
    return { sourceLanguages, targetLanguages, taskTypes };
  }, [preInvoiceTasks]);
  return { sourceLanguages, targetLanguages, taskTypes };
}

export const usePreInvoice = (preInvoiceId: number) => {
  const { i18n } = useTranslation();
  const {
    data = [],
    isLoading,
    error,
  } = useQuery<PreInvoiceTask[], AxiosError>({
    queryKey: ["pre-invoices", preInvoiceId, "tasks", i18n.language],
    queryFn: async ({ signal }) => {
      const response = await fetchPreInvoiceTasks(preInvoiceId, i18n.language as LanguageType, signal);
      return response.map(preInvoiceTasksMapper);
    },
  });
  const hasPreInvoiceTasks = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      if (message) {
        toast.error(toastContent(t("pre-invoice-tasks.error-title.pre-invoice-tasks"), message), {
          id: "pre-invoice-tasks-fetch-error",
        });
      }
    }
  }, [error]);

  return { preInvoiceTasks: data, hasOffers: hasPreInvoiceTasks, isLoading, error };
};

export const usePreInvoiceDetail = (preInvoiceId: number) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const {
    data = null,
    isLoading,
    error,
  } = useQuery<PreInvoiceDetail, AxiosError>({
    queryKey: ["pre-invoices", preInvoiceId, "detail", i18n.language],
    queryFn: async ({ signal }) => {
      const response = await fetchPreInvoiceDetail(preInvoiceId, i18n.language as LanguageType, signal).catch();
      return preInvoiceDetailMapper(response);
    },
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error.response?.status === 404) {
      navigate("/error/404");
      return;
    }
    const message = formatServerError(error);
    if (message) {
      toast.error(toastContent(t("pre-invoice-detail.error-title.pre-invoice-detail"), message), {
        id: "pre-invoice-detail-fetch-error",
      });
    }
  }, [error, navigate]);

  return { preInvoice: data, isLoading, error };
};
