import SupplierContactListBox from "../listbox/SupplierContactListbox";
import { useTranslation } from "react-i18next";

interface Props {
  supplierContactId: number;
  onChange: (supplierContactId: number, supplierContactName: string) => void;
}

export default function SupplierContactListBoxInput({ onChange, supplierContactId }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <label className="text-color-secondary">{t("task-base.detail.supplier-contact")}</label>
      <SupplierContactListBox
        type="formInput"
        supplierContactId={supplierContactId}
        onSupplierContactChange={(id, name) => {
          onChange(id, name);
        }}
      />
    </div>
  );
}
