import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import PageNavBar from "layout/PageNavBar";
import PageFooter from "layout/PageFooter";
import FileDownloadListener from "components/FileDownloadListener";
import { useUser } from "query/user.query";
import { initAppcue } from "utils/appcues";
import { datadogRum } from "@datadog/browser-rum";

function MainLayout() {
  const { data: user } = useUser();

  useEffect(() => {
    if (user) {
      initAppcue(user);
      datadogRum.setUser({ email: user.email });
    }
  }, [user]);

  return (
    <div className="flex h-full flex-col">
      <PageNavBar />
      <main className="flex-1 border-y border-slate-200 bg-gray-100 dark:border-slate-600 dark:bg-gray-700">
        <div className="">
          <Outlet />
          <FileDownloadListener />
        </div>
      </main>
      <PageFooter />
    </div>
  );
}

export default MainLayout;
