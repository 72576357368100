import { FC, useEffect } from "react";
import { useHubConnection } from "store/useHubConnection";

const HubProvider: FC = () => {
  const { start, stop } = useHubConnection((state) => ({
    start: state.start,
    stop: state.stopConnection,
  }));

  useEffect(() => {
    start();
    return () => {
      stop();
    };
  }, [start, stop]);

  return <></>;
};

export default HubProvider;
