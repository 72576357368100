import { clsx } from "clsx";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "components/ui/hover-card";
import { Progress } from "components/ui/progress";
import { ScrollArea } from "components/ui/scroll-area";
import { AlertTriangleIcon, Info } from "lucide-react";
import { XtmSteps } from "pages/project/common/taskBase.typing";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

const SegmentStatusContainer = ({ children, type }: PropsWithChildren<{ type: "warning" | "info" }>) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn("mb-4 rounded-md p-4", {
        "bg-yellow-100 text-yellow-600 dark:bg-yellow-700 dark:text-yellow-200": type === "warning",
        "bg-ribbon-100 text-ribbon-500 dark:bg-ribbon-700 dark:text-slate-200": type === "info",
      })}
    >
      <div className="flex items-center gap-2">
        <div className={clsx("shrink-0")}>
          {type === "warning" ? (
            <AlertTriangleIcon className="h-8 w-8" aria-hidden="true" />
          ) : (
            <Info className="h-8 w-8" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3 flex flex-1 flex-col gap-2">
          <p className="text-sm">
            {type === "warning"
              ? t("translation:task.detail.xtmSegments.deliver.nok")
              : t("translation:task.detail.xtmSegments.deliver.ok")}
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};

export function XtmSegments({ xtmSteps }: { xtmSteps: XtmSteps }) {
  const hasAllSegmentSubmitted = xtmSteps.progression === 100;
  return (
    <>
      <HoverCard openDelay={300}>
        <HoverCardTrigger className="cursor-pointer" data-test="xtm-segment-card-hover-trigger">
          <SegmentStatusContainer type={hasAllSegmentSubmitted ? "info" : "warning"}>
            <SegmentProgress
              totalDocuments={xtmSteps.details.length}
              progression={xtmSteps.progression}
              totalSegments={xtmSteps.totalSegments}
              doneSegments={xtmSteps.doneSegments}
            />
          </SegmentStatusContainer>
        </HoverCardTrigger>
        <HoverCardContent>
          <ScrollArea
            className={cn({
              "h-96": xtmSteps.details.length > 4,
            })}
          >
            <div className="flex flex-col gap-y-4" data-test="xtm-segment-card-hover-content">
              {xtmSteps.details.map((segment, index) => {
                return (
                  <div
                    key={segment.documentName}
                    className="mx-4 grid grid-cols-1 place-content-stretch content-stretch pt-3 sm:grid-cols-5"
                  >
                    <div className="max-w-[180px] truncate sm:col-span-2" title={segment.documentName}>
                      {index + 1}. {segment.documentName}
                    </div>
                    <div className="sm:col-span-3">
                      <SegmentProgress
                        progression={segment.progression}
                        totalSegments={segment.totalSegments}
                        doneSegments={segment.doneSegments}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollArea>
        </HoverCardContent>
      </HoverCard>
    </>
  );
}

interface SegmentProgressProps {
  progression: number;
  doneSegments: number;
  totalSegments: number;
  totalDocuments?: number;
}

const SegmentProgress = ({ totalDocuments, totalSegments, doneSegments, progression }: SegmentProgressProps) => {
  const { t } = useTranslation();
  const isInProgress = progression < 100;
  return (
    <div className="flex flex-col gap-1">
      <Progress
        value={progression}
        className={cn("hidden sm:block", { "bg-goldfish-300 ": isInProgress })}
        classNameIndicator={isInProgress ? "bg-yellow-500 dark:bg-yellow-500" : "bg-ribbon-500 dark:bg-ribbon-600"}
      />
      <div
        className={cn(
          "text-primary-color flex flex-1 flex-col text-xs sm:flex-row sm:items-center sm:justify-between sm:gap-4"
        )}
      >
        <span>
          {totalDocuments !== undefined ? (
            <span>{t("translation:task.detail.xtmSegments.document", { totalDocuments })}</span>
          ) : null}
          <span>{t("translation:task.detail.xtmSegments.segments", { doneSegments, totalSegments })}</span>
        </span>
        <span className="hidden sm:block">{progression} %</span>
        <span className="sm:hidden">{t("translation:task.detail.xtmSegments.progression", { progression })}</span>
      </div>
    </div>
  );
};
