import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Button from "components/buttons/Button";
import SupplierContactListBoxInput from "components/input/SupplierContactListInput";
import Modal from "components/modal/Modal";
import { TaskBase } from "pages/project/common/taskBase.typing";
import { useUpdateSupplierContact } from "query/task.query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  task: TaskBase;
  onClose: () => void;
  shown: boolean;
}

const UpdateSupplierContactModal = ({ task, onClose, shown }: Props) => {
  const { t } = useTranslation();
  const [supplierContactId, setSupplierContactId] = useState<number>(task.supplier.supplierContact.id!);
  const { mutate, isSuccess, reset } = useUpdateSupplierContact(task.id);

  const handleOnClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  useEffect(() => {
    if (isSuccess) {
      handleOnClose();
    }
  }, [isSuccess, handleOnClose]);

  return (
    <>
      <Modal isShown={shown} onClose={handleOnClose}>
        <div>
          {/*Icon*/}
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          {/*Header*/}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
              <div>{t("task.modal.update-field.supplier-contact.title")}</div>
              <div>{task.taskCode}</div>
            </Dialog.Title>
          </div>

          {/*body*/}
          <div>
            <SupplierContactListBoxInput
              supplierContactId={supplierContactId}
              onChange={(id) => {
                setSupplierContactId(id);
              }}
            />
          </div>
          <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
            <Button
              type="button"
              color="ternary"
              label={t("task.modal.update-field.btn-cancel")}
              onClick={() => handleOnClose()}
            />
            <Button
              type="button"
              color="primary"
              label={t("task.modal.update-field.confirm")}
              onClick={() => mutate(supplierContactId)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateSupplierContactModal;
