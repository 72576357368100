import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import NavigationTabsHeader from "components/header/NavigationTabsHeader";
import TaskBaseListFilter from "pages/project/common/taskBase/filter/TaskBaseListFilter";
import { useTaskBaseFilter } from "pages/project/common/taskBase/filter/useTaskBaseFilter";
import TaskBaseListCard from "pages/project/common/taskBase/list/TaskBaseListCard";
import TaskBaseListTable from "pages/project/common/taskBase/list/TaskBaseListTable";
import AcceptOfferModal from "pages/project/offer/components/modals/AcceptOfferModal";
import DeclineOfferModal from "pages/project/offer/components/modals/DeclineOfferModal";
import OfferSideBar from "pages/project/offer/components/sidebar/OfferSideBar";
import { OFFER_LIST_TYPE, OfferListRouteParam, OfferListType } from "pages/project/offer/offer.typing";
import { useOfferCounts, useOfferListByType } from "query/offer.query";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { cn } from "utils/ui";

const partialTabs: Array<{ name: string; to: OfferListType; count: 0 }> = [
  { name: "offer.navigation.pending", to: OFFER_LIST_TYPE.pending, count: 0 },
  { name: "offer.navigation.accepted", to: OFFER_LIST_TYPE.accepted, count: 0 },
];

function OfferListPage() {
  const { t } = useTranslation();
  const [filterParams, onChangeParams] = useTaskBaseFilter("offer");
  const { id: offerId, type = OFFER_LIST_TYPE.pending, action } = useParams<OfferListRouteParam>();
  const { offers, hasOffers, isLoading, error } = useOfferListByType(type, filterParams);
  const offerCounts = useOfferCounts();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const methods = useForm();
  const taskBaseStartDateLabel = t("task-base.fields.estimatedStartOn");
  const isOfferDetailOpen = !!offerId;
  const tabs = partialTabs.map((t) => ({
    ...t,
    count: t.to === "pending" ? offerCounts.pending : offerCounts.accepted,
    buildUrl: () => `/project/offer/list/${t.to}?${searchParams.toString()}`,
  }));

  const handleOnDecline = () => {
    navigate(`/project/offer/list/${type}/${offerId}/decline?${searchParams.toString()}`);
  };
  const handleOnAccept = () => {
    navigate(`/project/offer/list/${type}/${offerId}/accept?${searchParams.toString()}`);
  };
  const handleOnSideBarClose = () => {
    navigate(`/project/offer/list/${type}?${searchParams.toString()}`);
  };

  return (
    <div className="min-h-full">
      <div className="flex flex-col sm:flex-1">
        <NavigationTabsHeader
          tabs={tabs}
          selectedTab={type}
          dataTestId="offers-nav"
          pageName="offers"
          subTitle={t(`offer.subTitle.${type}`)}
          title={t("offer.title")}
        />
        <div className="flex flex-row">
          <div className={cn("flex-1", { "hidden lg:block": isOfferDetailOpen })}>
            <div className={cn("my-4 ml-4", { "mr-4": !isOfferDetailOpen })}>
              <TaskBaseListFilter
                isDetailOpen={isOfferDetailOpen}
                params={filterParams}
                onChange={onChangeParams}
                isUserSupplier={true} /*only suppliers can see offers*/
              />
              <Loader isShown={isLoading} />
              {!isLoading && !hasOffers && (
                <EmptyState
                  title={t(`offer.empty.${type}.title`)}
                  subtitle={
                    error
                      ? t("error:common.server")
                      : filterParams.term && filterParams.term.length > 0
                        ? t("offer.empty.noResult")
                        : t(`offer.empty.${type}.subtitle`)
                  }
                />
              )}
              {!isLoading &&
                hasOffers &&
                (filterParams.view === "table" ? (
                  <TaskBaseListTable
                    type="offer"
                    tasksBase={offers}
                    selectedTaskBaseId={offerId}
                    listFilterParams={filterParams}
                    onChange={onChangeParams}
                    taskBaseColumnName={t("offer.fields.offer")}
                    taskBaseStartDateLabel={taskBaseStartDateLabel}
                    isUserSupplier={true} /*only suppliers can see offers*/
                  />
                ) : (
                  <TaskBaseListCard
                    type="offer"
                    tasksBase={offers}
                    selectedTaskBaseId={offerId}
                    listFilterParams={filterParams}
                    taskBaseStartDateLabel={taskBaseStartDateLabel}
                    isUserSupplier={true} /*only suppliers can see offers*/
                  />
                ))}
            </div>
          </div>
          {!isLoading && hasOffers && (
            <>
              <OfferSideBar
                selectedOfferId={offerId}
                onClose={handleOnSideBarClose}
                onErrorNode={() => (
                  <Navigate to={`/project/offer/list/${type}?${filterParams.toString()}`} replace={true} />
                )}
                onAccept={handleOnAccept}
                onDecline={handleOnDecline}
                filterType={type}
              />
              {action === "accept" && <AcceptOfferModal offerId={offerId} show={true} />}
              {action === "decline" && (
                <FormProvider {...methods}>
                  <DeclineOfferModal filterType={type} offerId={offerId} show={true} />
                </FormProvider>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OfferListPage;
