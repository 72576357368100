import { CalendarIcon, CheckIcon, PlusCircleIcon } from "lucide-react";
import { Column } from "@tanstack/react-table";

import { cn } from "utils/ui";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Separator } from "components/ui/separator";
import { useTranslation } from "react-i18next";
import { Calendar } from "components/ui/calendar";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { addDays, format } from "date-fns";
import { ComponentType, useEffect, useState } from "react";

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  className?: string;
  options: {
    label: string;
    value: string;
    icon?: ComponentType<{ className?: string }>;
  }[];
}

export function DataTableFacetedFilter<TData, TValue>({
  column,
  title,
  options,
  className = "w-[200px]",
}: DataTableFacetedFilterProps<TData, TValue>) {
  const { t } = useTranslation();
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue() as string[]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-9 border-dashed">
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {selectedValues.size} {t("translation:facets.selected")}
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge variant="secondary" key={option.value} className="rounded-sm px-1 font-normal">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("p-0", className)} align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>{t("translation:facets.not-found")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      column?.setFilterValue(filterValues.length ? filterValues : undefined);
                    }}
                  >
                    <div
                      className={cn(
                        "border-primary mr-2 flex h-4 w-4 items-center justify-center rounded-sm border",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && <option.icon className="text-color-secondary mr-2 h-4 w-4" />}
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className="justify-center text-center"
                  >
                    {t("translation:facets.clear")}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export function DataTableFacetedDateFilter<TData, TValue>({
  column,
  title,
}: Omit<DataTableFacetedFilterProps<TData, TValue>, "options">) {
  const [predefinedRange, setPredefinedRange] = useState<string | null>(null);
  const { t } = useTranslation();
  const value = column?.getFilterValue() as { from: Date; to: Date } | undefined;

  useEffect(() => {
    if (!predefinedRange) {
      return;
    }
    column?.setFilterValue({
      from: addDays(new Date(), parseInt(predefinedRange) * -1),
      to: new Date(),
    });
  }, [column, predefinedRange]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-9 border-dashed">
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          {title}

          {value?.from && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                <CalendarIcon className="h-4 w-4 shrink-0" />
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {value.to ? (
                  <>
                    {format(value.from, "LLL dd, y")} - {format(value.to, "LLL dd, y")}
                  </>
                ) : (
                  format(value.from, "LLL dd, y")
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Select value={predefinedRange ?? ""} onValueChange={(value) => setPredefinedRange(value)}>
          <SelectTrigger className="rounded-b-none border-0 focus:ring-0 focus:ring-offset-0">
            <SelectValue placeholder={t("translation:date-range.select")} />
          </SelectTrigger>
          <SelectContent position="popper">
            <SelectItem value="3">{t("translation:date-range.last-days")}</SelectItem>
            <SelectItem value="7">{t("translation:date-range.last-week")}</SelectItem>
            <SelectItem value="30">{t("translation:date-range.last-month")}</SelectItem>
            <SelectItem value="60">{t("translation:date-range.last-months")}</SelectItem>
          </SelectContent>
        </Select>
        <Separator />
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={value?.from}
          selected={value}
          numberOfMonths={2}
          onSelect={(range) => {
            setPredefinedRange(null);
            column?.setFilterValue(range);
          }}
        />
        <Separator />
        <Button
          variant="ghost"
          onClick={() => {
            setPredefinedRange(null);
            column?.setFilterValue(undefined);
          }}
          className="w-full justify-center text-center"
        >
          {t("translation:facets.clear")}
        </Button>
      </PopoverContent>
    </Popover>
  );
}
