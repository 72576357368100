import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  term: string | null;
  onSearch: (term: string | null) => void;
  className?: string;
}

const SearchInput = ({ term = "", className, onSearch }: Props) => {
  const { t } = useTranslation("translation");

  const handleOnSearch = (fromTerm: string) => {
    onSearch(fromTerm.trim() === "" ? null : fromTerm);
  };
  return (
    <div className={cn("text-color-primary min-w-0 flex-1", className)}>
      <label htmlFor="offer-search" className="sr-only">
        {t("common.search.label")}
      </label>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="task-offer-search"
          type="search"
          name="pp-search"
          value={term ?? ""}
          autoComplete="one-time-code"
          onChange={(e) => handleOnSearch(e.target.value)}
          placeholder={t("common.search.label")}
          className="text-color-primary w-full rounded-md border-gray-300 pl-10 shadow-sm sm:text-sm dark:border-slate-600 dark:bg-slate-700 dark:placeholder:text-slate-400"
        />
      </div>
    </div>
  );
};
export default SearchInput;
