import { ColumnDef, SortingState, Table } from "@tanstack/react-table";
import { DataTable } from "components/DataTable";
import { DataTableFacetedDateFilter, DataTableFacetedFilter } from "components/DataTableFacetedFilter";
import HighlightText from "components/HighlightText";
import { PreInvoiceTask } from "pages/pre-invoice/pre-invoice.typing";
import { useLanguagesPreInvoiceTaskList } from "query/pre-invoice.query";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterDateRange, filterStingInclude } from "utils/datatable";

interface Props {
  tasks: PreInvoiceTask[];
  preInvoiceId: number;
}

export function PreInvoiceTasksTable({ tasks, preInvoiceId }: Props) {
  const { t } = useTranslation("translation");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortingState>([{ id: "taskCode", desc: false }]);

  const columns = useMemo<ColumnDef<PreInvoiceTask>[]>(
    () => [
      {
        id: "taskCode",
        header: t("pre-invoice-tasks.columns.taskCode"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (preInvoiceTask) => preInvoiceTask.taskCode,
        cell(props) {
          return <HighlightText term={searchTerm} text={props.row.original.taskCode?.toString()} />;
        },
      },
      {
        id: "taskType",
        header: t("pre-invoice-tasks.columns.taskType"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: filterStingInclude,
        accessorFn: (preInvoiceTask) => preInvoiceTask.taskType,
        cell(props) {
          return props.row.original.taskType;
        },
      },
      {
        id: "contact",
        header: t("pre-invoice-tasks.columns.contact"),
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (preInvoiceTask) => `${preInvoiceTask.contactName ?? ""} ${preInvoiceTask.contactEmail ?? ""}`,
        cell(props) {
          return (
            <div className="flex flex-col gap-1">
              <HighlightText term={searchTerm} text={props.row.original.contactName} />
              <HighlightText
                term={searchTerm}
                text={props.row.original.contactEmail}
                className="text-color-secondary"
              />
            </div>
          );
        },
      },
      {
        id: "startDate",
        header: t("pre-invoice-tasks.columns.startDate"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: filterDateRange,
        accessorFn: (preInvoiceTask) => preInvoiceTask.startDate,
        cell(props) {
          return t("enum:dates.mediumDateTime", { date: props.row.original.startDate });
        },
      },
      {
        id: "endTime",
        header: t("pre-invoice-tasks.columns.endTime"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: filterDateRange,
        accessorFn: (preInvoiceTask) => preInvoiceTask.endTime,
        cell(props) {
          return t("enum:dates.mediumDateTime", { date: props.row.original.endTime });
        },
      },
      {
        id: "sourceLanguage",
        header: t("pre-invoice-tasks.columns.sourceLanguage"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: filterStingInclude,
        accessorFn: (preInvoiceTask) => preInvoiceTask.sourceLanguage,
        cell(props) {
          return props.row.original.sourceLanguage;
        },
      },
      {
        id: "targetLanguage",
        header: t("pre-invoice-tasks.columns.targetLanguage"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        filterFn: filterStingInclude,
        accessorFn: (preInvoiceTask) => preInvoiceTask.targetLanguage,
        cell(props) {
          return props.row.original.targetLanguage;
        },
      },
      {
        id: "amount",
        header: t("pre-invoice-tasks.columns.amount"),
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (preInvoiceTask) => preInvoiceTask.price,
        cell(props) {
          const preInvoiceTask = props.row.original;
          const total = t("enum:intlNumber", { val: preInvoiceTask.price });
          const currencyCode = preInvoiceTask.currency;
          return <HighlightText term={searchTerm} text={`${total} ${currencyCode}`} />;
        },
      },
    ],
    [t, searchTerm]
  );
  return (
    <DataTable
      uniqueName="pre-invoice-tasks"
      defaultPageSize={5}
      columns={columns}
      data={tasks}
      sortBy={sortBy}
      onSortBy={(sortBy) => setSortBy(sortBy)}
      searchTerm={searchTerm}
      onSearch={(searchTerm) => setSearchTerm(searchTerm)}
      facetedFilters={(table) => <FacetedFilters preInvoiceId={preInvoiceId} table={table} />}
    />
  );
}

function FacetedFilters<TData>({ table, preInvoiceId }: { table: Table<TData>; preInvoiceId: number }): ReactElement {
  const { t } = useTranslation();
  const { sourceLanguages, targetLanguages, taskTypes } = useLanguagesPreInvoiceTaskList(preInvoiceId);

  return (
    <>
      {taskTypes.length > 0 && table.getColumn("taskType") ? (
        <DataTableFacetedFilter
          className="w-[350px]"
          column={table.getColumn("taskType")}
          title={t("pre-invoice-tasks.columns.taskType")}
          options={taskTypes}
        />
      ) : null}
      {table.getColumn("startDate") ? (
        <DataTableFacetedDateFilter
          column={table.getColumn("startDate")}
          title={t("pre-invoice-tasks.columns.startDate")}
        />
      ) : null}
      {table.getColumn("endTime") ? (
        <DataTableFacetedDateFilter
          column={table.getColumn("endTime")}
          title={t("pre-invoice-tasks.columns.endTime")}
        />
      ) : null}
      {sourceLanguages.length > 0 && table.getColumn("sourceLanguage") ? (
        <DataTableFacetedFilter
          className="w-[350px]"
          column={table.getColumn("sourceLanguage")}
          title={t("pre-invoice-tasks.columns.sourceLanguage")}
          options={sourceLanguages}
        />
      ) : null}
      {targetLanguages.length > 0 && table.getColumn("targetLanguage") ? (
        <DataTableFacetedFilter
          className="w-[350px]"
          column={table.getColumn("targetLanguage")}
          title={t("pre-invoice-tasks.columns.targetLanguage")}
          options={targetLanguages}
        />
      ) : null}
    </>
  );
}
