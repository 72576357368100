import { OFFER_LIST_TYPE } from "pages/project/offer/offer.typing";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAcceptOfferMutation } from "query/offer.query";

export interface AcceptFormModel {
  comment?: string;
}

export const useAcceptOfferForm = (offerId: string) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm<AcceptFormModel>();

  const onReset = () => {
    reset();
    navigate(`/project/offer/list/${OFFER_LIST_TYPE.pending}/${offerId}?${params.toString()}`);
  };

  const onSuccess = () => {
    reset();
    navigate(`/project/offer/list/${OFFER_LIST_TYPE.accepted}/${offerId}?${params.toString()}`);
  };

  const { mutate, ...others } = useAcceptOfferMutation(offerId, onSuccess);

  return { ...others, register, reset: onReset, accept: handleSubmit((params) => mutate(params)) };
};
