import { ReactElement } from "react";
import { cn } from "utils/ui";

interface Props {
  label: string;
  value: string | null;
  taskId: string;
  icon?: ReactElement;
}

const SideBarLabelValue = ({ label, value, taskId, icon }: Props) => {
  return (
    <div className="flex flex-col items-start space-y-2">
      <label className="text-color-secondary">{label}</label>
      <div className={cn("inline-flex flex-nowrap items-center space-x-1")}>
        <span className="font-medium" data-test={`label-${label}-value-${taskId}`.replace(/\s/g, "_")}>
          {value ?? ""}
        </span>
        {icon}
      </div>
    </div>
  );
};

export default SideBarLabelValue;
