import { BaseComponentProps } from "components/typings";
import { Link, LinkProps } from "react-router-dom";
import { cn } from "utils/ui";

interface Props extends LinkProps, BaseComponentProps {
  label: string;
  color?: "primary" | "secondary" | "ternary";
}

const AnchorLink = ({ label, color = "primary", className = "", dataActionName, dataTestId, ...props }: Props) => {
  return (
    <Link
      data-test={dataTestId}
      data-dd-action-name={
        dataActionName /* don't know why but this does not work while attributes exists on tag <a> in the DOM */
      }
      className={cn(
        "offset-ring-500 select-none outline-ribbon-300 hover:underline",
        {
          "text-ribbon-500 dark:text-ribbon-400": color === "primary",
          "text-color-secondary": color === "secondary",
          "text-white underline": color === "ternary",
        },
        className
      )}
      {...props}
    >
      {label}
    </Link>
  );
};

export default AnchorLink;
