import AnchorLink from "components/AnchorLink";
import LanguageDropdown from "components/LanguageDropdown";
import ThemeSwitch from "components/buttons/ThemeSwitch";
import PartnerPortalLogo from "components/svgs/Logo";
import { useTranslation } from "react-i18next";
import ENV_PARAMS from "utils/environment";

function PageFooter() {
  const { t, i18n } = useTranslation();
  return (
    <footer className="flex flex-col items-center justify-between space-y-4 p-4 text-sm sm:flex-row sm:space-y-0">
      <div className="hidden sm:block">
        <PartnerPortalLogo size="small" />
      </div>
      <div className="flex flex-col items-center space-y-2">
        <div className="flex flex-col items-center space-y-1 sm:flex-row sm:justify-center sm:space-x-2 sm:space-y-0">
          <AnchorLink
            label={t("footer.legal")}
            to="/legal/info"
            color="secondary"
            dataActionName="open-legal-page"
            dataTestId="link-legal"
          />
          <span className="text-color-secondary hidden sm:block">|</span>
          <a
            href={`https://www.acolad.com/content/dam/site/legal/terms/vendors/acolad-general-terms-and-conditions-for-vendors-${i18n.language.toUpperCase()}.pdf`}
            className="offset-ring-500 text-color-secondary select-none hover:underline"
            data-test-id="link-term-and-conditions"
          >
            {t("footer.terms")}
          </a>
          <span className="text-color-secondary hidden sm:block">|</span>
          <AnchorLink
            to="/legal/privacy"
            label={t("footer.privacy")}
            color="secondary"
            dataActionName="open-privacy-policy"
            dataTestId="link-privacy-page"
          />
        </div>
        <div className="text-color-secondary flex flex-row flex-wrap items-center justify-center space-x-2">
          <span title={t("footer.rightsReserved")}>
            {t("footer.acoladInc", { getFullYear: new Date().getFullYear() })}
          </span>
          <span>
            version: <span className="font-bold">{ENV_PARAMS.PARTNER_PORTAL_VERSION}</span>
          </span>
          {ENV_PARAMS.PARTNER_PORTAL_ENV !== "Prod" && (
            <span>
              env: <span className="font-bold">{ENV_PARAMS.PARTNER_PORTAL_ENV}</span>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <ThemeSwitch />
        <LanguageDropdown />
      </div>
    </footer>
  );
}

export default PageFooter;
