import { User } from "api/api.typing";
import { formatUserName } from "utils/format";
import ENV_PARAMS from "utils/environment";
import { datadogLogs } from "@datadog/browser-logs";

declare global {
  interface Window {
    Appcues: { identify: (id: string, values: { description: string }) => void };
    AppcuesSettings: { enableURLDetection: boolean };
  }
}

export const initAppcue = (user: User) => {
  if (!ENV_PARAMS.PARTNER_PORTAL_APPCUES_ENABLED) {
    return;
  }
  if (window.Appcues) {
    window.Appcues.identify(user.email, {
      description: formatUserName(user.firstName, user.lastName),
    });
  } else {
    datadogLogs.logger.error("Failed to load Appcues. 'window.Appcues' is undefined", { email: user?.email });
  }
};

export const loadAppcuesScripts = () => {
  if (!ENV_PARAMS.PARTNER_PORTAL_APPCUES_ENABLED) {
    return;
  }

  const script = document.createElement("script");
  script.src = "https://fast.appcues.com/113582.js";
  document.body.appendChild(script);

  window.AppcuesSettings = {
    enableURLDetection: true,
  };
};
