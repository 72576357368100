import { TaskBase } from "pages/project/common/taskBase.typing";

export type RequestListType = "pending";
export type RequestActionType = "decline" | "accept";

export const REQUEST_LIST_TYPE: Record<RequestListType, RequestListType> = {
  pending: "pending",
};

export enum RequestStatus {
  Sent = 1,
  Accepted = 2,
}

export interface ValidationRequest extends Omit<TaskBase, "id"> {
  id: string; // convert to string, easier to manage in react-router
  taskId: string;
  status: RequestStatus;
  searchFields: string;
  acceptedByAnother: boolean;
}

export const mapToListType = (status: RequestStatus): RequestListType | null => {
  if (status === RequestStatus.Sent) {
    return REQUEST_LIST_TYPE.pending;
  }

  return null;
};

export type RequestListRouteParam = { type: RequestListType; id: string; action: RequestActionType };

export interface ValidationRequestRejectReason {
  id: string;
  label: string;
}
