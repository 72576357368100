import Error from "components/Error";
import Button from "components/buttons/Button";
import InputForm from "components/input/InputForm";
import { useTranslation } from "react-i18next";
import { ResetPasswordSuccess } from "./SetPasswordSuccess";
import { useSetPassword } from "./useSetPassword";

interface Props {
  resetPasswordToken: string;
}

const passwordScoreClass: { [key: number]: string } = {
  1: "bg-red-400",
  2: "bg-red-400",
  3: "bg-red-400",
  4: "bg-yellow-400",
  5: "bg-green-500",
};

const passwordScoreRanges = [0, 1, 2, 3, 4];

const getPasswordStrengthClassName = (index: number, score: number) =>
  index + 1 <= score ? passwordScoreClass[Math.min(Math.floor(score), 5)] : "bg-gray-200";

export const SetPasswordForm = ({ resetPasswordToken }: Props) => {
  const {
    isPending: isSettingPassword,
    setPassword,
    register,
    errors,
    serverError,
    isSuccess,
    passwordScore,
    isValidPassword,
  } = useSetPassword(resetPasswordToken);

  const { t } = useTranslation("translation", { keyPrefix: "set-password" });

  if (isSuccess) {
    return <ResetPasswordSuccess />;
  }

  return (
    <>
      <form className="mb-2 space-y-6" onSubmit={setPassword} noValidate={true}>
        <div>
          <InputForm
            id="password-input"
            data-test="password-input"
            type="password"
            label={t("password.label")}
            autoComplete="new-password"
            hideOnDataDog={true}
            {...register("newPassword")}
            error={errors.newPassword?.message}
          />
          <div className="-mx-1 mt-3 flex">
            {passwordScoreRanges.map((i) => (
              <div className="w-1/5 px-1" key={i}>
                <div
                  data-test={`password-meter-${i}`}
                  className={`h-2 rounded-xl transition-colors ${getPasswordStrengthClassName(i, passwordScore)}`}
                ></div>
              </div>
            ))}
          </div>
          {!isValidPassword && <p className="mt-3 font-sans text-sm font-medium">{t("strong-requirement")}</p>}
        </div>

        {!isSettingPassword && <Error error={serverError} />}

        <Button
          type="submit"
          className="w-full"
          label={t("reset")}
          disabled={isSettingPassword || !isValidPassword}
          dataTestId="submit-set-password-btn"
        />
      </form>
    </>
  );
};
