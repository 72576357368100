import { BarsArrowDownIcon, BarsArrowUpIcon, LinkIcon } from "@heroicons/react/24/outline";
import HighlightText from "components/HighlightText";
import LinkWithParams from "components/LinkWithParams";
import { ListFilterParams } from "components/typings";
import { TaskBase, TaskBaseFields } from "pages/project/common/taskBase.typing";
import { taskBasePriceDisplay } from "pages/project/common/taskBase/taskBasePrice";
import { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  tasksBase: TaskBase[];
  selectedTaskBaseId?: string;
  listFilterParams: ListFilterParams<TaskBase>;
  onChange: (params: ListFilterParams<TaskBase>) => void;
  taskBaseColumnName: string;
  taskBaseStartDateLabel: string;
  type: "offer" | "task" | "request";
  isUserSupplier: boolean;
}

const TaskBaseListTable = ({
  selectedTaskBaseId,
  listFilterParams: params,
  type,
  onChange,
  isUserSupplier,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const isSideBarOpen = !!selectedTaskBaseId;
  const { sortDirection, sortBy } = params;

  const handleOnSort = useCallback(
    (fromSortBy: TaskBaseFields) => {
      const fromSortDirection = fromSortBy !== sortBy ? "asc" : sortDirection === "asc" ? "desc" : "asc";
      onChange({
        ...params,
        sortBy: sortBy === fromSortBy && fromSortDirection === "asc" && sortDirection === "desc" ? null : fromSortBy,
        sortDirection: fromSortDirection,
      });
    },
    [params, onChange, sortBy, sortDirection]
  );

  const sortIcon = useCallback(
    (field: TaskBaseFields): ReactNode => {
      return field === sortBy ? (
        sortDirection === "asc" ? (
          <BarsArrowUpIcon data-test={`col-${field}-ascending-icon`} className="ml-3 h-4 w-4" />
        ) : (
          <BarsArrowDownIcon data-test={`col-${field}-descending-icon`} className="ml-3 h-4 w-4" />
        )
      ) : null;
    },
    [sortBy, sortDirection]
  );

  return (
    <div
      className="flex flex-col rounded-md border border-slate-200 shadow dark:border-slate-800"
      data-test={`${type}-table-container`}
    >
      <table className="min-w-full divide-y divide-gray-100 dark:divide-slate-600" data-test={`${type}-table`}>
        <thead className="text-color-primary bg-color-primary" data-test={`${type}-table-head`}>
          <tr>
            <TableCol
              label={t("task-base.fields.id")}
              field="id"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.projectName")}
              field="projectName"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.customer")}
              field="customerName"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            {isUserSupplier ? (
              <TableCol
                label={t("task-base.fields.supplierContact")}
                field="supplierContactName"
                icon={sortIcon}
                onSort={handleOnSort}
                isSideBarOpen={isSideBarOpen}
              />
            ) : null}
            <TableCol
              label={t("task-base.fields.type")}
              field="taskType"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.source")}
              field="sourceLanguage"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.target")}
              field="targetLanguage"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={props.taskBaseStartDateLabel}
              field="startDate"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.dueOn")}
              field="dueDate"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            <TableCol
              label={t("task-base.fields.quantity")}
              field="quantity"
              icon={sortIcon}
              onSort={handleOnSort}
              isSideBarOpen={isSideBarOpen}
            />
            {isUserSupplier ? (
              <TableCol
                label={t("task-base.fields.total")}
                field="price"
                icon={sortIcon}
                onSort={handleOnSort}
                isSideBarOpen={isSideBarOpen}
              />
            ) : null}
          </tr>
        </thead>
        <tbody
          className="divide-y divide-gray-200 bg-white text-sm dark:divide-slate-600 dark:bg-slate-800"
          data-test={`${type}-table-body`}
        >
          {props.tasksBase.map((taskBase) => (
            <tr
              key={taskBase.id}
              data-test={`row-${type}-${taskBase.id}`}
              className={cn("mb-0.5", taskBase.id === selectedTaskBaseId ? "ring-2 ring-ribbon-500" : "")}
            >
              <td className="py-4 pl-4 pr-3">
                <div className="flex items-center">
                  <LinkWithParams
                    data-test={`row-${type}-${taskBase.id}-open-sidebar`}
                    to={`./${taskBase.id}`}
                    className={
                      "flex flex-row items-center space-x-1 whitespace-nowrap text-base font-medium hover:underline"
                    }
                  >
                    <LinkIcon data-test={`row-${type}-${taskBase.id}-link-icon`} className="h-4 w-4" />
                    <HighlightText
                      dataTestId={`row-${type}-${taskBase.id}-taskCode`}
                      text={`${taskBase.taskCode}`}
                      term={params.term}
                    ></HighlightText>
                  </LinkWithParams>
                </div>
                {/*START CARD VIEW*/}
                <dl
                  data-test={`row-${type}-${taskBase.id}-cardview`}
                  className={cn("text-base font-normal", isSideBarOpen ? "2xl:hidden" : "xl:hidden")}
                >
                  <dd className="mt-1 flex items-center space-x-2 truncate">
                    <span data-test={`row-${type}-${taskBase.id}-projectName-label`} className="text-color-secondary">
                      {t("task-base.fields.projectName")}
                    </span>
                    <HighlightText
                      dataTestId={`row-${type}-${taskBase.id}-projectName-value`}
                      text={taskBase.projectName}
                      term={params.term}
                      className="
                      font-medium"
                    />
                  </dd>
                  <dd className="mt-1 flex items-center space-x-2 truncate">
                    <span data-test={`row-${type}-${taskBase.id}-customerName-label`} className="text-color-secondary">
                      {t("task-base.fields.customer")}
                    </span>
                    <HighlightText
                      dataTestId={`row-${type}-${taskBase.id}-customerName-value`}
                      text={taskBase.customerName}
                      term={params.term}
                      className="font-medium"
                    />
                  </dd>
                  {isUserSupplier && (
                    <dd className="mt-1 flex items-center space-x-2 truncate">
                      <span data-test={`row-${type}-${taskBase.id}-supplierContact`} className="text-color-secondary">
                        {t("task-base.fields.supplierContact")}
                      </span>
                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-supplierContact-value`}
                        text={taskBase.supplier.supplierContact.name}
                        term={params.term}
                        className="font-medium"
                      />
                    </dd>
                  )}
                  <dd className="mt-1 flex items-center space-x-2 truncate">
                    <span data-test={`row-${type}-${taskBase.id}-taskType-label`} className="text-color-secondary">
                      {t("task-base.fields.type")}
                    </span>
                    <HighlightText
                      dataTestId={`row-${type}-${taskBase.id}-taskType-value`}
                      text={taskBase.taskType}
                      term={params.term}
                      className="font-medium"
                    />
                  </dd>
                  <dd className="mt-1 flex flex-col items-start truncate sm:flex-row sm:items-center sm:space-x-2">
                    <div className="flex flex-row space-x-2">
                      <span data-test={`row-${type}-${taskBase.id}-from-label`} className="text-color-secondary">
                        {t("task-base.fields.from")}
                      </span>
                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-sourceLanguage-value`}
                        text={taskBase.sourceLanguage ?? "-"}
                        term={params.term}
                        className="font-medium"
                      />
                    </div>
                    <div className="mt-1 flex flex-row space-x-2 sm:mt-0">
                      <span data-test={`row-${type}-${taskBase.id}-to-label`} className="text-color-secondary">
                        {t("task-base.fields.to")}
                      </span>

                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-targetLanguage-value`}
                        text={taskBase.targetLanguage ?? "-"}
                        term={params.term}
                        className="font-medium"
                      />
                    </div>
                  </dd>
                  <dd className="mt-1 flex flex-col items-start truncate sm:flex-row sm:items-center sm:space-x-2">
                    <div className="flex flex-row space-x-2">
                      <span data-test={`row-${type}-${taskBase.id}-startDate-label`} className="text-color-secondary">
                        {t("task-base.fields.startOn")}
                      </span>
                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-startDate-value`}
                        text={
                          taskBase.startDate
                            ? t("enum:dates.mediumDateTime", { date: taskBase.startDate })
                            : t("task-base.fields.startOnEmpty")
                        }
                        term={params.term}
                        className="font-medium"
                      />
                    </div>
                    <div className="mt-1 flex flex-row space-x-2 sm:mt-0">
                      <span data-test={`row-${type}-${taskBase.id}-dueDate-label`} className="text-color-secondary">
                        {t("task-base.fields.dueOn")}
                      </span>
                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-dueDate-value`}
                        text={t("enum:dates.mediumDateTime", { date: taskBase.dueDate })}
                        term={params.term}
                        className="font-medium"
                      />
                    </div>
                  </dd>
                  <dd className="mt-1 flex items-center space-x-2 truncate">
                    <span data-test={`row-${type}-${taskBase.id}-quantity-label`} className="text-color-secondary">
                      {t("task-base.fields.quantity")}
                    </span>
                    <HighlightText
                      dataTestId={`row-${type}-${taskBase.id}-quantity-value`}
                      text={`${t("enum:intlNumber", { val: taskBase.quantity })} ${taskBase.unit}`}
                      term={params.term}
                      className="font-medium"
                    />
                  </dd>
                  {isUserSupplier ? (
                    <dd className="mt-1 flex items-center space-x-2 truncate">
                      <span data-test={`row-${type}-${taskBase.id}-total-label`} className="text-color-secondary">
                        {t("task-base.fields.total")}
                      </span>
                      <HighlightText
                        dataTestId={`row-${type}-${taskBase.id}-total-value`}
                        text={taskBasePriceDisplay(taskBase)}
                        term={params.term}
                        className="font-medium"
                      />
                    </dd>
                  ) : null}
                </dl>
                {/*END CARD VIEW*/}
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-projectName-value`}
                  text={taskBase.projectName}
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-customerName-value`}
                  text={taskBase.customerName}
                  term={params.term}
                />
              </td>
              {isUserSupplier ? (
                <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                  <HighlightText
                    dataTestId={`row-${type}-${taskBase.id}-supplierContact`}
                    text={taskBase.supplier.supplierContact.name}
                    term={params.term}
                  />
                </td>
              ) : null}
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-taskType-value`}
                  text={taskBase.taskType}
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-sourceLanguage-value`}
                  text={taskBase.sourceLanguage ?? "-"}
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-targetLanguage-value`}
                  text={taskBase.targetLanguage ?? "-"}
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-startDate-value`}
                  text={
                    taskBase.startDate
                      ? t("enum:dates.mediumDateTime", { date: taskBase.startDate })
                      : t("task-base.fields.startOnEmpty")
                  }
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-dueDate-value`}
                  text={t("enum:dates.mediumDateTime", { date: taskBase.dueDate })}
                  term={params.term}
                />
              </td>
              <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                <HighlightText
                  dataTestId={`row-${type}-${taskBase.id}-quantity-value`}
                  text={`${t("enum:intlNumber", { val: taskBase.quantity })} ${taskBase.unit}`}
                  term={params.term}
                />
              </td>
              {isUserSupplier ? (
                <td className={cn("hidden px-3 py-4", isSideBarOpen ? "2xl:table-cell" : "xl:table-cell")}>
                  <HighlightText
                    dataTestId={`row-${type}-${taskBase.id}-total-value`}
                    className="whitespace-nowrap"
                    text={taskBasePriceDisplay(taskBase)}
                    term={params.term}
                  />
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface TableColProps {
  label: string;
  field: TaskBaseFields;
  isSideBarOpen: boolean;
  onSort: (field: TaskBaseFields) => void;
  icon: (field: TaskBaseFields) => ReactNode;
}

const TableCol: FC<TableColProps> = ({ label, field, isSideBarOpen, onSort, icon }: TableColProps) => {
  return (
    <th
      data-test={`col-${field}`}
      scope="col"
      className={`hidden cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 ${
        isSideBarOpen ? "2xl:table-cell" : "xl:table-cell"
      }`}
      onClick={() => onSort(field)}
    >
      <div className="flex items-center">
        <span data-test={`col-${field}-label`}>{label}</span>
        {icon(field)}
      </div>
    </th>
  );
};

export default TaskBaseListTable;
