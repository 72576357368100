import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Badge from "components/Badge";
import HighlightText from "components/HighlightText";
import LinkWithParams from "components/LinkWithParams";
import { ListFilterParams } from "components/typings";
import { TaskBase, getCatToolName } from "pages/project/common/taskBase.typing";
import { taskBasePriceDisplay } from "pages/project/common/taskBase/taskBasePrice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  listFilterParams: ListFilterParams<TaskBase>;
  tasksBase: TaskBase[];
  selectedTaskBaseId?: string;
  type: "offer" | "task" | "request";
  taskBaseStartDateLabel: string;
  isUserSupplier: boolean;
}

const TaskBaseListCard = ({
  type,
  taskBaseStartDateLabel,
  listFilterParams: params,
  isUserSupplier,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>([]);
  const isSideBarOpen = !!props.selectedTaskBaseId;

  const handleOnChange = (id: string, isChecked: boolean) => {
    if (!isChecked) {
      setChecked((state) => [...state].filter((e) => e !== id));
    } else {
      setChecked((state) => [...state, id]);
    }
  };

  return (
    <div className="text-color-base bg-color-base rounded-md text-base shadow" data-test={`${type}-card-container`}>
      <ul role="list" className="divide-y divide-gray-200 dark:divide-slate-600" data-test={`${type}-cards`}>
        {props.tasksBase.map((taskBase) => (
          <li
            key={taskBase.id}
            data-test={`card-${type}-${taskBase.id}`}
            className={cn(
              "mb-0.5 block hover:bg-gray-50 dark:hover:bg-slate-700/30",
              taskBase.id === props.selectedTaskBaseId || checked.includes(taskBase.id) ? "ring-2 ring-ribbon-500" : ""
            )}
          >
            <div className="flex items-center p-4 sm:px-6">
              {/*TODO activate the checkboxes*/}
              <div className="mr-6 hidden shrink-0">
                <input
                  id={`checkbox-${taskBase.id}`}
                  data-test={`card-${type}-${taskBase.id}-checkbox-select`}
                  data-dd-action-name="toggle-check-task-base"
                  type="checkbox"
                  checked={checked.includes(taskBase.id)}
                  onChange={(e) => {
                    handleOnChange(taskBase.id, e.target.checked);
                  }}
                  className="h-6 w-6 rounded border-gray-300 dark:border-slate-700 dark:bg-slate-700 dark:focus:ring-offset-slate-700"
                />
              </div>
              <LinkWithParams
                to={`./${taskBase.id}`}
                data-test={`card-${type}-${taskBase.id}-open-sidebar`}
                data-dd-action-name="open-side-bar"
                className="flex-1 items-center outline-0"
              >
                {/* Title and badge */}
                <div className="flex flex-col">
                  {/*BADGES */}
                  <div className="mb-4 flex items-center space-x-2">
                    {taskBase.taskType && (
                      <Badge dataTestId={`card-${type}-${taskBase.id}-taskType-value`}>
                        <HighlightText text={taskBase.taskType} term={params.term} />
                      </Badge>
                    )}
                    {getCatToolName(taskBase.catToolId) && (
                      <Badge>
                        <HighlightText
                          dataTestId={`card-${type}-${taskBase.id}-catToolId-value`}
                          text={getCatToolName(taskBase.catToolId) as string}
                          term={params.term}
                        />
                      </Badge>
                    )}
                    <HighlightText
                      dataTestId={`card-${type}-${taskBase.id}-taskCode`}
                      text={`${taskBase.taskCode}`}
                      term={params.term}
                      className="hidden text-xl font-medium xl:block"
                    />
                  </div>

                  <div className="mb-2 flex space-x-2 xl:hidden">
                    <span data-test={`card-${type}-${taskBase.id}-taskCode-sm-label`} className="text-color-secondary">
                      {t("task-base.fields.id")}
                    </span>
                    <HighlightText
                      dataTestId={`card-${type}-${taskBase.id}-taskCode-sm`}
                      className="font-medium"
                      text={`${taskBase.taskCode}`}
                      term={params.term}
                    />
                  </div>

                  <div className="mb-2 flex space-x-2">
                    <span
                      data-test={`card-${type}-${taskBase.id}-projectName-label`}
                      className="text-color-secondary whitespace-nowrap"
                    >
                      {t("task-base.fields.projectName")}
                    </span>
                    <HighlightText
                      dataTestId={`card-${type}-${taskBase.id}-projectName-value`}
                      className="font-medium"
                      text={taskBase.projectName}
                      term={params.term}
                    />
                  </div>
                  <div className="flex space-x-2 xl:mb-2">
                    <span data-test={`card-${type}-${taskBase.id}-customerName-label`} className="text-color-secondary">
                      {t("task-base.fields.customer")}
                    </span>
                    <HighlightText
                      dataTestId={`card-${type}-${taskBase.id}-customerName-value`}
                      className="font-medium"
                      text={taskBase.customerName}
                      term={params.term}
                    />
                  </div>
                  {isUserSupplier ? (
                    <div className="flex space-x-2 xl:mb-2">
                      <span
                        data-test={`card-${type}-${taskBase.id}-customerName-label`}
                        className="text-color-secondary"
                      >
                        {t("task-base.fields.supplierContact")}
                      </span>
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-supplierContact-value`}
                        className="font-medium"
                        text={taskBase?.supplier?.supplierContact?.name}
                        term={params.term}
                      />
                    </div>
                  ) : null}

                  <div className="flex flex-col space-y-2 xl:flex-row xl:items-center xl:space-x-2 xl:space-y-0">
                    <span
                      data-test={`card-${type}-${taskBase.id}-language-label`}
                      className="text-color-secondary mr-2 hidden xl:block"
                    >
                      {t("translation:task-base.fields.language")}
                    </span>
                    <div className="flex space-x-2 xl:space-x-0">
                      <span
                        data-test={`card-${type}-${taskBase.id}-sourceLanguage-label`}
                        className="text-color-secondary xl:hidden"
                      >
                        {t("task-base.fields.source")}
                      </span>
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-sourceLanguage-value`}
                        className="font-medium"
                        text={taskBase.sourceLanguage ?? "-"}
                        term={params.term}
                      />
                    </div>
                    <ArrowRightIcon
                      data-test={`card-${type}-${taskBase.id}-arrow-icon`}
                      className="text-color-secondary hidden h-4 w-4 xl:block"
                    />
                    <div className="flex space-x-2 xl:space-x-0">
                      <span
                        data-test={`card-${type}-${taskBase.id}-targetLanguage-label`}
                        className="text-color-secondary xl:hidden"
                      >
                        {t("task-base.fields.target")}
                      </span>
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-targetLanguage-value`}
                        className="font-medium"
                        text={taskBase.targetLanguage ?? "-"}
                        term={params.term}
                      />
                    </div>
                  </div>
                </div>
                {/* Grid */}
                <div
                  className={cn(
                    "mr-8 mt-2 grid flex-1 grid-flow-row-dense grid-cols-1 gap-2",
                    isSideBarOpen ? "lg:grid-col-1 xl:grid-cols-2" : "md:mt-2 md:grid-cols-2 xl:grid-cols-4"
                  )}
                >
                  <div className="flex space-x-2">
                    <span
                      data-test={`card-${type}-${taskBase.id}-startDate-label`}
                      className="text-color-secondary whitespace-nowrap"
                    >
                      {taskBaseStartDateLabel}
                    </span>
                    <time
                      className="font-medium"
                      dateTime={t("enum:dates.mediumDateTime", { date: taskBase.startDate })}
                    >
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-startDate-value`}
                        text={
                          taskBase.startDate
                            ? t("enum:dates.mediumDateTime", { date: taskBase.startDate })
                            : t("task-base.fields.startOnEmpty")
                        }
                        term={params.term}
                      />
                    </time>
                  </div>

                  <div className="flex space-x-2">
                    <span data-test={`card-${type}-${taskBase.id}-dueDate-label`} className="text-color-secondary">
                      {t("task-base.fields.dueOn")}
                    </span>
                    <time className="font-medium" dateTime={t("enum:dates.mediumDateTime", { date: taskBase.dueDate })}>
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-dueDate-value`}
                        text={t("enum:dates.mediumDateTime", { date: taskBase.dueDate })}
                        term={params.term}
                      />
                    </time>
                  </div>

                  <div className="flex space-x-2">
                    <span data-test={`card-${type}-${taskBase.id}-quantity-label`} className="text-color-secondary">
                      {t("task-base.fields.quantity")}
                    </span>
                    <HighlightText
                      dataTestId={`card-${type}-${taskBase.id}-quantity-value`}
                      className="font-medium"
                      text={`${t("enum:intlNumber", { val: taskBase.quantity })} ${taskBase.unit} ${
                        taskBase.secondQuantity
                          ? `, ${t("enum:intlNumber", { val: taskBase.secondQuantity })} ${taskBase.secondUnit}`
                          : ""
                      }`}
                      term={params.term}
                    />
                  </div>

                  {isUserSupplier ? (
                    <div className={cn("flex items-center space-x-2 ", isSideBarOpen ? "" : "xl:justify-center")}>
                      <span data-test={`card-${type}-${taskBase.id}-total-label`} className="text-color-secondary ">
                        {t("task-base.fields.total")}
                      </span>
                      <HighlightText
                        dataTestId={`card-${type}-${taskBase.id}-total-value`}
                        className="text-lg font-semibold"
                        text={taskBasePriceDisplay(taskBase)}
                        term={params.term}
                      />
                    </div>
                  ) : null}
                </div>
              </LinkWithParams>
              <div>
                <LinkWithParams to={`./${taskBase.id}`}>
                  <ChevronRightIcon
                    data-test={`card-${type}-${taskBase.id}-chevron-icon`}
                    className="text-color-secondary h-5 w-5"
                    aria-hidden="true"
                  />
                </LinkWithParams>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskBaseListCard;
