import CookieMessage from "components/CookieMessage";
import { useEffect, useState } from "react";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

const COOKIE_CONSENT_KEY = "pp-cookie-consent";

function CookieConsentProvider() {
  const [isShown, setIsShown] = useState<boolean>(false);
  useEffect(() => {
    const value = getLocalStorageValue(COOKIE_CONSENT_KEY, "read-cookie-consent");
    setIsShown(!value);
  }, []);

  const handleCookieAccept = () => {
    setIsShown(false);
    setLocalStorageValue(COOKIE_CONSENT_KEY, "accepted", "set-cookie-consent-accepted");
  };

  if (isShown) {
    return <CookieMessage onCookieAccept={handleCookieAccept} />;
  }

  return null;
}

export default CookieConsentProvider;
