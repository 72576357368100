import PartnerPortalLogo from "components/svgs/Logo";
import PageFooter from "layout/PageFooter";
import { ReactNode } from "react";

interface Props {
  title: string;
  children?: ReactNode;
}

const imageId = Math.floor(Math.random() * 6) + 1;

const AuthenticationLayout = ({ title, children }: Props) => (
  <main className="flex min-h-full">
    <div className="relative hidden w-0 flex-1 lg:block">
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src={`/images/banner-${imageId}.jpg`}
        alt="acolad banner"
      />
      <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-ribbon-500 to-ribbon-100 opacity-40 dark:from-slate-900 dark:to-zodiac-400" />
    </div>
    <div className="flex flex-1 flex-col sm:w-1/2 lg:w-1/3 xl:w-1/4">
      <div className="flex h-full flex-col justify-between">
        <div className="flex h-full sm:items-center">
          <div className="mx-auto my-6 sm:my-12">
            <PartnerPortalLogo />
            <h1 className="text-color-primary mt-12 max-w-md px-4 text-center text-3xl font-bold">{title}</h1>
            <div className="mt-8 max-w-md px-4 sm:px-0">{children}</div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  </main>
);

export default AuthenticationLayout;
