import { USER_TYPES, UserTypeCode } from "api/api.typing";
import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import NavigationTabsHeader from "components/header/NavigationTabsHeader";
import TaskBaseListFilter from "pages/project/common/taskBase/filter/TaskBaseListFilter";
import { useTaskBaseFilter } from "pages/project/common/taskBase/filter/useTaskBaseFilter";
import TaskBaseListCard from "pages/project/common/taskBase/list/TaskBaseListCard";
import TaskBaseListTable from "pages/project/common/taskBase/list/TaskBaseListTable";
import DeliverTaskModal from "pages/project/task/components/modals/DeliverTaskModal";
import TaskSideBar from "pages/project/task/components/sidebar/TaskSideBar";
import { useTaskCounts, useTaskListByType } from "query/task.query";
import { useUser } from "query/user.query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { cn } from "utils/ui";
import { TASK_LIST_TYPE, TaskListRouteParam, TaskListType } from "./task.typing";

const partialTabs: Array<{ name: string; to: TaskListType; count: number; forUserTypes: UserTypeCode[] }> = [
  {
    name: "task.navigation.upcoming",
    to: TASK_LIST_TYPE.upcoming,
    count: 0,
    forUserTypes: [USER_TYPES.supplier, USER_TYPES.customer],
  },
  {
    name: "task.navigation.ongoing",
    to: TASK_LIST_TYPE.ongoing,
    count: 0,
    forUserTypes: [USER_TYPES.supplier, USER_TYPES.customer],
  },
  {
    name: "task.navigation.delivered",
    to: TASK_LIST_TYPE.delivered,
    count: 0,
    forUserTypes: [USER_TYPES.supplier],
  },
  {
    name: "task.navigation.validated",
    to: TASK_LIST_TYPE.validated,
    count: 0,
    forUserTypes: [USER_TYPES.customer],
  },
  { name: "task.navigation.completed", to: TASK_LIST_TYPE.completed, count: 0, forUserTypes: [USER_TYPES.supplier] },
];

function TaskListPage() {
  const { t } = useTranslation();
  const [filterParams, onChangeParams] = useTaskBaseFilter("task");
  const { id: taskId, type = TASK_LIST_TYPE.upcoming, action } = useParams<TaskListRouteParam>();
  const { tasks, hasTasks, isLoading, error } = useTaskListByType(type, filterParams);
  const counts = useTaskCounts();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { data: currentUser } = useUser();

  if (!currentUser) {
    return null;
  }

  const isTaskDetailOpen = !!taskId;
  const isUserSupplier = currentUser.userType === USER_TYPES.supplier;
  const taskBaseStartDateLabel =
    type === TASK_LIST_TYPE.upcoming ? t("task-base.fields.estimatedStartOn") : t("task-base.fields.startOn");
  const tabs = partialTabs
    .filter((tab) => tab.forUserTypes.includes(currentUser.userType))
    .map((tab) => ({
      ...tab,
      count: counts[tab.to] ?? 0,
      buildUrl: () => `/project/task/list/${tab.to}?${searchParams.toString()}`,
    }));

  return (
    <>
      <div className="min-h-full">
        <div className="flex flex-col sm:flex-1">
          <NavigationTabsHeader
            tabs={tabs}
            selectedTab={type}
            dataTestId="tasks-nav"
            pageName="tasks"
            subTitle={t(`task.subTitle.${type}`)}
            title={t("task.title")}
          />
          <div className="flex flex-row">
            <div className={cn("flex-1", { "hidden lg:block": isTaskDetailOpen })}>
              <div className={cn("my-4 ml-4", { "mr-4": !isTaskDetailOpen })}>
                <TaskBaseListFilter
                  isDetailOpen={isTaskDetailOpen}
                  params={filterParams}
                  onChange={onChangeParams}
                  isUserSupplier={isUserSupplier}
                />
                <Loader isShown={isLoading} />
                {!isLoading && !hasTasks && (
                  <EmptyState
                    title={t(`task.empty.${type}.title`)}
                    subtitle={
                      error
                        ? t("error:common.server")
                        : filterParams.term && filterParams.term.length > 0
                          ? t("task.empty.noResult")
                          : t(`task.empty.${type}.subtitle`)
                    }
                  />
                )}
                {!isLoading &&
                  hasTasks &&
                  (filterParams.view === "table" ? (
                    <TaskBaseListTable
                      type="task"
                      tasksBase={tasks}
                      selectedTaskBaseId={taskId}
                      listFilterParams={filterParams}
                      onChange={onChangeParams}
                      taskBaseColumnName={t("task.fields.task")}
                      taskBaseStartDateLabel={taskBaseStartDateLabel}
                      isUserSupplier={isUserSupplier}
                    />
                  ) : (
                    <TaskBaseListCard
                      type="task"
                      selectedTaskBaseId={taskId}
                      tasksBase={tasks}
                      listFilterParams={filterParams}
                      taskBaseStartDateLabel={taskBaseStartDateLabel}
                      isUserSupplier={isUserSupplier}
                    />
                  ))}
              </div>
            </div>
            {!isLoading && hasTasks && taskId && (
              <>
                <TaskSideBar
                  selectedTaskId={taskId}
                  onClose={() => navigate(`/project/task/list/${type}?${searchParams.toString()}`)}
                  onDeliverClicked={() =>
                    navigate(`/project/task/list/${type}/${taskId}/deliver?${searchParams.toString()}`)
                  }
                  onErrorNode={() => navigate(`/project/task/list/${type}?${filterParams.toString()}`)}
                  filterType={type}
                />
                {action === "deliver" && (
                  <DeliverTaskModal taskId={taskId} show={true} isUserSupplier={isUserSupplier} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskListPage;
