import { datadogRum } from "@datadog/browser-rum";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { requestUpdatePassword } from "api/user.api";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatServerError } from "utils/format";
import * as yup from "yup";
import { getPasswordScore } from "./getPasswordScore";

type ResetPasswordModel = {
  newPassword: string;
};

export const useSetPassword = (resetPasswordToken: string) => {
  const { t } = useTranslation(["error", "translation"]);

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t("yup.password.required"))
      .test(
        "test-no-whitespace",
        t("yup.password.whitespace-forbidden"),
        (password) => (password ?? "").indexOf(" ") < 0
      )
      .test(
        "test-strength",
        t("yup.password.choose-stronger-password"),
        (password) => getPasswordScore(password ?? "").isValidPassword
      ),
  });
  const {
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordModel>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const password = watch("newPassword");
  const passwordScoreResult = getPasswordScore(password);

  const {
    isPending,
    error: axiosError,
    mutate,
    isSuccess,
    isError,
  } = useMutation<unknown, AxiosError, ResetPasswordModel>({
    mutationKey: ["reset-password"],
    mutationFn: ({ newPassword }) => requestUpdatePassword(newPassword, resetPasswordToken),
    retry: false,
    onSuccess: async () => {
      datadogRum.addAction("reset-password-success");
    },
    onError: (error: AxiosError) => {
      datadogRum.addAction("reset-password-failed", {
        error: { status: error.response?.status, message: error.message, response: error.response?.data },
      });
      resetField("newPassword");
    },
  });

  const serverError = () => {
    return formatServerError(axiosError, (axiosResponse) => {
      if (axiosResponse.status !== 400) {
        return null;
      }
      if (!Array.isArray(axiosResponse.data) || axiosResponse.data.length === 0) {
        return null;
      }
      if (axiosResponse.data.find((err: string) => err.startsWith("INCORRECT_PASSWORD"))) {
        return t("set-password.invalid-password");
      }
      if (axiosResponse.data.indexOf("TOKEN_NOT_FOUND") >= 0) {
        return t("set-password.token-not-found");
      }
      if (axiosResponse.data.indexOf("TOKEN_EXPIRED") >= 0) {
        return t("set-password.token-expired");
      }
      if (axiosResponse.data.find((err: string) => err.startsWith("TOKEN"))) {
        return t("set-password.invalid-link");
      }
      if (axiosResponse.data.indexOf("PASSWORD_ALREADY_USED") >= 0) {
        return t("set-password.already-used");
      }
      return null;
    });
  };

  const onSubmit: SubmitHandler<ResetPasswordModel> = ({ newPassword }) => {
    mutate({ newPassword });
  };

  return {
    isPending,
    isSuccess,
    isError,
    serverError: serverError(),
    errors,
    register,
    passwordScore: passwordScoreResult.score,
    isValidPassword: passwordScoreResult.isValidPassword,
    setPassword: handleSubmit(onSubmit),
  };
};
