import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ValidationExecutionRequestDto } from "api/api.typing";
import {
  fetchValidationRejectReasons,
  fetchValidationRequest,
  fetchValidationRequests,
  requestAcceptValidationRequest,
  requestRejectValidationRequest,
} from "api/request.api";
import { AxiosError } from "axios";
import { toastContent } from "components/toastContent";
import { ListFilterParams } from "components/typings";
import { t } from "i18next";
import { filterAndSortValidationRequestList, mapToTaskBase } from "pages/project/common/taskBase/taskBaseMapper";
import {
  REQUEST_LIST_TYPE,
  RequestListType,
  RequestStatus,
  ValidationRequest,
  ValidationRequestRejectReason,
} from "pages/project/request/request.typing";
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { formatServerError } from "utils/format";
import { LanguageType } from "utils/language";

const mapper = (request: ValidationExecutionRequestDto): ValidationRequest => {
  const taskBase = mapToTaskBase(request);
  return {
    ...taskBase,
    taskId: request.taskId.toString(),
    status: request.status,
    acceptedByAnother: request.acceptedByAnother,
  };
};

export const useValidationRequest = (selectedId: string) => {
  const { i18n } = useTranslation();

  const { data, isLoading, error } = useQuery<ValidationRequest, AxiosError>({
    queryKey: ["validationRequest", i18n.language, selectedId],
    queryFn: async () => {
      const response = await fetchValidationRequest(i18n.language as LanguageType, selectedId);
      return mapper(response);
    },
  });

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch validation execution request");
      if (message) {
        toast.error(toastContent(t("translation:request.error-title.validation-request"), message), {
          id: "validation-request-fetch-error",
        });
      }
    }
  }, [error]);

  return { isLoading, validationRequest: data };
};

export const useValidationRequestList = (params?: ListFilterParams<ValidationRequest>) => {
  const { i18n } = useTranslation();

  const {
    data = [],
    isLoading,
    error,
  } = useQuery<ValidationRequest[], AxiosError>({
    queryKey: ["validationRequests", i18n.language],
    queryFn: async () => {
      const response = await fetchValidationRequests(i18n.language as LanguageType);
      return response.requests.map(mapper);
    },
    select: useCallback(
      (requests: ValidationRequest[]) => filterAndSortValidationRequestList(requests, params),
      [params]
    ),
  });

  const hasValidationRequests = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch offer list");
      if (message) {
        toast.error(toastContent(t("translation:request.error-title.validation-request-list"), message), {
          id: "validation-request-list-fetch-error",
        });
      }
    }
  }, [error]);

  return { requests: data, hasValidationRequests, isLoading, error };
};

export const useValidationRequestCounts = () => {
  const { requests } = useValidationRequestList();

  const pendingCount = requests.filter((r) => r.status === RequestStatus.Sent).length;
  const acceptedCount = requests.filter((r) => r.status === RequestStatus.Accepted).length;

  return { pending: pendingCount, accepted: acceptedCount };
};

export const useValidationRequestListByType = (type: RequestListType, params?: ListFilterParams<ValidationRequest>) => {
  const { requests, isLoading, ...others } = useValidationRequestList(params);

  const requestsByType = useMemo(() => {
    const tabRequestStatus = REQUEST_LIST_TYPE.pending === type ? RequestStatus.Sent : RequestStatus.Accepted;
    return requests.filter((o) => o.status === tabRequestStatus);
  }, [requests, type]);

  return { ...others, isLoading, hasRequests: !isLoading && requestsByType.length > 0, requests: requestsByType };
};

export const useAcceptValidationRequestMutation = (requestId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { t: trans } = useTranslation();

  return useMutation<unknown, AxiosError, { comment?: string }>({
    mutationFn: (form) => {
      return requestAcceptValidationRequest(requestId, form.comment);
    },

    retry: false,
    onError: (error, form) => {
      toast.error(trans("request.modal.error"));
      datadogRum.addError("accept-validation-request-failed", { requestId, comment: form.comment, error });
    },
    onSuccess: async () => {
      toast.success(t("request.modal.accept.success"));
      await queryClient.cancelQueries({ queryKey: ["validationRequests"] });
      const allCachedRequests = queryClient.getQueriesData({ queryKey: ["validationRequests"] });
      allCachedRequests.forEach(([keys]) => {
        queryClient.setQueryData(keys, (oldData = []) => {
          const requests = oldData as ValidationRequest[];
          console.log(requests);
          return requests.map((o) => (o.id !== requestId ? o : { ...o, status: RequestStatus.Accepted }));
        });
      });
      onSuccess?.();
    },
  });
};

export const useRejectValidationRequestMutation = (requestId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { t: trans } = useTranslation();

  return useMutation<
    unknown,
    AxiosError,
    { reason: ValidationRequestRejectReason; comment?: string; newDeadline?: Date }
  >({
    mutationFn: (form) => {
      const comment =
        form.newDeadline && form.reason.id == "1"
          ? `${form.comment} [Suggested new deadline: ${form.newDeadline}]`
          : form.comment;
      return requestRejectValidationRequest(requestId, form.reason.id, comment);
    },

    onError: (error, form) => {
      toast.error(trans("request.modal.error"));
      datadogRum.addError("reject-validation-request-failed", {
        requestId,
        reason: form.reason,
        comment: form.comment,
        error,
      });
    },
    onSuccess: async (_, form) => {
      toast.success(trans("request.modal.decline.success"));
      datadogRum.addAction("reject-validation-request-success", {
        requestId,
        reason: form.reason,
        comment: form.comment,
      });
      await queryClient.cancelQueries({ queryKey: ["validationRequests"] });
      const allCachedRequests = queryClient.getQueriesData({ queryKey: ["validationRequests"] });
      allCachedRequests.forEach(([keys]) => {
        queryClient.setQueryData<ValidationRequest[]>(
          keys,
          (old = []) => old?.filter((request) => request.id !== requestId)
        );
      });
      onSuccess?.();
    },
  });
};

export const useValidationRequestRejectionReason = () => {
  const { i18n } = useTranslation();

  const { error, ...others } = useQuery<ValidationRequestRejectReason[], AxiosError>({
    queryKey: ["validationRejectionReason", i18n.language],
    queryFn: async () => {
      const data = await fetchValidationRejectReasons(i18n.language as LanguageType);
      return data.map<ValidationRequestRejectReason>(({ id, description }) => ({
        id: id.toString(),
        label: description,
      }));
    },
    retry: false,
  });

  useEffect(() => {
    if (error) {
      datadogLogs.logger.error("fetch-validationExecutionRequestRejectReasons-failed");
      toast.error(
        toastContent(
          t("translation:request.error-title.reject-reasons"),
          t("request.modal.decline.failFetchRejectReason")
        ),
        {
          id: "reject-validation-execution-reject-reasons-fetch-error",
        }
      );
    }
  }, [error]);

  return { error, ...others };
};
