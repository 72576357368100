import { ReactNode } from "react";

interface Props {
  message?: string;
  children?: ReactNode;
}

function Success({ message, children }: Props) {
  return (
    <div className="rounded-md bg-green-100 p-4 dark:bg-green-100/90">
      <div className="flex">
        <div className="ml-3">
          {message && <p className="text-sm font-medium text-green-600 dark:text-green-800">{message}</p>}

          {!message && children && (
            <div className="text-sm font-medium text-green-600 dark:text-green-800">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Success;
