import { Listbox as HListbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { IdName } from "api/api.typing";
import { Fragment, ReactElement, useMemo } from "react";
import { cn } from "utils/ui";

interface Props<T extends IdName<number | string>> {
  items: T[];
  selectedId: number | string | undefined | null;
  label?: ReactElement;
  renderListBoxOption?: (item: T) => ReactElement;
  onSelect: (item: T | null) => void;
  inError?: boolean;
  className?: string;
  emptyValue?: boolean;
  placeholder?: string;
  isReadyOnly?: boolean;
  emptyValueName?: string;
}

export default function Listbox<T extends IdName<number | string>>({
  items,
  selectedId,
  onSelect,
  label,
  className = "",
  renderListBoxOption,
  emptyValue = false,
  inError = false,
  placeholder = "Select",
  emptyValueName = "All",
  isReadyOnly = false,
}: Props<T>) {
  const allElements: T[] = useMemo(() => {
    return emptyValue ? [{ id: null, name: emptyValueName } as T, ...items] : items;
  }, [emptyValue, emptyValueName, items]);

  const selected = useMemo(() => items.find(({ id }) => id === selectedId) ?? null, [items, selectedId]);

  const disabled = allElements.length === 0 || isReadyOnly === true;
  const disabledMessage = allElements.length === 0 ? "No elements" : "Cannot be updated";

  return (
    <div className={className}>
      <HListbox
        disabled={disabled}
        value={selected}
        onChange={(value) => {
          return onSelect(value);
        }}
        multiple={false}
      >
        <div className="relative">
          <HListbox.Button
            title={disabled ? disabledMessage : ""}
            className={cn(
              "relative w-full rounded-lg border py-2 pl-3 pr-10 text-left  focus:outline-none focus-visible:ring-1 sm:text-sm",
              { "bg-gray-100": disabled },
              inError
                ? "border-red-300 placeholder:text-red-300 focus:border-red-500 focus:ring-red-500"
                : "focus:ring-brand-500 focus-visible:border-brand-500 border-gray-300 bg-white"
            )}
          >
            <div className="flex flex-nowrap items-center space-x-2">
              {label}
              {selected?.name ? (
                <span
                  title={selected.name}
                  className={cn("block truncate font-semibold", {
                    "cursor-not-allowed font-normal text-gray-400": disabled,
                    "text-brand-600": !disabled,
                  })}
                >
                  {selected.name}
                </span>
              ) : (
                <span className={cn("block truncate font-medium ", inError ? "text-red-900" : "text-gray-600")}>
                  {disabled ? disabledMessage : placeholder}
                </span>
              )}
              <span
                className={cn("pointer-events-none absolute inset-y-0 right-0 flex items-center", {
                  hidden: disabled,
                })}
              >
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </div>
          </HListbox.Button>
          {items.length > 0 ? (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HListbox.Options className="absolute z-30 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-0 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {allElements.map((item, idx) => (
                  <HListbox.Option
                    key={idx}
                    className={({ active }) =>
                      cn("relative cursor-default select-none py-2 pl-10 pr-4", {
                        "bg-brand-50 text-brand-900": active,
                        "text-gray-900": !active,
                      })
                    }
                    value={item}
                  >
                    {() => {
                      const isSelected = (selected?.id ?? null) === item.id;
                      return (
                        <>
                          <span
                            title={item.name}
                            className={`block truncate ${isSelected ? "font-medium text-ribbon-500" : "font-normal"}`}
                          >
                            {renderListBoxOption ? renderListBoxOption(item) : item.name}
                          </span>
                          {isSelected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </HListbox.Option>
                ))}
              </HListbox.Options>
            </Transition>
          ) : null}
        </div>
      </HListbox>
    </div>
  );
}
